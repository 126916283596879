 import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/SIEM-01.svg";
import graph2 from "../../imgs/SIEM-02.svg";

export default function SIEM() {
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">
            Security Information and Event Management
          </span>
          <br />
          The core of SOC
        </p>
      </div>
      <div className="box 1">
      <Fade bottom cascade> <p>
        
            <h1>Security Information and Event Management (SIEM) </h1>
            <h3>
              SIEM is the core solution for any SOC. All other solutions depend
              on SIEM and base their services on top of SIEM, using the data and
              analysis of SIEM. In order to optimize SIEM and receive the
              highest returns, start using other SOC solutions that will improve
              SOC reaction time and team efforts; this will for sure reflect on
              the company’s cybersecurity level and avoidance of threats and
              attacks.
            </h3>
            <br />
            <br />
            <h2 className="eng">Unsurpassed visibility</h2>
            Collects data across more capture points (logs, packet, net flow,
            endpoint), computing platforms (physical, virtual, cloud), and
            threat intelligence sources than other SIEMs.
            <br />
            <br />
            <h2 className="eng">Definitive response</h2>
            Exposes the full scope of attacks and provides analysts with
            orchestration and automation capabilities to eradicate threats
            before business impact.
            <br />
            <br />
            <h2 className="eng">Advanced Threat Detection</h2>
            Applies behavioral analytics, data science and threat intelligence
            to detect attacks in a fraction of the time of other platforms.
            <br />
            <br />
       
        </p>   </Fade>
        <img src={graph} alt="" />
      </div>

   

      <div className="box2">
        <Fade bottom cascade>
          <p>
            SIEM is the core solution for any SOC. All other solutions depend on
            SIEM and base their services on top of SIEM, using the data and
            analysis of SIEM. In order to optimize SIEM and receive the highest
            returns, start using other SOC solutions that will improve SOC
            reaction time and team efforts; this will for sure reflect on the
            company’s cybersecurity level and avoidance of threats and attacks.
            <br />
            <br />
            From the following pie, we could define the SIEM relation and
            position with other SOC solutions:
          </p>
          <img src={graph2} alt="" />
        </Fade>
      </div>
    </div>
  );
}
