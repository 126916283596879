  import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Multi-Factor-Authentication-MFA-01.svg";

export default function MFA() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">Multi-factor Authentication</span>
          <br />
          Strengthen your checkpoints, <br />
          use multi-factor authentications
        </p>
      </div>
      <div className="box 1">
        <Fade bottom cascade> <p>
  
            <h1>Multi-factor Authentication (MFA)</h1>
            <h3>
              Multi-factor Authentication (MFA) is the use of multiple factors
              to confirm the identity of someone who is requesting access to an
              application, website, or other resources. Multi-factor
              authentication is the difference between, for example, entering a
              password to gain access and entering a password plus a one-time
              password (OTP), or a password plus answering a security question.
            </h3>
              
            By requiring people to confirm identity in more than one way,
            multi-factor authentication provides greater assurance that they
            really are who they claim to be, which reduces the risk of
            unauthorized access to sensitive data. After all, it is one thing to
            enter a stolen password to gain access, and another to enter a
            stolen password and then enter an OTP that was texted to the
            legitimate user’s phone.
            <br />
            <br />
      
          <br />
          <br />
        </p>    </Fade>
      </div>

   

      <div className="box2">
        <Fade bottom cascade>
          <p>
            {" "}
            The following are categories of multifactor authentication methods:
            <br />
            <br />
          </p>
          <img src={graph} alt="" />
          <p>
            <br />
            Selecting categories should be according to the required access and
            based on user types and locations. This will affect the success of
            the service you are looking to secure, and here is TSC’s secret in
            gaining the trust of their clients.
          </p>
        </Fade>
      </div>
    </div>
  );
}
