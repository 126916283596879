import React from "react";
import './App.css';
import {Route, Routes} from "react-router-dom";
import Home from "./components/Home";
import Footer from "./components/Footer";
import About from "./pages/About";
import Networking from "./pages/Network/Networking";
import Infrastructure from "./pages/Network/Infrastructure";
import Engineering from "./pages/Network/Engineering";
import Design from "./pages/Network/Design";
import Assessment from "./pages/Network/Assessment";
import Implementation from "./pages/Network/Implementation";
import Itm from "./pages/ITM/Itm";
import ItomN from "./pages/ITM/ItomN";
import ItomS from "./pages/ITM/ItomS";
import ItomA from "./pages/ITM/ItomA";
import ItomD from "./pages/ITM/ItomD";
import ItsmS from "./pages/ITM/ItsmS";
import ItsmA from "./pages/ITM/ItsmA";
import ItsmE from "./pages/ITM/ItsmE";
import Com from "./pages/Computing/Com";
import ComS from "./pages/Computing/ComS";
import ComC from "./pages/Computing/ComC";
import Ucc from "./pages/Computing/Ucc";
import Consultancy from "./pages/Consultancy"
import Strategy from "./pages/Cybersecurity/Strategy"
import CAssessment from "./pages/Cybersecurity/CAssessment"
import Audit from "./pages/Cybersecurity/Audit"
import Awareness from "./pages/Cybersecurity/Awareness"
import Policies from "./pages/Cybersecurity/Policies"
import GRC from "./pages/Cybersecurity/GRC"
import NGFW from "./pages/Cybersecurity/NGFW"
import IPS from "./pages/Cybersecurity/IPS"
import Sandbox from "./pages/Cybersecurity/Sandbox"
import Email from "./pages/Cybersecurity/Email"
import Backup from "./pages/Cybersecurity/Backup";
import PAM from "./pages/Cybersecurity/PAM";
import AuditAndAss from "./pages/Cybersecurity/AuditAndAss";
import Firewall from "./pages/Cybersecurity/Firewall";
import NetworkSec from "./pages/Cybersecurity/NetworkSec";
import WAF from "./pages/Cybersecurity/WAF";
import MFA from "./pages/Cybersecurity/MFA";
import NAC from "./pages/Cybersecurity/NAC";
import IGA from "./pages/Cybersecurity/IGA";
import DLP from "./pages/Cybersecurity/DLP";
import AntiMalware from "./pages/Cybersecurity/AntiMalware";
import EE from "./pages/Cybersecurity/EE";
import SIEM from "./pages/Cybersecurity/SIEM";
import UEBA from "./pages/Cybersecurity/UEBA";
import NDR from "./pages/Cybersecurity/NDR";
import EDR from "./pages/Cybersecurity/EDR";
import SOAR from "./pages/Cybersecurity/SOAR";
import Physical from "./pages/Cybersecurity/Physical";
import PAC from "./pages/Cybersecurity/PAC";
import CCTV from "./pages/Cybersecurity/CCTV";
import DataE from "./pages/Cybersecurity/DataE";
import Machines from "./pages/Cybersecurity/Machines";
import Confg from "./pages/Cybersecurity/Confg";
import AppSec from "./pages/Cybersecurity/AppSec";
import Penetration from "./pages/Cybersecurity/Penetration";
import Vulnerability from "./pages/Cybersecurity/Vulnerability";
import NavbarParent from "./components/NavbarParent";
import Partners from "./pages/Partners";
import Clients from "./pages/clients";
import Team from "./pages/Team";
import Cybersecurity from "./pages/Cybersecurity/Cybersecurity";
import Prepare from "./pages/Cybersecurity/Prepare";
import Contact from "./components/Contact";
import Why from "./pages/Why";
import Protect from "./pages/Cybersecurity/Protect";
import Proof from "./pages/Cybersecurity/Proof";
function App() {
  return (
    <div className="App">
    <div className="content">
       
           
       {<NavbarParent/>} 

      <Routes>
        
        <Route path="/" element={<Home />} />
        <Route path="/who-tsc" element={<About />} />
        <Route path="/networking" element={<Networking />} />
        <Route path="/network-infrastructure" element={<Infrastructure />} />
        <Route path="/network-engineering" element={<Engineering />} />
        <Route path="/network-designing/" element={<Design />} />
        <Route path="/network-assessment" element={<Assessment />} />
        <Route path="/network-implementation" element={<Implementation />} />
        <Route path="/it-management" element={<Itm />} />
        <Route path="/itom-network-management" element={<ItomN />} />
        <Route path="/itom-system-management" element={<ItomS />} />
        <Route path="/itom-application-management" element={<ItomA />} />
        <Route path="/itom-database-management" element={<ItomD />} />
        <Route path="/itsm-service-desk" element={<ItsmS />} />
        <Route path="/itsm-it-asset-management" element={<ItsmA />} />
        <Route path="/itsm-endpoint-management" element={<ItsmE />} />
        <Route path="/computing-and-ucc" element={<Com />} />
        <Route path="/computing-servers" element={<ComS />} />
        <Route path="/computing-clients" element={<ComC />} />
        <Route path="/ucc" element={<Ucc />} />
        <Route path="/consultancy" element={<Consultancy />} />
        <Route path="/cybersecurity-strategy" element={<Strategy />} />
        <Route path="/cybersecurity-assessment" element={<CAssessment />} />
        <Route path="/cybersecurity-audit" element={<Audit />} />
        <Route path="/cybersecurity-awareness" element={<Awareness />} />
        <Route path="/cybersecurity-policies" element={<Policies />} />
        <Route path="/grc" element={<GRC />} />
        <Route path="/ngfw" element={<NGFW />} />
        <Route path="/ips" element={<IPS />} />
        <Route path="/sandbox" element={<Sandbox />} />
        <Route path="/email-security" element={<Email />} />
        <Route path="/backup" element={<Backup />} />
        <Route path="/pam" element={< PAM/>} />
        <Route path="/assessment-and-audit" element={< AuditAndAss/>} />
        <Route path="/networking-security" element={< NetworkSec/>} />
        <Route path="/firewall" element={< Firewall/>} />
        <Route path="/waf" element={< WAF/>} />
        <Route path="/mfa" element={< MFA/>} />
        <Route path="/nac" element={< NAC/>} />
        <Route path="/iga" element={< IGA/>} />
        <Route path="/dlp" element={< DLP/>} />
        <Route path="/anti-malware-defense" element={< AntiMalware/>} />
        <Route path="/endpoint-encryption" element={< EE/>} />
        <Route path="/siem" element={< SIEM/>} />
        <Route path="/ueba" element={< UEBA/>} />
        <Route path="/ndr" element={< NDR/>} />
        <Route path="/edr" element={< EDR/>} />
        <Route path="/soar" element={< SOAR/>} />
        <Route path="/physical-security" element={< Physical/>} />
        <Route path="/pac" element={< PAC/>} />
        <Route path="/cctv" element={< CCTV/>} />
        <Route path="/data-erasing" element={< DataE/>} />
        <Route path="/machines-physical-security" element={< Machines/>} />
        <Route path="/configuration-review" element={< Confg/>} />
        <Route path="/application-security" element={< AppSec/>} />
        <Route path="/penetration-testing" element={< Penetration/>} />
        <Route path="/vulnerability-assessment" element={< Vulnerability/>} />
        <Route path="/partners" element={< Partners/>} />
        <Route path="/clients" element={< Clients/>} />
        <Route path="/team" element={< Team/>} />
        <Route path="/cybersecurity" element={< Cybersecurity/>} />
        <Route path="/prepare" element={< Prepare/>} />
        <Route path="/contact-us" element={< Contact/>} />
        <Route path="/why-TSC" element={<Why/>} />
        <Route path="/protect" element={< Protect/>} />
        <Route path="/proof" element={< Proof/>} />
      </Routes>
    
  </div>

  
  {<Footer />} 
    </div>
  );
}

export default App;
