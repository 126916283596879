import React, { useEffect } from "react";
import Fade from "react-reveal/Fade";
import img1 from "../imgs/TSC Logos-01.png";
import img2 from "../imgs/TSC Logos-02.png";
import img3 from "../imgs/TSC Logos-03.png";
import img4 from "../imgs/TSC Logos-04.png";
import img5 from "../imgs/TSC Logos-05.png";
import img6 from "../imgs/TSC Logos-06.png";
import img7 from "../imgs/TSC Logos-07.png";
import img8 from "../imgs/TSC Logos-08.png";
import img9 from "../imgs/TSC Logos-09.png";

export default function Clients() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="clientsheader">
        <p className="pp">
          <span className="transparent">
            {" "}
            Our success is first from
            <br />
            Allah and then our trusted partners
          </span>
          <br />
          Thank You!
        </p>
      </div>
      <div className="clients">
        <Fade bottom cascade>
        <br/>
       
          <img src={img1} alt="" />
          <img src={img2} alt="" />
          <img src={img3} alt="" />
          <img src={img4} alt="" />
          <img src={img5} alt="" />
          <img src={img6} alt="" />
          <img src={img7} alt="" />
          <img src={img8} alt="" />
          <img src={img9} alt="" />
        </Fade>
      </div>

    </div>
  );
}
