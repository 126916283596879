  import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Data-Erasing-01.svg";

export default function DataE() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">Data Wiping and Shredding</span>
          <br />
          Deleting Data Is Not Enough <br />
          to Fully Erase the Data
        </p>
      </div>
      <div className="box 1">
        <Fade bottom cascade>
      <p>
               <h1>Data Erasing</h1>
            <h3>
              Wiping is a process that deletes information from a hard drive. It
              is different from simply moving an item to the trash. When you hit
              delete, the information is gone from one area, but it is still
              secure in the hard drive and could be recovered.
            </h3>
         
         
            Therefore, keeping not-in-use data or believing that deleted data is
            fully erased is a cybersecurity gap. All not-in-use data and deleted
            data can be retrieved and re-used by unauthorized persons.
      
          <br />
          <br />
          <br />
          <br />
          <h3>How to erase the data rather than just delete it?</h3>
        </p>    </Fade>
        <img src={graph} alt="" />
        <Fade bottom cascade>
          <p>
          <br />
          <br />
            We will recommend one of the above solutions depending on the
            company and the type of data. The decision in this regard is made
            depending on a major factor: if the media (hard drives) can be
            utilized and reused again, we recommend data wiping, and if the
            media is not usable anymore, we recommend shredding.
            <br />
            <br />
            Protecting data while storing it or transferring it is mandatory,
            but also paying attention to the deleted data and assuring that this
            data will never be used again is just as important. Keeping the
            rather than fully erasing it can lead to cybersecurity threats. Our
            team is ready to assist you in selecting the proper data erasing
            solution for your company, and it will be our pleasure to deliver
            the full service to you.
          </p>
        </Fade>
      </div>

 
    </div>
  );
}
