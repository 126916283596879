import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";


export default function Engineering() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="networkheader">
        <p className="pp">
          <span className="transparent">
            The brain that thinks, manages, controls,
            <br />
            and decides on your traffic
          </span>
          <br />
          The Brain of Your Infrastructure
        </p>
      </div>
      <div className="box 1">
         <Fade bottom cascade>  <p>
     
            <h1>Networking Engineering</h1>
 
       
            <h3>
              Network engineering in networking is the part that thinks,
              manages, and controls your traffic, and takes decisions on it as
              well. It does all this by switching and routing your data based on
              the installed configurations. Anything else in your IT environment
              will depend on it. Selecting a suitable partner for your network
              engineering need is crucial because major configurations will
              remain the same for an extended period, unlike network devices
              that may need replacement every 5 to 10 years.
            </h3>
     
   
            Our expert engineers and consultants are dedicated to serving your
            needs in the areas below:
           
            <h2 className="eng">WAN</h2>
            
            WAN (Wide Area Network) connections bring data centers, remote
            office, and mobile users together to provide everyone with the
            access they need, thus keeping operations running smoothly. TSC
            services enable you to reduce WAN operating costs, increase network
            agility through automation, and provide a higher broadband
            performance for business-critical applications. We provide a variety
            of WAN networking solutions (e.g. SD-WAN, MPLS, VPN, and more) to
            help build bridges across your wider network and meet all your
            networking needs. We understand the importance of quick and reliable
            access from any location, and our experts are more than qualified to
            help you create a customized WAN solution that satisfies your needs.
            Our coverage for this sub-domain is limitless, but we will outline a
            part of it here:
            <br />
 
    
            <ul>
              <li>WAN architectural design</li>
              <li>WAN Network protocol analysis</li>
              <li>Virtual LAN (VLAN) configuration</li>
              <li>SD-WAN solutions (Software-defined WAN)</li>
              <li>MPLS solution implementation</li>
              <li>SD-WAN solutions (Software-defined WAN)</li>
              <li>VPN connectivity between HQ and Branches</li>
              <li>Quality of Service (QoS)</li>
              <li>Quality of Service (QoS)</li>
              <li>Quality of Service (QoS)</li>
            </ul>
 

            <h2 className="eng">LAN</h2>
            
            A Local Area Network (LAN) is a computer network covering a
            specific local area. This could be something small, such as a home
            network, or on a much larger scale like a complex network for
            multiple buildings with multiple floors in the same location. TSC
            brings you a wide range of LAN services that expand the capabilities
            of desktop computers. We create a safe and reliable network
            environment for your company. Our LAN services include provisioning,
            implementation, maintenance, and management of LAN infrastructure
            equipment:
            <br />
   
            <ul>
              <li>
                <h4>Provisioning</h4> covers the selection, requisition, and
                delivery of equipment to the customer’s premises
              </li>
              <li>
                <h4>Implementation </h4>includes equipment configuration,
                pre-implementation checks, installation, and commissioning.
              </li>
              <li>
                <h4>Maintenance </h4>ensures support and fault rectification for
                installed equipment.
              </li>
            </ul>
            <br />

            TSC covers all LAN services, such as the following:
            <br />
            <ul>
              <li>Network architectural design</li>
              <li>Network protocol analysis</li>
              <li>Virtual LAN (VLAN) configuration</li>
              <li>Network Subnetting</li>
              <li>Virtual Private Network (VPN) design</li>
              <li>Quality of Service (QoS)</li>
              <li>Dynamic routing</li>
              <li>Firewall protection</li>
              <li>Network router configuration</li>
              <li>Fiber-channel Networks</li>
            </ul>
           

            <h2 className="eng">WLAN</h2>
            Wireless Local Area Network (WLAN) is simply a wireless LAN. It
            performs the same functions as LAN but wirelessly. WLAN is easier to
            use from the perspective of end-users, and its implementation can be
            easier and faster. Configurations and designing the WLAN, however,
            requires deeper knowledge, more experience, and various tools.
            <br />
            <br /> At TSC, we guarantee top reliability in WLAN by ensuring 100%
            coverage of signals with no black or disconnected zones. We perform
            all this through extensive surveys and using the proper tools.
   
            <h2 className="eng">DC Network</h2>
            DC networking plays a pivotal role in a data center as it
            interconnects all of the data center resources, i.e., computing,
            storage, servers, and databases.
            <br />
            Out team provides DC networking based on best practices to help
            address the challenges you may face as you grow your DC
            infrastructure. Our services will help you:
            <br />
  
            <ul>
              <li>Support fast application growth</li>
              <li>Manage growing data center storage necessities</li>
              <li>Optimize your investments in server-processing resources</li>
              <li>Access information reliably</li>
              <li>Access information reliably</li>
              <li>Control the cost of installing a new data center</li>
            </ul>

            <h2 className="eng">SD Network</h2>
            In SDN, TSC delivers a centralized approach to network management
            that helps you provision, manage, and program networks more rapidly
            with software-defined networking.
            <br />
            The SDN service is divided into two domains:
            <br />
            <ul>
              <li>SDN for enterprises</li>
              <li>SDN in Data Centers</li>
            </ul>
            <br />
            The solution will help your company establish automated Data Center
            services and policies so your engineers can spend less time managing
            the Data Center network and more time focusing on strategic
            activities.
       
        </p>   </Fade>
      </div>

     
    </div>
  );
}
