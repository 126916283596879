  import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Data-Loss-Prevention-DLP-01.svg";

export default function DLP() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">Data Loss Prevention</span>
          <br />
          Your data is your number one asset… <br />
          do not lose it
        </p>
      </div>
      <div className="box 1">
           <Fade bottom cascade><p>
       
            <h1>Data Loss Prevention (DLP)</h1>
      
       
            <h3>
              Data Loss Prevention (DLP) is an approach that seeks to improve
              information security and protect business information from data
              breaches. It prevents end-users from moving key information
              outside the network. DLP also enable a network administrator to
              monitor data accessed and shared by end users.
            </h3>

            <br />
      
        </p>    </Fade>
        <img src={graph} alt="" />
      </div>

<div className="box2">
        <Fade bottom cascade>
          <p>
            <br />
            <br />
            Once the envelope is opened and the content processed, there are
            multiple content analysis techniques that can be used to trigger
            policy violations, including:
          </p>
       

  
          <br />
          <br />
          <p>
            When we deliver the SEG services, we ensure selecting the best SEG
            solution to fit your company based on many considerations. This will
            increase SEG optimization and boost return of investment on SEG as
            well. Benefits of SEG include:
            <br />
            <br />
          </p>
          <div className="rowabout">
            <div className="columnabout boxes four">
              <h2 className="eng">RULE-BASED/REGULAR EXPRESSIONS</h2>
              Engine analyzing content for specific rules such as 16-digit
              credit card numbers, 9-digit U.S. social security numbers, etc.
              This technique is an excellent first-pass filter since the rules
              can be configured and processed quickly, although they can be
              prone to high false-positive rates without checksum validation to
              identify valid patterns.
            </div>
            <div className="columnabout boxes four">
              <h2 className="eng">DATABASE FINGERPRINTING</h2>
              Also known as Exact Data Matching, this mechanism looks at exact
              matches from a database dump or live database. Although database
              dumps or live database connections affect performance, this is an
              option for structured data from databases.
            </div>
            <div className="columnabout boxes four">
              <h2 className="eng">EXACT FILE MATCHING</h2>
              File contents are not analyzed; however, the hashes of files are
              matched against exact fingerprints. This provides low false
              positives, but this approach does not work for files with multiple
              similar but not identical versions.
            </div>
            <div className="columnabout boxes four">
              <h2 className="eng">PRE-BUILT CATEGORIES</h2>Pre-built categories
              with rules and dictionaries for common types of sensitive data,
              such as credit card numbers/PCI protection, HIPAA, etc.
            </div>
          </div>
          <div className="rowabout">
            <div className="columnabout boxes four">
              <h2 className="eng">PARTIAL DOCUMENT MATCHING</h2>
              Looks for a complete or partial match on specific files such as
              multiple versions of a form that have been filled out by different
              users.
            </div>
            <div className="columnabout boxes four">
              <h2 className="eng">CONCEPTUAL/LEXICON</h2>
              Using a combination of dictionaries, rules, etc., these policies
              can alert on completely unstructured ideas that defy simple
              categorization. It needs to be customized for the DLP solution
              provided.
            </div>
            <div className="columnabout boxes four">
              <h2 className="eng">STATISTICAL ANALYSIS</h2>Uses machine learning
              or other statistical methods such as Bayesian analysis to trigger
              policy violations in secure content. Requires a large volume of
              data to scan from (the larger the better) or is else prone to
              false positives and negatives.
            </div>
          </div>
          <br />
          <p>
            
            When evaluating DLP solutions, pay close attention to the types of
            patterns detected by each solution against a real corpus of
            sensitive data to confirm the accuracy of its content engine.
            <br />
            <br />
          </p>
        </Fade>
      </div>
    </div>
  );
}
