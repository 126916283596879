import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Networking-01.svg";

export default function Networking() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="networkheader">
        <p className="pp">
          <span className="transparent">
            Multiple domains and services are evidence of our expertise
          </span>
          <br />
          Sustainable and Robust Networks Start Here
        </p>
      </div>
      <div className="box 1">
      <Fade bottom cascade>   <p>
        
            <h1>Networking</h1>
       
         
            <h3>
              A flexible, agile and secured network infrastructure lays <br />
              the foundation of successful businesses. Network infrastructure{" "}
              <br />
              includes LAN, WAN, and Wireless.
            </h3>
        
            In a fast connecting world, your data network’s up-time and speed is
            often the key to improving your company’s performance. With today’s
            competing and growing business world, your network is the key to
            success.
            <br />
            <br />
            TSC takes pride in placing network infrastructure solutions for the
            client’s needs before everything else. Our team has wide experience
            in performing optimization/upgrade or deploying new or emerging
            technologies for your network. We specialize in designing networks
            using leading vendor technologies, and we have already successfully
            deployed many such solutions.
          
        </p>    <img src={graph} alt="" /></Fade>
      </div>
   
     
    
     
    </div>
  );
}
