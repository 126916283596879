 import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/ITOM-–-Application-Management-01.svg";
export default function ItomS() {
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="itmheader">
        <p className="pp">
          <span className="transparent">System Operation Management</span>
          <br />
          Monitor and Manage <br />
          the Most Valuable Components
        </p>
      </div>
      <div className="box 1">
        <Fade bottom cascade> <p>
     
            <h1>System Operation Management</h1>
         
        
            <h3>
              Through an integrated solution aligned with other operation
              management solutions, your visibility over your environment will
              be high and your control will be raised to the max. TSC’s mission
              is to make your operation experience more streamlined, efficient,
              optimized, and even fun.
            </h3>
      
            Through network management solutions, everything will easily be
            under control. All the required processes will be streamlined,
            efficient, and rapid.
          
        </p></Fade>
      </div>

      <div className="box2">
        <Fade bottom cascade>
          <img src={graph} alt="" />
        </Fade>
       <Fade bottom cascade>   <p>
        
            <h2 className="eng">Server and Application Monitoring</h2>
            Comprehensive server and application monitoring to make it much
            easier.
            <br />
            <ul>
              <li>Remote Server Monitoring</li>
              <li>IT Inventory Management</li>
              <li>Hardware Monitoring</li>
              <li>Active Directory</li>
              <li>SSL</li>
              <li>Server Capacity</li>
              <li>Server Health Monitoring</li>
              <li>Microsoft IIS Monitoring</li>
              <li>Windows Server Monitoring</li>
              <li>End-to-End Monitoring</li>
              <li>Application Mapping</li>
              <li>Citrix XenApp</li>
              <li>Application Dependency Mapping</li>
              <li>Microsoft SCOM Monitoring</li>
              <li>File Monitoring</li>
              <li>Hybrid Cloud Solution</li>
              <li>Java Monitoring</li>
              <li>Docker Monitoring</li>
              <li>Response Time Monitor</li>
              <li>SMTP Monitoring</li>
              <li>Server Monitoring Software</li>
            </ul>
            <h2 className="eng">Virtualization Optimization</h2>
            Inspect virtual machine optimization and determine whether optimized
            enough or even over-optimized.
            <br />
            <ul>
              <li>Performance Monitoring</li>
              <li>Capacity Planning</li>
              <li>Hyper-V Monitor</li>
              <li>Snapshot Management</li>
              <li>Virtualization Management</li>
              <li>VSAN Performance Monitoring</li>
              <li>Configuration</li>
              <li>Events Monitoring</li>
              <li>Sprawl Control</li>
              <li>Troubleshooting</li>
              <li>Recommendations</li>
              <li>Performance Management</li>
              <li>Virtual Machine Monitor</li>
              <li>VM Monitoring Tool</li>
              <li>Hyper-V Resource Monitor</li>
              <li>vSphere Monitor</li>
              <li>VM Management Software</li>
            </ul>
     
            <h2 className="eng">Storage Resources Monitoring</h2>
            Storage resource management reporting for health, performance, and
            capacity.
            <br />
            <ul>
              <li>SAN Management</li>
              <li>Capacity Planning</li>
              <li>Network Storage Monitoring</li>
              <li>Enterprise Storage Management</li>
              <li>Reporting</li>
              <li>Data Storage Management</li>
              <li>Storage Resource Management</li>
            </ul>
            <h2 className="eng">Server Configuration Monitoring</h2>Detect and
            compare configuration changes to your servers, databases, and
            applications.
            <br />
            <ul>
              <li>
                Baseline server and application configurations on Windows and
                Linux
              </li>
              <li>
                Alert and report on deviations from the baseline in near
                real-time
              </li>
              <li>
                See who is making configuration changes on servers or
                applications
              </li>
              <li>Compare current configurations to previous versions</li>
              <li>Correlate configuration changes with performance metrics</li>
              <li>Track server hardware and software inventory</li>
            </ul>
    
            <h2 className="eng">Web Performance Monitoring</h2>
            Find and fix internal and external site and app performance issues
            <br />
            <ul>
              <li>Complete performance monitoring</li>
              <li>Continuous synthetic transaction monitoring</li>
              <li>Detailed load time metrics</li>
              <li>Multi-vendor on-premises and cloud DHCP and DNS support</li>
              <li>Regular expression text matching</li>
              <li>Responsive design</li>
              <li>Tab handling</li>
            </ul>
         
        </p> </Fade>
      </div>
    </div>
  );
}
