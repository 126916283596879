 import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Configuration-Review-01.svg";
import graph2 from "../../imgs/Configuration-Review-02.svg";
export default function Confg() {
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">
            Devices will not be <br />
            well-configured on the first try
          </span>
          <br />A Deep Look into Your Configurations
        </p>
      </div>
      <div className="box 1">
         <Fade bottom cascade> <p>
       
            <h1>Configuration Review</h1>

        
            <h3>
              The first step of proofing and testing is to perform configuration
              review for your devices. Most configurations will never mature
              unless reviewed for a second time. Usually, the review will
              recommend a series of modifications and adjustments, especially
              when someone adds a new device to the network, some parts of the
              configuration are missing, or some integration requirements are
              missing. All these will definitely affect cybersecurity level.
            </h3>
            Our mission here is to ensure the most suitable configurations,
            paying attention to integration via a horizontal overview for all
            components. We divide our configuration review service into three
            sub-services:
            <br />
            <br />
         
        </p> </Fade>
        <img src={graph} alt="" />
      </div>
   
      <div className="box2">
        <Fade bottom cascade>
          <p>
            The true value of these reviews are the results, which will show
            gaps and weaknesses along with mismatching configuration that causes
            conflicts. It will also provide a full explanation on how to resolve
            and recover by following best practices for each device and system.
            <br />
            <br />
            The review journey goes through:
          </p>
          <img src={graph2} alt="" />
          <p>
            <br />
            <br />
            Our services in this regard does not end here; it is our pleasure to
            go the extra mile and fix the configuration gaps if needed through
            our experts and engineers.
          </p>
        </Fade>
      </div>
    </div>
  );
}
