import logo from "../imgs/TSC logo-01-02.png";
import { ReactComponent as CaretIcon } from "../icons/caret.svg";
import { ReactComponent as ChevronIcon } from "../icons/chevron.svg";
import { ReactComponent as ArrowIcon } from "../icons/arrow.svg";
import React, { useState, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";

export default function NavbarParent() {
  // const [active, setActive] = useState("nav_menu");
  // const [toggleIcon, setToggleIcon] = useState("nav_toggler");
  // const navToggle = () => {
  //   active === "nav_menu"
  //     ? setActive("nav_menu nav_active")
  //     : setActive("nav_menu");
  //   toggleIcon === "nav_toggler"
  //     ? setToggleIcon("nav_toggler toggle")
  //     : setToggleIcon("nav_toggler");
  // };

  if (window.innerWidth > 768) {
    return (
      <div>
        {/* <Logo/> */}

        <Navbar>
          <Link to="/">
            <NavItem name="Home" link="/"></NavItem>
          </Link>

          <NavItem icon={<CaretIcon />} name="About TSC">
            <DropdownMenuAbout />
          </NavItem>

          <NavItem icon={<CaretIcon />} name="TSC Services">
            <DropdownMenuServices menu="services"></DropdownMenuServices>
          </NavItem>
          <NavItem icon={<CaretIcon />} name="TSC Relations">
            <DropdownMenuRelations></DropdownMenuRelations>
          </NavItem>
          <Link to="/contact-us">
            <NavItem name="Contact Us"></NavItem>
          </Link>
        </Navbar>
      </div>
    );
  } else {
    return (
      <Navbar>
        <NavItem icon={<CaretIcon />}>
          <DropdownMenuMain></DropdownMenuMain>
        </NavItem>
      </Navbar>
    );
  }
}

// function Logo() {
//   return (
//     <a className="site-title">
//       <img className="navlogo" src={logo} alt="tech stations" />
//     </a>
//   );
// }

function Navbar(props) {
  const [nav, setNav] = useState(false);
  const cb = () => {
    if (window.scrollY >= 300) {
      setNav(true);
    } else {
      setNav(false);
    }
  };
  window.addEventListener("scroll", cb);
  return (
    <nav className={nav ? "navbar active " : "navbar"}>
      <Link to="/">
        <img
          className="navlogo"
          src={logo}
          alt="tech stations"
          style={{ flexDirection: "start" }}
        />
      </Link>
      <ul className="navbar-nav">{props.children} </ul>
    </nav>
  );
}

function NavItem(props) {
  const [open, setOpen] = useState(false);
  let menuRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <li className="anchor" ref={menuRef}>
      <button className="icon-button" onClick={() => setOpen(!open)}>
        {props.name} {props.icon}
      </button>

      {open && props.children}
    </li>
  );
}

function DropdownMenuServices() {
  const [activeMenu, setActiveMenu] = useState("main");

  const [menuHeight, setMenuHeight] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setMenuHeight(dropdownRef.current?.firstChild.offsetHeight);
  }, []);

  function calcHeight(el) {
    const height = el.offsetHeight + 30;
    setMenuHeight(height);
  }

  function DropdownItem(props) {
    return (
      <button
        className="menu-item"
        onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}
      >
        <span className="icon-button">{props.leftIcon}</span>
        {props.children}
        <span className="icon-button">{props.rightIcon}</span>
      </button>
    );
  }

  return (
    <div className="dropdown" style={{ height: menuHeight }} ref={dropdownRef}>
      {/* Services */}

      <CSSTransition
        in={activeMenu === "main"}
        timeout={500}
        classNames="menu-primary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="cyber">
            Cybersecurity
          </DropdownItem>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="networking">
            Networking
          </DropdownItem>

          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="itm">
            IT Management
          </DropdownItem>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="computing">
            Computing & UCC
          </DropdownItem>
          <Link to="/consultancy">
            {" "}
            <DropdownItem>Consultancy</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      {/* Cybersecurity */}

      <CSSTransition
        in={activeMenu === "cyber"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="main" leftIcon={<ArrowIcon />}>
            <h3>Services</h3>
          </DropdownItem>
          <Link to="/cybersecurity">
            {" "}
            <DropdownItem>Cybersecurity</DropdownItem>
          </Link>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="prepare">
            Prepare
          </DropdownItem>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="protect">
            Protect
          </DropdownItem>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="proof">
            Proof
          </DropdownItem>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "prepare"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="cyber" leftIcon={<ArrowIcon />}>
            <h3>Cybersecurity</h3>
          </DropdownItem>
          <Link to="/prepare">
            {" "}
            <DropdownItem>Prepare</DropdownItem>
          </Link>
          <DropdownItem>
            <Link to="/cybersecurity-strategy">Strategy</Link>
          </DropdownItem>
          <DropdownItem goToMenu="assaudit" rightIcon={<ChevronIcon />}>
            Assessment and Audit
          </DropdownItem>
          <Link to="/cybersecurity-awareness">
            {" "}
            <DropdownItem>Awareness and Training</DropdownItem>
          </Link>
          <Link to="/cybersecurity-policies">
            {" "}
            <DropdownItem>Polices and Procedures</DropdownItem>{" "}
          </Link>
          <Link to="/grc">
            {" "}
            <DropdownItem>GRC Platform Service</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "assaudit"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="prepare" leftIcon={<ArrowIcon />}>
            <h3>Prepare</h3>
          </DropdownItem>
          <Link to="/assessment-and-audit">
            {" "}
            <DropdownItem>Assessment and Audit</DropdownItem>
          </Link>
          <Link to="/cybersecurity-assessment">
            {" "}
            <DropdownItem>Assessment</DropdownItem>
          </Link>
          <Link to="/cybersecurity-audit">
            {" "}
            <DropdownItem>Audit</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "protect"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="cyber" leftIcon={<ArrowIcon />}>
            <h3>Cybersecurity</h3>
          </DropdownItem>
          <Link to="/protect">   <DropdownItem>Protect</DropdownItem>    </Link>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="netsec">
            Network Security
          </DropdownItem>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="soc">
            Security Operations System
          </DropdownItem>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="phsec">
            Physical Security
          </DropdownItem>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "netsec"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="protect" leftIcon={<ArrowIcon />}>
            <h3>Protect</h3>
          </DropdownItem>
          <Link to="/networking-security">
            {" "}
            <DropdownItem>Network Security</DropdownItem>
          </Link>
          <DropdownItem goToMenu="firewall" rightIcon={<ChevronIcon />}>
            Firewall
          </DropdownItem>
          <DropdownItem goToMenu="iam" rightIcon={<ChevronIcon />}>
            IAM
          </DropdownItem>
          <DropdownItem goToMenu="email" rightIcon={<ChevronIcon />}>
            Email Security
          </DropdownItem>
          <Link to="/backup">
            {" "}
            <DropdownItem>Backup & Recovery</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "firewall"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="netsec" leftIcon={<ArrowIcon />}>
            <h3>Network Security</h3>
          </DropdownItem>

          <Link to="/firewall">
            {" "}
            <DropdownItem>Firewall</DropdownItem>
          </Link>
          <Link to="/ngfw">
            {" "}
            <DropdownItem>Next Generation Firewall</DropdownItem>
          </Link>
          <Link to="/ips">
            {" "}
            <DropdownItem>Intrusion Prevention System</DropdownItem>
          </Link>
          <Link to="/waf">
            {" "}
            <DropdownItem>Web Application Firewall</DropdownItem>
          </Link>
          <Link to="/email-security">
            {" "}
            <DropdownItem>Email Security</DropdownItem>
          </Link>
          <Link to="/sandbox">
            {" "}
            <DropdownItem>Sandbox</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "iam"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="netsec" leftIcon={<ArrowIcon />}>
            <h3>Network Security</h3>
          </DropdownItem>
          <Link to="/pam">
            {" "}
            <DropdownItem>PAM</DropdownItem>
          </Link>
          <Link to="/mfa">
            <DropdownItem>MFA</DropdownItem>
          </Link>
          <Link to="/nac">
            {" "}
            <DropdownItem>NAC</DropdownItem>
          </Link>
          <Link to="/iga">
            {" "}
            <DropdownItem>IGA</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "email"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="netsec" leftIcon={<ArrowIcon />}>
            <h3>Network Security</h3>
          </DropdownItem>
          <Link to="/email-security">
            {" "}
            <DropdownItem>Email Security</DropdownItem>
          </Link>
          <Link to="/dlp">
            {" "}
            <DropdownItem>Data-Loss Prevention</DropdownItem>
          </Link>
          <Link to="/anti-malware-defense">
            <DropdownItem>Anti-Malware Defense</DropdownItem>
          </Link>
          <Link to="/endpoint-encryption">
            {" "}
            <DropdownItem>Endpoint Encryption</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "soc"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="protect" leftIcon={<ArrowIcon />}>
            <h3>Protect</h3>
          </DropdownItem>
          <Link to="/siem">
            {" "}
            <DropdownItem>SIEM</DropdownItem>
          </Link>
          <Link to="/ueba">
            <DropdownItem>UEBA</DropdownItem>
          </Link>
          <Link to="/edr">
            {" "}
            <DropdownItem>EDR</DropdownItem>
          </Link>
          <Link to="/ndr">
            {" "}
            <DropdownItem>NDR</DropdownItem>
          </Link>
          <Link to="/soar">
            {" "}
            <DropdownItem>SOAR</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "phsec"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="protect" leftIcon={<ArrowIcon />}>
            <h3>Protect</h3>
          </DropdownItem>
          <Link to="/physical-security">
            {" "}
            <DropdownItem>Physical Access Control</DropdownItem>
          </Link>
          <Link to="/cctv">
            {" "}
            <DropdownItem>CCTV</DropdownItem>
          </Link>
          <Link to="/data-erasing">
            {" "}
            <DropdownItem>Data Erasing</DropdownItem>
          </Link>
          <Link to="/ndr">
            {" "}
            <DropdownItem>NDR</DropdownItem>
          </Link>
          <Link to="/machines-physical-security">
            {" "}
            <DropdownItem>Machines Physical Security</DropdownItem>{" "}
          </Link>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "proof"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="cyber" leftIcon={<ArrowIcon />}>
            <h3>Cybersecurity</h3>
          </DropdownItem>
          <Link to="/proof"> <DropdownItem>Proof</DropdownItem>  </Link>
          <Link to="/configuration-review">
            {" "}
            <DropdownItem>Configuration Review</DropdownItem>
          </Link>
          <Link to="/application-security">
            {" "}
            <DropdownItem>Application Security Testing</DropdownItem>
          </Link>
          <Link to="/penetration-testing">
            <DropdownItem>Penetration Testing</DropdownItem>
          </Link>
          <Link to="/vulnerability-assessment">
            {" "}
            <DropdownItem>Vulnerability Assessment</DropdownItem>
          </Link>
          <DropdownItem>Vulnerability Assessment vs Testing</DropdownItem>
        </div>
      </CSSTransition>

      {/* Networking */}

      <CSSTransition
        in={activeMenu === "networking"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="main" leftIcon={<ArrowIcon />}>
            <h3>Services</h3>
          </DropdownItem>
          <Link to="/networking">
            {" "}
            <DropdownItem>Networking</DropdownItem>
          </Link>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="networkDomains">
            Network Domains
          </DropdownItem>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="networkServices">
            Network Services
          </DropdownItem>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "networkDomains"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="networking" leftIcon={<ArrowIcon />}>
            <h3>Netowrking</h3>
          </DropdownItem>
          <Link to="/network-infrastructure">
            <DropdownItem>Network Infrastructure</DropdownItem>
          </Link>
          <Link to="/network-engineering">
            {" "}
            <DropdownItem>Network Engineering</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "networkServices"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="networking" leftIcon={<ArrowIcon />}>
            <h3>Netowrking</h3>
          </DropdownItem>
          <Link to="network-designing">
            {" "}
            <DropdownItem>Network Design</DropdownItem>
          </Link>
          <Link to="/network-assessment">
            {" "}
            <DropdownItem>Network Assessment</DropdownItem>
          </Link>
          <Link to="/network-implementation">
            {" "}
            <DropdownItem>Network Implementaion</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      {/* ITM */}

      <CSSTransition
        in={activeMenu === "itm"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="main" leftIcon={<ArrowIcon />}>
            <h3>Services</h3>
          </DropdownItem>
          <Link to="/it-management">
            {" "}
            <DropdownItem>IT Management</DropdownItem>
          </Link>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="itom">
            ITOM
          </DropdownItem>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="itsm">
            ITSM
          </DropdownItem>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "itom"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="itm" leftIcon={<ArrowIcon />}>
            <h3>IT Management</h3>
          </DropdownItem>
          <Link to="/itom-network-management">
            {" "}
            <DropdownItem>Network Managemant</DropdownItem>
          </Link>
          <Link to="/itom-system-management">
            {" "}
            <DropdownItem>System Managemant</DropdownItem>
          </Link>
          <Link to="/itom-application-management">
            {" "}
            <DropdownItem>Application Managemant</DropdownItem>{" "}
          </Link>
          <Link to="/itom-database-management">
            {" "}
            <DropdownItem>Database Managemant</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "itsm"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="itm" leftIcon={<ArrowIcon />}>
            <h3>IT Management</h3>
          </DropdownItem>
          <Link to="/itsm-service-desk">
            {" "}
            <DropdownItem>Service desk</DropdownItem>
          </Link>
          <Link to="/itsm-it-asset-management">
            {" "}
            <DropdownItem>Assest Managemant</DropdownItem>
          </Link>
          <Link to="/itsm-endpoint-management">
            {" "}
            <DropdownItem>Endpoint Managemant</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      {/* Computing */}

      <CSSTransition
        in={activeMenu === "computing"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="main" leftIcon={<ArrowIcon />}>
            <h3>Services</h3>
          </DropdownItem>
          <Link to="/computing-and-ucc">
            <DropdownItem>Computing & UCC</DropdownItem>
          </Link>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="comp">
            Computing
          </DropdownItem>
          <Link to="ucc">
            <DropdownItem>UCC</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "comp"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="itm" leftIcon={<ArrowIcon />}>
            <h3>Computing & UCC</h3>
          </DropdownItem>
          <Link to="/computing-servers">
            {" "}
            <DropdownItem>Computing Servers</DropdownItem>
          </Link>
          <Link to="/computing-clients">
            <DropdownItem>Computing Clients</DropdownItem>
          </Link>
        </div>
      </CSSTransition>
    </div>
  );
}

function DropdownMenuRelations() {
  const [activeMenu, setActiveMenu] = useState("main");

  const [menuHeight, setMenuHeight] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setMenuHeight(dropdownRef.current?.firstChild.offsetHeight);
  }, []);

  function calcHeight(el) {
    const height = el.offsetHeight;
    setMenuHeight(height);
  }

  function DropdownItem(props) {
    return (
      <button
        className="menu-item"
        onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}
      >
        <span className="icon-button">{props.leftIcon}</span>
        {props.children}
        <span className="icon-button">{props.rightIcon}</span>
      </button>
    );
  }

  return (
    <div className="dropdown" style={{ height: menuHeight }} ref={dropdownRef}>
      {/* Services */}

      <CSSTransition
        in={activeMenu === "main"}
        timeout={500}
        classNames="menu-primary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <Link to="/clients">
            {" "}
            <DropdownItem>Clients</DropdownItem>
          </Link>
          <Link to="/partners">
            {" "}
            <DropdownItem>Partners</DropdownItem>
          </Link>
          <Link to="/team">
            <DropdownItem>Team</DropdownItem>
          </Link>
        </div>
      </CSSTransition>
    </div>
  );
}

function DropdownMenuAbout() {
  const [activeMenu, setActiveMenu] = useState("main");

  const [menuHeight, setMenuHeight] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setMenuHeight(dropdownRef.current?.firstChild.offsetHeight);
  }, []);

  function calcHeight(el) {
    const height = el.offsetHeight;
    setMenuHeight(height);
  }

  function DropdownItem(props) {
    return (
      <button
        className="menu-item"
        onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}
      >
        <span className="icon-button">{props.leftIcon}</span>
        {props.children}
        <span className="icon-button">{props.rightIcon}</span>
      </button>
    );
  }

  return (
    <div className="dropdown" style={{ height: menuHeight }} ref={dropdownRef}>
      {/* Services */}

      <CSSTransition
        in={activeMenu === "main"}
        timeout={500}
        classNames="menu-primary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <Link to="/who-tsc">
            {" "}
            <DropdownItem>Who is TSC?</DropdownItem>
          </Link>
          <Link to="/why-tsc">
            {" "}
            <DropdownItem>Why TSC?</DropdownItem>
          </Link>

          <DropdownItem>Gallary</DropdownItem>
        </div>
      </CSSTransition>
    </div>
  );
}

function DropdownMenuMain() {
  const [activeMenu, setActiveMenu] = useState("main");

  const [menuHeight, setMenuHeight] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setMenuHeight(dropdownRef.current?.firstChild.offsetHeight);
  }, []);

  function calcHeight(el) {
    const height = el.offsetHeight;
    setMenuHeight(height);
  }

  function DropdownItem(props) {
    return (
      <button
        className="menu-item"
        onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}
      >
        <span className="icon-button">{props.leftIcon}</span>
        {props.children}
        <span className="icon-button">{props.rightIcon}</span>
      </button>
    );
  }

  return (
    <div className="dropdown" style={{ height: menuHeight }} ref={dropdownRef}>
      {/* main */}

      <CSSTransition
        in={activeMenu === "main"}
        timeout={500}
        classNames="menu-primary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <Link to="/">
            {" "}
            <DropdownItem>Home</DropdownItem>
          </Link>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="about">
            About TSC
          </DropdownItem>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="servicesmain">
            TSC Services
          </DropdownItem>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="relationsmain">
            TSC Relations
          </DropdownItem>
          <Link to="/contact-us">
            <DropdownItem>Contact Us</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      {/* Services */}

      <CSSTransition
        in={activeMenu === "servicesmain"}
        timeout={500}
        classNames="menu-primary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="main" leftIcon={<ArrowIcon />}>
            <h3>Back</h3>
          </DropdownItem>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="cyber">
            Cybersecurity
          </DropdownItem>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="networking">
            Networking
          </DropdownItem>

          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="itm">
            IT Management
          </DropdownItem>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="computing">
            Computing & UCC
          </DropdownItem>
          <Link to="/consultancy">
            {" "}
            <DropdownItem>Consultancy</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      {/* Cybersecurity */}

      <CSSTransition
        in={activeMenu === "cyber"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="servicesmain" leftIcon={<ArrowIcon />}>
            <h3>Services</h3>
          </DropdownItem>
          <Link to="/cybersecurity">
            <DropdownItem>Cybersecurity</DropdownItem>
          </Link>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="prepare">
            Prepare
          </DropdownItem>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="protect">
            Protect
          </DropdownItem>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="proof">
            Proof
          </DropdownItem>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "prepare"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="cyber" leftIcon={<ArrowIcon />}>
            <h3>Cybersecurity</h3>
          </DropdownItem>
          <Link to="/prepare">
            <DropdownItem>Prepare</DropdownItem>
          </Link>
          <Link to="/cybersecurity-strategy">
            {" "}
            <DropdownItem>Strategy</DropdownItem>
          </Link>
          <DropdownItem goToMenu="assaudit" rightIcon={<ChevronIcon />}>
            Assessment and Audit
          </DropdownItem>
          <Link to="/cybersecurity-awareness">
            <DropdownItem>Awareness and Training</DropdownItem>
          </Link>
          <Link to="/cybersecurity-policies">
            {" "}
            <DropdownItem>Polices and Procedures</DropdownItem>
          </Link>
          <Link to="/grc">
            <DropdownItem>GRC Platform Service</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "assaudit"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="prepare" leftIcon={<ArrowIcon />}>
            <h3>Prepare</h3>
          </DropdownItem>
          <Link to="/assessment-and-audit">
            {" "}
            <DropdownItem>Assessment and Audit</DropdownItem>{" "}
          </Link>
          <Link to="/cybersecurity-assessment">
            {" "}
            <DropdownItem>Assessment</DropdownItem>
          </Link>
          <Link to="/cybersecurity-audit">
            {" "}
            <DropdownItem>Audit</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "protect"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="cyber" leftIcon={<ArrowIcon />}>
            <h3>Cybersecurity</h3>
          </DropdownItem>
          <Link to="/protect">     <DropdownItem>Protect</DropdownItem>  </Link>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="netsec">
            Network Security
          </DropdownItem>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="soc">
            Security Operations System
          </DropdownItem>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="phsec">
            Physical Security
          </DropdownItem>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "netsec"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="protect" leftIcon={<ArrowIcon />}>
            <h3>Protect</h3>
          </DropdownItem>
          <Link to="/networking-security">
            {" "}
            <DropdownItem>Network Security</DropdownItem>
          </Link>
          <DropdownItem goToMenu="firewall" rightIcon={<ChevronIcon />}>
            Firewall
          </DropdownItem>
          <DropdownItem goToMenu="iam" rightIcon={<ChevronIcon />}>
            IAM
          </DropdownItem>
          <DropdownItem goToMenu="email" rightIcon={<ChevronIcon />}>
            Email Security
          </DropdownItem>
          <Link to="/backup">
            {" "}
            <DropdownItem>Backup & Recovery</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "firewall"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="netsec" leftIcon={<ArrowIcon />}>
            <h3>Network Security</h3>
          </DropdownItem>

          <Link to="/firewall">
            {" "}
            <DropdownItem>Firewall</DropdownItem>
          </Link>
          <Link to="/ngfw">
            <DropdownItem>Next Generation Firewall</DropdownItem>{" "}
          </Link>
          <Link to="/ips">
            {" "}
            <DropdownItem>Intrusion Prevention System</DropdownItem>
          </Link>
          <Link to="/waf">
            {" "}
            <DropdownItem>Web Application Firewall</DropdownItem>
          </Link>
          <Link to="/email-security">
            {" "}
            <DropdownItem>Email Security</DropdownItem>
          </Link>
          <Link to="/sandbox">
            {" "}
            <DropdownItem>Sandbox</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "iam"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="netsec" leftIcon={<ArrowIcon />}>
            <h3>Network Security</h3>
          </DropdownItem>
          <Link to="/pam">
            {" "}
            <DropdownItem>PAM</DropdownItem>
          </Link>
          <Link to="/mfa">
            {" "}
            <DropdownItem>MFA</DropdownItem>
          </Link>
          <Link to="/nac">
            <DropdownItem>NAC</DropdownItem>
          </Link>
          <Link to="/iga">
            <DropdownItem>IGA</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "email"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="netsec" leftIcon={<ArrowIcon />}>
            <h3>Network Security</h3>
          </DropdownItem>
          <Link to="/email-security">
            {" "}
            <DropdownItem>Email Security</DropdownItem>
          </Link>
          <Link to="/dlp">
            <DropdownItem>Data-Loss Prevention</DropdownItem>
          </Link>
          <Link to="/anti-malware-defense">
            {" "}
            <DropdownItem>Anti-Malware Defense</DropdownItem>
          </Link>
          <Link to="/endpoint-encryption">
            {" "}
            <DropdownItem>Endpoint Encryption</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "soc"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="protect" leftIcon={<ArrowIcon />}>
            <h3>Protect</h3>
          </DropdownItem>
          <Link to="/siem">
            {" "}
            <DropdownItem>SIEM</DropdownItem>
          </Link>
          <Link to="/ueba">
            {" "}
            <DropdownItem>UEBA</DropdownItem>
          </Link>
          <Link to="/edr">
            <DropdownItem>EDR</DropdownItem>
          </Link>
          <Link to="/ndr">
            {" "}
            <DropdownItem>NDR</DropdownItem>
          </Link>
          <Link to="/soar">
            <DropdownItem>SOAR</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "phsec"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="protect" leftIcon={<ArrowIcon />}>
            <h3>Protect</h3>
          </DropdownItem>
          <Link to="/physical-security">
            {" "}
            <DropdownItem>Physical Access Control</DropdownItem>
          </Link>
          <Link to="/cctv">
            {" "}
            <DropdownItem>CCTV</DropdownItem>
          </Link>
          <Link to="/data-erasing">
            <DropdownItem>Data Erasing</DropdownItem>
          </Link>
          <Link to="/ndr">
            {" "}
            <DropdownItem>NDR</DropdownItem>
          </Link>
          <Link to="/machines-physical-security">
            {" "}
            <DropdownItem>Machines Physical Security</DropdownItem>{" "}
          </Link>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "proof"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="cyber" leftIcon={<ArrowIcon />}>
            <h3>Cybersecurity</h3>
          </DropdownItem>
          <Link to="/proof">
            {" "}
            <DropdownItem>Proof</DropdownItem>
          </Link>{" "}
          <Link to="/configuration-review">
            {" "}
            <DropdownItem>Configuration Review</DropdownItem>
          </Link>
          <Link to="/application-security">
            {" "}
            <DropdownItem>Application Security Testing</DropdownItem>
          </Link>
          <Link to="/penetration-testing">
            {" "}
            <DropdownItem>Penetration Testing</DropdownItem>
          </Link>
          <Link to="/vulnerability-assessment">
            {" "}
            <DropdownItem>Vulnerability Assessment</DropdownItem>
          </Link>
          <DropdownItem>Vulnerability Assessment vs Testing</DropdownItem>
        </div>
      </CSSTransition>

      {/* Networking */}

      <CSSTransition
        in={activeMenu === "networking"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="main" leftIcon={<ArrowIcon />}>
            <h3>Services</h3>
          </DropdownItem>
          <Link to="/networking">
            {" "}
            <DropdownItem>Networking</DropdownItem>{" "}
          </Link>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="networkDomains">
            Network Domains
          </DropdownItem>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="networkServices">
            Network Services
          </DropdownItem>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "networkDomains"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="networking" leftIcon={<ArrowIcon />}>
            <h3>Netowrking</h3>
          </DropdownItem>
          <Link to="/network-infrastructure">
            <DropdownItem>Network Infrastructure</DropdownItem>
          </Link>
          <Link to="/network-engineering">
            <DropdownItem>Network Engineering</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "networkServices"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="networking" leftIcon={<ArrowIcon />}>
            <h3>Netowrking</h3>
          </DropdownItem>
          <Link to="network-designing">
            {" "}
            <DropdownItem>Network Design</DropdownItem>
          </Link>
          <Link to="/network-assessment">
            <DropdownItem>Network Assessment</DropdownItem>
          </Link>
          <Link to="/network-implementation">
            <DropdownItem>Network Implementaion</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      {/* ITM */}

      <CSSTransition
        in={activeMenu === "itm"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="main" leftIcon={<ArrowIcon />}>
            <h3>Services</h3>
          </DropdownItem>
          <Link to="/it-management">
            {" "}
            <DropdownItem>IT Management</DropdownItem>
          </Link>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="itom">
            ITOM
          </DropdownItem>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="itsm">
            ITSM
          </DropdownItem>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "itom"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="itm" leftIcon={<ArrowIcon />}>
            <h3>IT Management</h3>
          </DropdownItem>
          <Link to="/itom-network-management">
            {" "}
            <DropdownItem>Network Managemant</DropdownItem>
          </Link>
          <Link to="/itom-system-management">
            {" "}
            <DropdownItem>System Managemant</DropdownItem>
          </Link>
          <Link to="/itom-application-management">
            {" "}
            <DropdownItem>Application Managemant</DropdownItem>{" "}
          </Link>
          <Link to="/itom-database-management">
            <DropdownItem>Database Managemant</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "itsm"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="itm" leftIcon={<ArrowIcon />}>
            <h3>IT Management</h3>
          </DropdownItem>
          <Link to="/itsm-service-desk">
            {" "}
            <DropdownItem>Service desk</DropdownItem>
          </Link>
          <Link to="/itsm-it-asset-management">
            {" "}
            <DropdownItem>Assest Managemant</DropdownItem>
          </Link>
          <Link to="/itsm-endpoint-management">
            {" "}
            <DropdownItem>Endpoint Managemant</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      {/* Computing */}

      <CSSTransition
        in={activeMenu === "computing"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="main" leftIcon={<ArrowIcon />}>
            <h3>Services</h3>
          </DropdownItem>
          <Link to="/computing-and-ucc">
            {" "}
            <DropdownItem>Computing & UCC</DropdownItem>
          </Link>
          <DropdownItem rightIcon={<ChevronIcon />} goToMenu="comp">
            Computing
          </DropdownItem>
          <Link to="ucc">
            {" "}
            <DropdownItem>UCC</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "comp"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="itm" leftIcon={<ArrowIcon />}>
            <h3>Computing & UCC</h3>
          </DropdownItem>
          <Link to="/computing-servers">
            <DropdownItem>Computing Servers</DropdownItem>
          </Link>
          <Link to="/computing-clients">
            <DropdownItem>Computing Clients</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "relationsmain"}
        timeout={500}
        classNames="menu-primary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="main" leftIcon={<ArrowIcon />}>
            <h3>Back</h3>
          </DropdownItem>
          <Link to="/clients">
            {" "}
            <DropdownItem>Clients</DropdownItem>
          </Link>
          <Link to="/partners">
            <DropdownItem>Partners</DropdownItem>
          </Link>
          <Link to="/team">
            <DropdownItem>Team</DropdownItem>
          </Link>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "about"}
        timeout={500}
        classNames="menu-primary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <Link to="/who-tsc">
            {" "}
            <DropdownItem>Who is TSC?</DropdownItem>
          </Link>
          <Link to="/why-tsc">
            {" "}
            <DropdownItem>Why TSC?</DropdownItem>
          </Link>

          <DropdownItem>Gallary</DropdownItem>
        </div>
      </CSSTransition>
    </div>
  );
}
