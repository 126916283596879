import React, { useEffect } from "react";
import Fade from "react-reveal/Fade";

export default function Prepare() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">
            Implementing your cybersecurity needs starts here
          </span>
          <br />
          GOVERN YOUR ORGANIZATION
          <br /> WITH NO EXTRA EFFORTS ​
        </p>
      </div>
      <div className="box 1">
        <Fade bottom cascade>
          {" "}
          <p>
            <h1>Governance</h1>
            <h3>
              Governance is how you control and direct your company’s approach
              to security. When done well, security governance will effectively
              coordinate the security activities of your company. It streamlines
              the flow of security information and empowers you to make more
              informed decisions regarding the security of your company.
            </h3>
            Governance encompasses structures and procedures designed to ensure
            accountability, transparency, responsiveness, rule of law,
            stability, equity, inclusiveness, empowerment, and broad-based
            participation.
            <br />
            TSC focuses on Cybersecurity governance through its team of expert
            consultants who will dive deep into your company to formulate
            appropriate and effective security measures tailored for your
            company.
            <br />
            
            <br />
            
          </p>{" "}
        </Fade>
      </div>


    </div>
  );
}
