import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Network-Assessment-01.svg";

export default function Assessment() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="networkheader">
        <p className="pp">
          <span className="transparent">
            Do not worry. Our comprehensive  <br />assessment services will guide you
          </span>
          <br />
          You Have No Idea Where to Go Next?
        </p>
      </div>
      <div className="box 1">
        <p>
          <Fade bottom>
            <h1>Network Assessment</h1>
          </Fade>{" "}
          <Fade bottom cascade>
            <h3>
              A Network Assessment is an in-depth analysis of your current IT
              infrastructure that provides you with a comprehensive
              understanding of your existing environment and recommends
              improvements, such as network consolidation, simplification, or
              automation.
            </h3>
          </Fade>
          <Fade bottom cascade>
            Manual assessment will never be enough to come up with accurate
            results, so TSC utilizes multiple tools that automate the process
            while performing deep scans to reveal detailed reports complemented
            with graphs and dashboards. All this is to maximize the benefits of
            the assessment and to help you make the right decisions.
          </Fade>
        </p>
      </div>
     
      <div className="box2">
        <img src={graph} alt="" />
      </div>
      
    </div>
  );
}
