  import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/ITSM-–-Endpoint-Management-01.svg";

export default function ItsmE() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="itmheader">
        <p className="pp">
          <span className="transparent">Endpoint Management</span>
          <br />
          Endpoints under Full Control
        </p>
      </div>
      <div className="box 1">
          <Fade bottom cascade><p>
     
            <h1>Endpoint Management</h1>
     
        
            <h3>
              With new types of devices getting into use in the workplace, the
              administration of traditional laptops, desktops, and new devices
              is a challenging task for IT administrators. Endpoint management
              solutions help the service desk (support team), network team, and
              cybersecurity team to accomplish their tasks more efficiently.
            </h3>
            Endpoint management solutions can be combined with multiple
            functions or offered separately with each function in a different
            product or module. TSC takes a lot into consideration to assist you
            in selecting the most suitable solution for your company. The
            following is an overall view for the Endpoint Management solutions
            and functions:
        
        </p>  </Fade>
      </div>
   
      <div className="box2">
        <Fade bottom cascade>
          <img src={graph} alt="" />
        </Fade>
     <Fade bottom cascade>   <p>
          
            Multiple controls are set by the NCA’s Essential Cybersecurity
            Controls (ECC – 1: 2018). One of these controls is cybersecurity
            defense, under which is the Mobile Device Security. It is very clear
            the importance of implementing solutions to secure mobile devices in
            your company. Mobile device security is a part of the Mobile Device
            Management (MDM) services we provide, which go beyond even beyond
            cybersecurity.
            <br />
         
        </p> </Fade>
      </div>
    </div>
  );
}
