  import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Machines-Physical-Security-01.svg";
import graph2 from "../../imgs/Machines-Physical-Security-02.svg";
export default function Machines() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">Machines Physical Security</span>
          <br />
          Protect against Fire, <br /> Heat, Power Failure, and Water
        </p>
      </div>
      <div className="box 1">
          <Fade bottom cascade> <p>
     
            <h1>Machines Physical Security</h1>
   
       
            <h3>
              A part of physical security is machine physical security, which
              refers to the means of securing the machines from any physical
              risk, i.e., protecting machine boxes regardless of the data
              inside. This kind of security should be carried out at early
              stages, but that is not say it could not be implemented later. It
              would be much easier, however, if we prepare for this kind of
              security from the beginning.
            </h3>
            Using a sandbox for advanced malware detection provides another
            layer of protection against new security threats, especially
            previously unseen malware and stealthy attacks. The benefit here is
            that what happens in the sandbox, stays in the sandbox, effectively
            avoiding system failures and keeping software vulnerabilities from
            spreading. In order to ensure the highest coverage for machine
            physical security, this section has been divided into multiple
            layers with each layer containing multiple considerations to be
            covered:
            <br />
            <br />
    
        </p>
        <img src={graph} alt="" />      </Fade>
      </div>
 
      <div className="box2">
        <Fade bottom cascade>
          <p>
            <h4>What are machine physical risks and how to be protected?</h4>
            <br />
            Other than the non-authorized access by non-authorized people, there
            are other risks that threaten machine physical security. There are
            solutions for these threats as shown below:
            <br />
            </p>
            <img src={graph2} alt="" />
            <p>
            Based on the size, location, sensitivity, and other considerations,
            we select the proper solutions for the company. The solutions can
            also differ between machines; data center machines, for example, are
            the most sensitive and important machines, so all security controls
            should be installed there. Network devices at the data rooms and
            cabinets will have a second level of security, and the final
            security controls will go to endpoint machines that need minimum
            controls.
            <br />
            <br />
            <br />
            <h4>What TSC does to ensure machine physical security?</h4>
            <br />
            Our mission starts from categorizing the machines and the required
            controls to formulate the required standards and roles, select the
            proper solutions, build and deliver the solution through qualified
            partners, ensure quality and continuity, and link all controls to
            monitoring systems.
          </p>
        </Fade>
      </div>
    </div>
  );
}
