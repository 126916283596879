import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/ITOM-–-Database-Management-01.svg";
export default function ItomD() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="itmheader">
        <p className="pp">
          <span className="transparent">Database Operation Management</span>
          <br />
          Databases Are Much More <br />
          Than Just Storing Data
        </p>
      </div>
      <div className="box 1">
      <Fade bottom cascade>  <p>
      
            <h1>Database Operation Management</h1>
       
            <h3>
              Storing data is one thing, but retrieving it is a different beast.
              The challenge arises in selecting the right query and not
              consuming database running time to give results.
            </h3>
      
          
            Database administrators and IT professionals cannot improve what
            they cannot measure. Ensuring you have the right monitoring solution
            to monitor the correct metrics, and provide actionable metrics,
            ensures you can efficiently pinpoint the root cause of a problem and
            optimize and tune database performance to prevent future issues.
            <br />
            <br />
            Database performance monitoring and optimization solutions are
            designed to give you insights into database health and pinpoint
            performance issues in minutes.
       
        </p>   </Fade>
      </div>
     
      <div className="box2">
        <Fade bottom cascade>
          <img src={graph} alt="" />
    
        <p>
  
            <h2 className="eng">Database Performance Analyzer</h2>
            Database management software built for SQL query performance
            monitoring, analysis, and tuning.
            <br />
            <ul>
              <li>
                Cross-platform database support for both cloud and on-premises
              </li>
              <li>Anomaly detection powered by machine learning</li>
              <li>Expert advice with index and query tuning advisors</li>
              <li>VMware vSphere performance monitoring</li>
              <li>
                Down-to-the-second, detailed data, both real-time and historical
              </li>
              <li>Azure SQL Server Performance</li>
              <li>Database Anomaly Detection</li>
              <li>SQL Query Analyzer</li>
              <li>SQL Database Monitor</li>
              <li>SQL Server Audit Log Tool</li>
              <li>SQL Server Performance Tuning Tool</li>
              <li>SQL Query Optimization</li>
              <li>SQL Server Activity Monitoring</li>
              <li>Amazon RDS Monitoring</li>
              <li>VMware Monitoring</li>
              <li>Oracle Exadata Performance Monitoring</li>
              <li>Database Performance Analyzer Oracle SE</li>
              <li>Database Optimization</li>
              <li>MySQL Performance Tuning</li>
              <li>MySQL Query Analyzer</li>
              <li>MySQL Slow Query Log</li>
            </ul>
            <h2 className="eng">SQL Sentry</h2>
            Monitor, diagnose, and optimize SQL Server and Azure SQL.
            <br />
            <ul>
              <li>At-a-glance view of database environment health</li>
              <li>Proactive alerting and response system</li>
              <li>Find and fix high-impact queries</li>
              <li>
                Manage scheduled events and identify resource contention in an
                Outlook-style calendar
              </li>
              <li>Storage forecasting powered by predictive analytics</li>
              <li>
                Identify contributing problems in the OS and virtual environment
              </li>
              <li>Performance Analysis Dashboard</li>
              <li>Top SQL</li>
              <li>Advisory Conditions</li>
              <li>SQL Sentry Portal</li>
              <li>Deadlock Analysis</li>
              <li>SQL Server Blocking Analysis</li>
              <li>TempDB Analysis</li>
              <li>Event Calendar</li>
              <li>Storage Forecasting</li>
              <li>SQL Sentry Always On Monitoring</li>
              <li>SSAS Top Commands</li>
              <li>SSAS Usage Totals</li>
              <li>SSAS Performance Metrics</li>
              <li>Event Chains</li>
              <li>Index Analysis</li>
              <li>Plan Explorer</li>
            </ul>
   
            <h2 className="eng">Database Performance Monitor</h2>
            Database performance monitoring and optimization for open systems
            and NoSQL databases.
            <br />
            <ul>
              <li>SaaS platform with a web-based user interface</li>
              <li>Lightweight agents via multiple configurations</li>
              <li>Monitors databases in the cloud, locally, or hybrid</li>
              <li>
                Real-time and historical data to pinpoint performance issues
              </li>
              <li>
                Multiple ways to protect sensitive data; GDPR and SOC2 compliant
              </li>
              <li>APM and Database Monitoring</li>
              <li>Code Deploy</li>
              <li>Database Health</li>
              <li>Infrastructure Cost Savings</li>
              <li>Outages</li>
              <li>Product Architecture and Implementation</li>
              <li>System Performance</li>
              <li>Team Efficiency</li>
              <li>VividCortex</li>
            </ul>
            <h2 className="eng">Database Insight for SQL Server</h2>
            The insights data teams need to proactively manage the performance
            of Microsoft SQL databases.
            <br />
            <ul>
              <li>SQL Server performance monitoring</li>
              <li>Blocking and deadlock analysis</li>
              <li>Index and query tuning advisors</li>
              <li>Anomaly detection for wait-time analysis</li>
              <li>VMware, Hyper-V, and environment monitoring</li>
              <li>Visualize Windows and SQL scheduled events</li>
            </ul>

            <h2 className="eng">Database mapper</h2>
            Easy-access, self-managed database documentation and data lineage
            analysis
            <br />
            <ul>
              <li>Automated database documentation</li>
              <li>Documentation search</li>
              <li>Visual data lineage analysis</li>
              <li>Object lineage tracking</li>
              <li>Data dictionary search</li>
              <li>Point in time historical documentation</li>
            
            </ul>
            <h2 className="eng">Task Factory</h2>
            Save time managing tedious data warehousing ELT/ETL tasks.
             <br />
            <ul>
              <li>Prebuilt SQL Server Integration Services (SSIS) components to accelerate ETL processes

              </li>
              <li>Optimized for SSIS to ensure the fast performance of data-centric applications

              </li>
              <li>Connect to dozens of data sources, including REST-enabled sources

              </li>
              <li>Streamline many tedious data warehouse management tasks</li>
              <li>Run Task Factory on an Azure-SSIS Integration Runtime (IR) with the Azure Data

              </li>
 
            </ul>
         
        </p> </Fade>
      </div>
    </div>
  );
}
