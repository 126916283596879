  import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Penetration-Testing-01.svg";

export default function Penetration() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">Penetration Testing in Reality</span>
          <br />
          Employ Ethical Hacking to <br />
          Prevent Real Hacking
        </p>
      </div>
      <div className="box 1">    <Fade bottom cascade> <p>

            <h1>Penetration Testing</h1>
   
      
            <h3>
              A penetration test is an authorized simulated cyberattack on an IT
              asset, performed to evaluate the security of the system. In
              contrast to Vulnerability Assessment, Penetration Testing involves
              identifying vulnerabilities in a particular network and attempting
              to exploit them to penetrate into the system.
            </h3>
            <br />
            <br />
            Simply, we are hacking your company without inflicting any damages.
            After signing the agreement, we will attempt to penetrate your
            company in safe mode to discover genuine vulnerabilities without
            impacting your systems.
            <br />
            <br />
         
        </p>
        <img src={graph} alt="" /> </Fade>
      </div>


      <div className="box2">
        <Fade bottom cascade>
          <p>
            TSC covers the following domains in Penetration Testing:
            <br />
            <br />
            <ul>
              <li>
                <h2 className="eng">Network (Wired and Wireless)</h2>
              </li>
              <li>
                <h2 className="eng">Apps (Web and Mobile)</h2>
              </li>
              <li>
                <h2 className="eng">Databases</h2>
              </li>
              <li>
                <h2 className="eng">Social Engineering</h2>
              </li>
            </ul>{" "}
            <br />
            <br />
          </p>
        </Fade>
      </div>
    </div>
  );
}
