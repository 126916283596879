import React, { useEffect } from "react";
import Fade from "react-reveal/Fade";


export default function Team() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="teamheader">
        <p className="pp">
          <br />
          Great Vision
          <br />
          Without Great People Is Irrelevant
        </p>
      </div>
      <div className="box 1">
        <Fade bottom cascade>
          <p>
            <h1>TSC Team</h1>
            <h3>
              When we built our team, we did not only focus on their
              qualifications. We paid close attention to those who bring their
              hearts to the office – those who could be a part of our family. We
              looked for those who hold the same ethics, the same passion, the
              ones who will add value to our house and be committed to our
              clients and projects.
            </h3>
            To show its valuation and dedication to its family member, TSC is
            always keen on supporting its member with a compensation and rewards
            program, retention program, entertainment program, and development
            program. TSC believes the world is changing fast, especially in
            technology and we need to keep our team up to date with the latest
            technologies and solutions. Our development program is designed to
            suit everyone in all positions to ensure the quality of our service.
            <br />
            <br />
            As a part of our duty to give back to our nation and in following
            the teachings of Islam, we offer our Cooperative training program
            (COOP). COOP is our social responsibility where our experts can
            teach fresh graduates and prepare them for the market.
          </p>
        </Fade>
   
      </div>
    </div>
  );
}
