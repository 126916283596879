import React, { useEffect } from "react";
import Fade from "react-reveal/Fade";

export default function Protect() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">
            Real and effective protection from cyber attacks
          </span>
          <br />
          The best defense is a strong offense
        </p>
      </div>
      <div className="box 1">
        <Fade bottom cascade>
          {" "}
          <p>
            <h1>Protect</h1>
            <h3>
              When we talk about real and practical cybersecurity we talk about
              TSC’s “Protect” services. Our “Protect” services zero in on making
              your company secure.
            </h3>
            Compromising on cybersecurity is extremely ill-advised. Protection
            should be well-rounded and cover all aspects of protection; any less
            than this and the company will remain vulnerable. For this reason,
            TSC is keen on covering all the related domains for mature
            protection.
            <br />
            <br />
            <ul>
                <li>Network Security</li>
                <li>Physical Security</li>
                <li>SOC</li>
            </ul>
          </p>{" "}
        </Fade>
      </div>
    </div>
  );
}
