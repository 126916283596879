import React, { useEffect } from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Cybersecurity-Awareness-and-Training-01.svg";
import graph2 from "../../imgs/Cybersecurity-Awareness-and-Training-02.svg";

export default function Awareness() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">
            Practical and applicable, not theoretical
          </span>
          <br />
          Technology alone does not prevent <br />
          employees from clicking the wrong link
        </p>
      </div>
      <div className="box 1">
        <Fade bottom cascade>
          {" "}
          <p>
            <h1>Cybersecurity Awareness & Training</h1>
            <h3>
              Cybersecurity awareness refers to how much end-users know about
              cybersecurity threats their networks face and the risks they can
              potentially introduce. End users are the weakest link in
              cybersecurity and the primary source of vulnerability in a
              network. According to the 2017 Data Breach Investigations Report,
              more than 90% of cyber-attacks were traced back to human error,
              suggesting that mistakes caused by humans both initiate and
              amplify the risk of cyber-crime and the damage it poses to
              businesses.
            </h3>
            Cybersecurity awareness refers to how much end-users know about
            cybersecurity threats their networks face and the risks they can
            potentially introduce. End users are the weakest link in
            cybersecurity and the primary source of vulnerability in a network.
            According to the 2017 Data Breach Investigations Report, more than
            90% of cyber-attacks were traced back to human error, suggesting
            that mistakes caused by humans both initiate and amplify the risk of
            cyber-crime and the damage it poses to businesses.
            <br />
            Everything in any cyber environment can be secured properly through
            systematic solutions and with very clear processes and known
            behaviors; everything, that is, except people. Employees are one of
            the weakest parts of cybersecurity efforts in any company as they
            are un-controllable and their behavior is not expectable. Most of
            the time, however, people commit mistakes simply because they do not
            know that what they were doing is a mistake.
            <br />
            These simple, honest mistakes can be very costly for companies
            nowadays, and from this rises the importance of boosting employee
            awareness regarding Cybersecurity.
            <br />
            The list of expected regular employee mistakes that could affect the
            company’s cyberspace is too long to list, but if employees are
            educated and made aware about taking proper measures that do not put
            the company at risk, then 90% of such mistakes will never occur. The
            following are some examples of employee mistakes:
            <br />
          </p>{" "}
        </Fade>
        <img src={graph} alt="" />
      </div>


      <div className="box2">
        <Fade bottom cascade>
          <p>
            <br />
            <br />
            <div className="rowabout">
              <div className="columnabout boxes two two">
                Using the same login credentials for social media and public
                portals
              </div>
              <div className="columnabout boxes two">
                Having weak login credentials
              </div>
              <div className="columnabout boxes two">
                Opening emails from unknown sources
              </div>
            </div>
            <div className="rowabout">
              <div className="columnabout boxes two">
                Opening links or download files from unknown emails
              </div>
              <div className="columnabout boxes two">
                Writing down passwords on sticky notes
              </div>
              <div className="columnabout boxes two">
                Using unsecured Wi-Fi networks
              </div>
            </div>
            <br />
            <br />
            TSC uses different methods to raise awareness and conduct proper
            training, merging theory with practice. Naturally, people who commit
            a mistake are much more attentive to learning how not to commit it
            again, and this is exactly what we do. We launch a campaign with
            numerous phishing emails, SMS, or voice calls, and then we use the
            results to start training those who fell for it. Victims will be
            more focused and learn faster. Our awareness and training strategy
            here is simply:
            <br />
            <br />
          </p>
          <img src={graph2} alt="" />
        </Fade>
      </div>

    
      <div className="box">
        {" "}
        <Fade bottom cascade>
          <p>
            <br />
            <br />
            <br />
            <br />
            We then repeat this process regularly but with different methods and
            techniques. Through awareness software, this operation will be much
            easier than you think. All results and improvement progress of the
            employees’ knowledge will be reported automatically.
            <br />
            <br />
            Based on the automated reports about the cybersecurity awareness
            level, this exercise will assign employees to training programs that
            map out the training path for each employee, if needed, and based on
            their level of understanding and job role and title requirements. We
            perform training either through conventional methods like physical
            or virtual classes, or through recorded videos and written
            materials.
          </p>{" "}
        </Fade>
      </div>
      
    </div>
  );
}
