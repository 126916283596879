import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Network-Designing-01.svg";

export default function Design() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="networkheader">
        <p className="pp">
          <span className="transparent">
            The entire network
            <br />
            depends on how you design it
          </span>
          <br />
          We Design by Knowledge
        </p>
      </div>
      <div className="box 1">
     <Fade bottom cascade>   <p>

            <h1>Networking Designing</h1>
     
          
            <h3>
              Designing in TSC is not merely approached as science, but we see
              it as a science and a form of art as well. We take care in design
              to ensure it is satisfactory to the client and us by answering
              many questions, most important of which are:
            </h3>
          
            <ul>
              <li>What is the reason behind the design?</li>
              <li>What are the uses of this network?</li>
              <li>What are the probabilities of expansion?</li>
            </ul>

            Then, our qualified engineers begin their scientific works, ensuring
            they cover everything in the design to come up with the best design
            for your need. We cover the following aspects in designing the
            network:
            <div className="rowabout">
              <div className="columnabout">
                <h2 className="eng">Suitability</h2>
                Designing based on what the client needs, no more or less. This
                means using suitable technologies for the required domain,
                location, etc.
              </div>
              <div className="columnabout">
                <h2 className="eng">Integrability</h2>
                Making sure the highest integration levels with the existing
                environment.
              </div>
              <div className="columnabout">
                <h2 className="eng">Security</h2>
                We always place security at top of our considerations, and so we
                design as per security recommendations.
              </div>
              <div className="columnabout">
                <h2 className="eng">Cost-effectiveness</h2>
                We always consider the cost, thus we select the best solution
                for the best cost whether for establishing or operating the
                network.
              </div>
            </div>
            <div className="rowabout">
              <div className="columnabout">
                <h2 className="eng">Security</h2>
                In most networks, 30% of existing items can be utilized in a new
                network. We keep this in mind when designing.
              </div>
              <div className="columnabout">
                <h2 className="eng">Up to Date</h2>
                We select the latest technologies to guarantee the maximum
                lifetime for the network.
              </div>
              <div className="columnabout">
                <h2 className="eng">Expandability</h2>
                Networks are always growing. That is why you should keep
                potential growth in mind to avoid extensive changes later.
              </div>
              <div className="columnabout">
                <h2 className="eng">Availability</h2>
                Increase availability up to 24/7, keeping in mind other
                considerations.
              </div>
            </div>
       
 
        </p>   </Fade>
      </div>
    
      <div className="box2">
        <Fade bottom cascade>
          <p>
            This is not an easy undertaking as numerous details comes into play.
            All details must be covered properly, and here is where TSC shines.
            The first thing we start with here is define how many employees the
            company needs and what skillsets and qualifications are required.
            Most SOC staffing does not exceed the following:
            <br />
            <br />
            <br />
            TSC Design Journey:
          </p>
          <img src={graph} alt="" />
        </Fade>
      </div>
    </div>
  );
}
