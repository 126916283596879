  import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";

export default function UEBA() {


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">
            User and Entity Behaviour Analytics
          </span>
          <br />
          Analyze More, Detect More, <br />
          Protect More.
        </p>
      </div>
      <div className="box 1">
        <Fade bottom cascade>   <p>

            <h1>User and Entity Behavior Analytics (UEBA)</h1>

      
            <h3>
              User and Entity Behavior Analytics (UEBA) is a category of
              security solutions that use innovative analytics technology,
              including machine learning and deep learning, to discover abnormal
              and risky behavior by users, machines, and other entities on the
              corporate network in conjunction with a SIEM.
            </h3>
            <br />
            <br />
            Namely, SIEM is oriented on point-in-time analysis of known threats.
            UEBA, meanwhile, provides real-time analysis of activity that can
            detect unknown threats as they happen and even predict a security
            incident based on anomalous behavior by a user or entity.
           
          
        </p></Fade>
      </div>

    
      <div className="box2">
        <Fade bottom cascade>
          <p>
            <h2>How UEBA Works?</h2>
            <br />
            <br />
            As a native security analytics module in the UEBA platform, UEBA
            utilizes machine learning to identify normal behavior and alert to
            risky deviations that suggest insider threats, lateral movement, or
            attacks at the end of the cyber kill chain.
            <br />
            <br />
            <h2> UEBA Features:</h2>
          </p>
          <div className="rowabout">
            <div className="columnabout boxes seven">
              <h2 className="eng">INSIDER THREAT DETECTION</h2>Detect
              compromised accounts and privilege abuse through user behavior
              analysis.
            </div>
            <div className="columnabout boxes seven">
              <h2 className="eng">GENERATE AUTOMATIC REPORTS</h2>Meet data
              compliance standards including PCI and HIPAA.
            </div>
            <div className="columnabout boxes seven">
              <h2 className="eng">DATA EXFILTRATION DETECTION</h2>
              Detect late-stage attacks by identifying when data is being
              exfiltrated from your environment.
            </div>
            <div className="columnabout boxes seven">
              <h2 className="eng">ENTITY ANALYTICS</h2>
              Monitor all your connected devices and networks, from industrial
              control systems to the cloud. Detect abnormal configurations or
              alterations to security logging to prevent covert attacker access.
            </div>
          </div>
          <div className="rowabout">
            <div className="columnabout boxes seven">
              <h2 className="eng">CREDENTIAL ABUSE DETECTION</h2>
              Identify compromised credentials and passwords by observing logins
              that are indicative of account abuse by attackers.
            </div>
            <div className="columnabout boxes seven">
              <h2 className="eng">COMPROMISED VPN ACCOUNT DETECTION</h2>
              Use models of login times and locations as well as login hostnames
              to establish common login behavior for users within a network.
            </div>
            <div className="columnabout boxes seven">
              <h2 className="eng">BEHAVIOR BASELINING</h2>See when devices are
              connecting to unusual addresses and sending data outside of their
              normal boundaries.
            </div>
            <div className="columnabout boxes seven">
              <h2 className="eng">ADVANCED MACHINE LEARNING</h2>Alert to
              abnormal data flow volumes and destinations with combined machine
              learning and statistical anomaly detection.
            </div>
          </div>
          <br />
        
        </Fade>
      </div>
    </div>
  );
}
