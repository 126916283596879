  import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
export default function AppSec() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">
            SAST and DAST application security tests
          </span>
          <br />
          Secure Your Applications, <br />
          Inside-Out And Outside-In
        </p>
      </div>
      <div className="box 1">
         <Fade bottom cascade> <p>
     
            <h1>Application Security Testing</h1>
     
        
            <h3>
              Threats could pose a greater risk if they come from an insider
              application. That is because traffic can bypass all security doors
              since it belongs to a specific insider application. Therefore,
              checking and testing the application security is a crucial step
              for any company. Before taking the application to the production
              environment, it should be thoroughly tested at a simulation
              environment. The following are methods of application security
              testing:
            </h3>
            Many companies wonder about the pros and cons of choosing SAST vs.
            DAST not realizing that SAST and DAST are different testing
            approaches with different benefits. Each discovers different types
            of vulnerabilities, and both are effective in different phases of
            the SDLC. SAST should be performed early and often against all files
            containing source code. DAST should be performed on a running
            application in an environment similar to production. Thus, we at TSC
            recommend the best approach, which is to include both SAST and DAST
            in your application security testing program.
            <br/>
            <br/>

            <div className="rowabout">
       
              <div className="columnabout boxes nine two">
                <h2 className="eng">Cybersecurity<br/> Assessment</h2>
              </div>
              <div className="columnabout boxes nine two">
                <h2 className="eng">Cybersecurity <br/> Audit</h2>
              </div>
            </div>
            <br />
            <br />
   
            <br />
            <br />
            <div className="rowabout">
              
              <div className="columnabout boxes nine">
                Testing methodologies used to find security vulnerabilities that
                can make an application susceptible to attacks.
              </div>
              <div className="columnabout boxes nine">
                Testing methodologies used to find security vulnerabilities that
                can make an application susceptible to attacks.
              </div>
            </div>
            <div className="rowabout">
             
              <div className="columnabout boxes nine">
                (SAST) is a white box method of testing. It examines the code to
                find software flaws and weaknesses such as SQL injection and
                others listed in the OWASP Top 10.
              </div>
              <div className="columnabout boxes nine">
                (DAST) is a black box testing method that examines an
                application as it is running to find vulnerabilities that an
                attacker could exploit.
              </div>
            </div>
            <div className="rowabout">
           
              <div className="columnabout boxes nine">
                <span style={{ fontWnine: "bold" }}>
                  White box security testing
                </span>{" "}
              </div>
              <div className="columnabout boxes nine">
                <span style={{ fontWnine: "bold" }}>
                  Black box security testing
                </span>
              </div>
            </div>
            <div className="rowabout">
        
              <div className="columnabout boxes nine">
                The tester has access to the underlying framework, design, and
                implementation. The application is tested from the inside out.
                This type of testing represents the developer approach.{" "}
              </div>
              <div className="columnabout boxes nine">
                The tester has no knowledge of the technologies or frameworks
                that the application is built on. The application is tested from
                the outside in. This type of testing represents the hacker
                approach.
              </div>
            </div>
            <div className="rowabout">
          
              <div className="columnabout boxes nine">
                <span style={{ fontWnine: "bold" }}>Requires source code</span>{" "}
              </div>
              <div className="columnabout boxes nine">
                <span style={{ fontWnine: "bold" }}>
                  Requires a running application
                </span>
              </div>
            </div>
            <div className="rowabout">
          
              <div className="columnabout boxes nine">
                SAST does not require a deployed application. It analyzes the
                sources code or binary without executing the application.
              </div>
              <div className="columnabout boxes nine">
                DAST does not require source code or binaries. It analyzes by
                executing the application.
              </div>
            </div>
            <div className="rowabout">
         
              <div className="columnabout boxes nine">
                <span style={{ fontWnine: "bold" }}>
                  Finds vulnerabilities earlier in the software development life
                  cycle (SDLC)
                </span>{" "}
              </div>
              <div className="columnabout boxes nine">
                <span style={{ fontWnine: "bold" }}>
                  Finds vulnerabilities toward the end of the SDLC
                </span>
              </div>
            </div>
            <div className="rowabout">
         
              <div className="columnabout boxes nine">
                The scan can be executed as soon as code is deemed
                feature-complete.
              </div>
              <div className="columnabout boxes nine">
                Vulnerabilities can be discovered after the development cycle is
                complete.
              </div>
            </div>
            <div className="rowabout">
         
              <div className="columnabout boxes nine">
                <span style={{ fontWnine: "bold" }}>
                  Less expensive to fix vulnerabilities
                </span>{" "}
              </div>
              <div className="columnabout boxes nine">
                <span style={{ fontWnine: "bold" }}>
                  More expensive to fix vulnerabilities
                </span>
              </div>
            </div>
            <div className="rowabout">
           
              <div className="columnabout boxes nine">
                Since vulnerabilities are found earlier in the SDLC, it is
                easier and faster to fix them. Findings can often be fixed
                before the code enters the QA cycle.
              </div>
              <div className="columnabout boxes nine">
                Since vulnerabilities are found toward the end of the SDLC,
                fixing often gets pushed into the next cycle. Critical
                vulnerabilities may be fixed as an emergency release.
              </div>
            </div>
            <div className="rowabout">
          
              <div className="columnabout boxes nine">
                <span style={{ fontWnine: "bold" }}>
                  Cannot discover run-time and environment-related issues
                </span>{" "}
              </div>
              <div className="columnabout boxes nine">
                <span style={{ fontWnine: "bold" }}>
                  Can discover run-time and environment-related issues
                </span>
              </div>
            </div>
            <div className="rowabout">
          
              <div className="columnabout boxes nine">
                Since the tool scans static code, it can’t discover run-time
                vulnerabilities.
              </div>
              <div className="columnabout boxes nine">
                Since the tool uses dynamic analysis on an application, it is
                able to find run-time vulnerabilities.
              </div>
            </div>
            <div className="rowabout">
         
              <div className="columnabout boxes nine">
                <span style={{ fontWnine: "bold" }}>
                  Typically supports all kinds of software
                </span>{" "}
              </div>
              <div className="columnabout boxes nine">
                <span style={{ fontWnine: "bold" }}>
                  Typically scans only apps like web applications and web
                  services
                </span>
              </div>
            </div>
            <div className="rowabout">
      
              <div className="columnabout boxes nine">
                Examples include web applications, web services.
              </div>
              <div className="columnabout boxes nine">
                DAST is not useful for other types of software.
              </div>
            </div>
            <br />
          
          <br />
          <br />
          Many companies wonder about the pros and cons of choosing SAST vs.
          DAST not realizing that SAST and DAST are different testing approaches
          with different benefits. Each discovers different types of
          vulnerabilities, and both are effective in different phases of the
          SDLC. SAST should be performed early and often against all files
          containing source code. DAST should be performed on a running
          application in an environment similar to production. Thus, we at TSC
          recommend the best approach, which is to include both SAST and DAST in
          your application security testing program.
        </p>
        </Fade>
      </div>

    
    </div>
  );
}
