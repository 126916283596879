import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Network-Implementation-01.svg";
import graph2 from "../../imgs/Network-Implementation-02.svg";

export default function Implementation() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="networkheader">
        <p className="pp">
          <span className="transparent">
            Excellency in implementation is our goal
          </span>
          <br />
          Implementation Excellence
        </p>
      </div>
      <div className="box 1">
        <Fade bottom cascade>  <p>
      
            <h1>Network implementation</h1>
     
        
            <h3>
              Network implementation is the core service and we take good care
              of core services. Our implementation covers the details of the
              details through a qualified and trained team to give you a robust
              network that will be with you for the long run.
            </h3>
       
            A combination of multiple components are required to ensure the
            highest level of cybersecurity maturity for the company. Simply put,
            the more of these components have been executed well, the more
            mature the cybersecurity of the company will be.
            <br />
            <br />
            To ensure the best quality of services and to guarantee the maximum
            return of investment, our implementation goes through multiple
            stages:
            <br />
            <img src={graph} alt="" />
       
        </p>   </Fade>
      </div>

      <div className="box2">
      <Fade bottom cascade>  <p>
          
            <h2 className="eng">Designing:</h2>
            For more details about our designing service, kindly click here.
            <h2 className="eng">Planning:</h2>A major step in ensuring a smooth
            network implementation is to have a detailed plan that outlines
            process steps, tasks, milestones, and roles and responsibilities for
            all involved.
            <br />
            Network implementation planning involves expert project planners who
            will review the high-level and detailed designs to prepare a
            ‘Network Implementation Plan’ document to serve as guidelines for
            implementing the design in the most efficient manner and with
            minimum disruption to your existing services.
            <br />
            Time, cost, and quality are the three pillars of planning network
            implementation.
            <h2 className="eng">Delivery:</h2>
            We carry out delivery of the network components carefully and
            cleanly, in which everything is labeled and packed. TSC logistic
            team is equipped with tracking systems to ensure delivery on time no
            matter the location. Our storage facilities are available and secure
            if they are needed when components need to be delivered partially
            based on project demands.
            <h2 className="eng">Execution:</h2>
            When the design is well-thought of and the plan is set according to
            the highest standards, execution will be smooth and straightforward.
            TSC’s qualified engineers and technicians will happily provide the
            full range of our network execution services:
            <br />
        
        </p>  </Fade>
        <img src={graph2} alt="" />
      </div>
     
      <div className="box">
        <Fade bottom cascade>
          <p>
            <h2 className="eng">Testing:</h2>
            Quality is not an accident; it is always the result of intelligent
            efforts. TSC cares about its reputation and client satisfaction.
            This is why we perform testing and commissioning to ensure the
            highest quality before we can see the delivery is closed. We test
            passive and connectivity components through the latest technologies
            and tools. In fact, we focus on configuration testing via tough
            procedures and come up with reports on all testing results.
            <h2 className="eng">Labeling:</h2>
            As you run your network, and especially at first, you find it hard
            to recognize which device you should use or which cable you should
            plug in. Here is where labeling comes in to guide you in identifying
            network components easily. Moreover, if you do not have a specific
            naming convention for us to follow in naming and labeling, we can
            build a naming convention according to global standards practices.
            <h2 className="eng">Finishing:</h2>
            Installation works will naturally require cleaning and tidying up
            after. So, at the finishing stage, we make sure everything returns
            better than before.
            <h2 className="eng">Documentation:</h2>
            We know what documentation means for engineers and how they are lost
            without proper documentation. We are proud of the full documentation
            we provide as it covers all details of the project, high- and
            low-level designs, connections, configurations, etc. All
            documentation is well-structured with clear indexing allow anyone to
            learn the details in no time.
          </p>
        </Fade>
      </div>
  
    </div>
  );
}
