import React, { useEffect } from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Anti-Malware-Defense-01.svg";

export default function AntiMalware() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">Anti-Malware Defense</span>
          <br />
          Malware can be dangerous. <br />
          Get proper protection
        </p>
      </div>
      <div className="box 1">
        <Fade bottom cascade>
          {" "}
          <p>
            <h1>Anti-Malware Defense</h1>
            <br />
            <br />
            Malware typically refers to a code developed by cyber attackers,
            designed to cause extensive damage to data and systems or to gain
            unauthorized access to a network. Malware is the collective name for
            a number of malicious software variants, including viruses,
            ransomware, spyware, etc.
            <br />
            <br />
            <h3>Malware Types:</h3>
          </p>{" "}
     
        <img src={graph} alt="" />

        <p>
          <br />
          <br />
          Do not delay protecting your endpoints against malware. Start now and
          select the suitable anti-malware measures that can be integrated with
          your cybersecurity environment, and controlled, monitored and managed
          from a single centralized console. Our mission is to make it easy,
          fast, and cost effective for you, while assuring the highest returns
          of your investment in anti-malware.
        </p>   </Fade>
      </div>


    </div>
  );
}
