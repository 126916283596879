 import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/NDR-01.svg";

export default function NDR() {
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">Network Detection and Response</span>
          <br />
          Deep Inspection for Forensic <br />
          Investigation
        </p>
      </div>
      <div className="box 1">
     <Fade bottom cascade>   <p> 
         
            <h1>Network Detection and Response (NDR)</h1>
            <h3>
              Network Detection and Response (NDR) is the key to network threat
              detection, and fast threat response is comprehensive, offering
              real-time visibility into your entire IT infrastructure. NDR
              delivers full-packet capture, metadata and netflow, either on
              premises, in the cloud, and across virtual infrastructures. Detect
              and monitor emerging, targeted, and unknown threats as they
              traverse the network. Moreover, reconstruct entire network
              sessions for forensic investigations.
            </h3>
            <br />
            <br />
  
         
            NDR is one of the SIEM family of solutions. Complemented with SIEM,
            it allows deep inspection of hundreds of protocols with a powerful,
            integrated tool kit for forensic investigations. So it is highly
            recommended to choose the right selection for all your SOC products.
            Integration is the number one consideration to ensure the highest
            optimization.
         
          <br />
          <br />
        </p>
        <img src={graph} alt="" /> </Fade>
      </div>

 
    </div>
  );
}
