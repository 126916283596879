import React, { useEffect } from "react";

import Fade from "react-reveal/Fade";

export default function Why() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="aboutheader">
        <p className="pp">
          <span className="transparent">
            More than 16 reasons to select TSC
          </span>
          <br />
          When looking for security, choose the best
        </p>
      </div>

      <div className="why">
        <h1 className="whytsc">Why TSC?</h1>
        <h3 className="focus">
          Focus on growing your business while TSC focus on securing it
        </h3>
        <Fade bottom cascade>
          {" "}
          <div className="rowabout w">
            <div className="columnabout s">
              <div className="whybullet">
                <h2>EXPERIENCE</h2>
                <ul>
                  <li>10+ Years</li>
                  10 years of experience for doing numerous projects.
                  <li>400+ Clients​</li>
                  More than 400 satisfiied clients.
                  <li>500+ Projects​</li>
                  Completed more than 500 different projects.
                  <li>Expert Team</li>
                  Highly qualified team with international certifications and
                  unique experience.
                </ul>
              </div>
            </div>
            <div className="columnabout s">
              <div className="whybullet">
                <h2>PROJECT MANAGEMENT</h2>
                <ul>
                  <li>Match Your Needs​</li>
                  When we discuss your needs, we cover everything that you are
                  gonna require.
                  <li>Are Cost Effective​</li>
                  We give you the most suitable technology to your needs with
                  cost efficiency in mind.
                  <li>Are Compatible</li>
                  Our solutions are always compatible with your current
                  environment.
                  <li>Are Future Proof</li>
                  We continuously keep your future needs in mind.
                </ul>
              </div>
            </div>{" "}
          </div>
          <div className="rowabout w">
            <div className="columnabout s">
              <div className="whybullet">
                <h2>SOLUTIONS DESIGN </h2>
                <ul>
                  <li>Professional Planning</li>
                  We Know projects are much easier to implement when planned
                  well, and that is why we depend on PMI standards.
                  <li>Team Structure</li>
                  The delivery team leads by a project manager who reports to
                  PMO. Not to mention, a Steering committee is also involved for
                  high-level supervision
                  <li>Reports</li>
                  We provide daily, weekly, monthly and quarterly reports that
                  offer all relevant parties complete visibility of what is
                  going on.
                  <li>Full Documentation</li>
                  Our documentation efforts cover all aspects of the project and
                  keeping everyone involved in the loop.
                </ul>
              </div>
            </div>

            <div className="columnabout s">
              <div className="whybullet">
                <h2>TSC ETHICS </h2>
                <ul>
                  <li>Credibility</li>
                  TSC believes that credibility is the cornerstone of our policy
                  so we make it a priority.
                  <li>Client First</li>
                  TSC believes that if the client is happy, then our job is
                  done. TSC always follows client-first rule.
                  <li>Flixability​</li>
                  We at TSC understand our clients' unique needs and so we offer
                  flexibility in everything, from the business model to the
                  timeline.
                  <li>Commitment​</li>
                  Our reputation hangs on our commitment. That is why TSC shows
                  equal commitment to all its clients.
                </ul>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}
