import React, { useEffect } from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Computing-and-UCC-01.svg";

export default function Com() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="comheader">
        <p className="pp">
          <span className="transparent">Computing and UCC</span>
          <br />
          Make the Right Choice for
          <br />
          Entering Your Data
        </p>
      </div>
      <div className="box 1">
        <Fade bottom>
          {" "}
          <p>
            <h1>Computing and UCC</h1>

            <h3>
              Data transmitted across a network is generated by and entered via
              one of three major sources: server machines, client machines, or
              collaboration (Unified Communication and Collaboration) machines.
              The latter type mostly accounts for up to 50% of the IT
              infrastructure investment. Making the right choice here will lead
              to smooth management/maintenance and superior control.
            </h3>
          </p>{" "}
        </Fade>{" "}
        <Fade bottom cascade>
          <img src={graph} alt="" />
        </Fade>
      </div>
    </div>
  );
}
