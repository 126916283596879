import React, {  useEffect } from "react";
import logoc1 from "../imgs/saudi.svg";
import logoc2 from "../imgs/10.svg";
import logoc3 from "../imgs/nahl.svg";
import logoc4 from "../imgs/moh.svg";
import logoc5 from "../imgs/iso.svg";
import Fade from "react-reveal/Fade";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


    return (
      <div>
        <div className="aboutheader">
          <p className="pp">
            <span className="transparent">
              More than 16 reasons to select TSC
            </span>
            <br />
            When looking for security, choose the best
          </p>
        </div>
        <div className="about">
          <Fade bottom cascade>
            <div className="rowabout a">
              <div className="columnabout">
                <img className="certified" src={logoc1} alt="​" />
                <p className="aboutlogos">
                  <h1>Saudi Company</h1>
                  TSC is proud of being a Saudi company,
                  <br /> based in Riyadh and owned by Saudi citizens.
                </p>
              </div>
              <div className="columnabout">
                <img className="certified" src={logoc2} alt="​" />
                <p className="aboutlogos">
                  <h1>10+ Years of Experience</h1>
                  TSC was founded in 2009. Since its launch, TSC has focused on
                  building a good reputation and developing our nation.
                </p>
              </div>
              <div className="columnabout">
                <img className="certified" src={logoc3} alt="" />
                <p className="aboutlogos">
                  <h1>Part of NAHL Holding Family</h1>
                  TSC is one of NAHL Holding subsidiaries. NAHL is a holding
                  company that provides its companies with full backend shared
                  services to empower them in delivering qualified services.
                </p>
              </div>
            </div>
            <div className="rowabout a">
              <div className="columnabout">
                <img className="certified" src={logoc4} alt="" />
                <p className="aboutlogos">
                  <h1>Classified by MOMRA</h1>
                  TSC is proud to be classified by the Ministry of Municipal,
                  Rural Affairs and Housing as a qualified Saudi company
                  following its long track record of success. ​
                </p>
              </div>{" "}
              <div className="columnabout">
                <img className="certified" src={logoc5} alt="" />
                <p className="aboutlogos">
                  <h1>Certified by ISO</h1>
                  TSC is an ISO certified company for the quality services it
                  provides and through our expert team and measured progress. ​
                </p>
              </div>
            </div>
          </Fade>
        </div>
      
        <div className="visions">
          <Fade right>
            <div className="vision">
              <h1>Vision</h1>
              <p>
                TSC is looking to become a leading IT company in the region.
                Participating in developing our nation, and serving our country
                and people with the highest quality of products and services as
                best as we can.
              </p>
            </div>
          </Fade>
          <Fade left>
            <div className="mission">
              <h1>Mission</h1>
              <p>
                TSC aims of empowering its clients with innovation and
                technology by implementing cutting-edge products and services
                which help them be the best in their fields.
              </p>
            </div>
          </Fade>
        </div>
       

        </div>
    
    );
 
}
