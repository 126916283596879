  import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Screenshot-2021-12-16-080205-768x301.png";

export default function GRC() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">
            Governance, risk management, and compliance
          </span>
          <br />
          Applied to the max
        </p>
      </div>
      <div className="box 1">
          <Fade bottom cascade>  <p>
         
            <h1>GRC</h1>
         
      
            <h3>
              GRC stands for Governance, Risk management, and Compliance, and it
              aims at ensuring the company can freely go after achieving its
              objectives, address uncertainty, and act with integrity. GRC is a
              collection of critical and synergistic capabilities that work
              together to achieve aligned business objectives.
            </h3>
            It is a strategy for managing a company’s overall governance, risk
            management, and compliance with regulations. Think of GRC as a
            structured approach to aligning IT with business objectives, while
            effectively managing risks and meeting compliance requirements.
            <br />
            <br />
         
        </p> </Fade>
      </div>

 
      <div className="box2">
        <Fade bottom cascade>
          <h3>
            A well-planned GRC strategy backed by an effective GRC platform
            leads to several benefits:
          </h3>
          <img src={graph} alt="" />
        </Fade>
        <Fade bottom cascade>
          <p>
            <br />
            <br />
            Implementing GRC through a GRC platform is easier, faster, and more
            efficient in covering all the required domains and controllers.
            <br />
            At TSC, we strongly recommend deploying a GRC platform to achieve
            the best results. Through using a suitable platform, governance will
            be state of the art.
            <br />
            The continuity of governance is just as important as governance
            itself. Keeping the company governed at all times is one of the main
            goals of a GRC platform.
            <br />
            <br />
          </p>
        </Fade>
      </div>
    </div>
  );
}
