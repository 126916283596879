import React from "react";

export default function Card({ title, imgurl, body, link}) {
  return (
    <div className="card-container">
   
      <div className="card-content">
        <div className="card-title">
        <img className="image-container" src={imgurl} alt="" />
          <h3>{title}</h3>
        </div>
        <div className="cards-body">
          <p>{body}</p>
        </div>
      </div>
      <div className="btn">
        <button className="cardbtn">
          {link}
        </button>
      </div>
    </div>
  );
}
