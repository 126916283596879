import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/ITOM-–-Application-Management-02.svg";
export default function ItomA() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="itmheader">
        <p className="pp">
          <span className="transparent">Application Management</span>
          <br />
          Knowing All You Can <br />
          About Your applications is Key
        </p>
      </div>
      <div className="box 1">
              <Fade bottom cascade>    <p>
       
            <h1>Application Management</h1>
  

            <h3>
              Manage, troubleshoot, and optimize everything from your
              infrastructure to your end-user experience, including broad
              language support for your applications. Proactive measurement of
              web application’s availability and performance minimizing the risk
              of losing customers and prospects due to a poor user experience.
              Application Management Provides complete visibility into the
              performance and response time of your key business applications
              and transactions. The monitoring of the availability and
              performance of IT Hardware and Software computing, storage, and
              networking components. In addition, the monitoring and lifecycle
              management of large volume, time-series machine data created
              across information systems.
            </h3>
      
   
            Without monitoring and management solutions, you would not be aware
            of updates or risks at the application layer. Even if the company
            has a monitoring and management solution but is not well-integrated
            or is not easy to manage, the usefulness of such a solution will be
            at its minimum. TSC will gladly help you, starting from the
            selection and ending with the final tuning of the project.
        
        </p>  </Fade>
      </div>
   
      <div className="box2">
        <Fade bottom cascade>
          <img src={graph} alt="" />
        </Fade>
        <p>
          <Fade bottom cascade>
            <h2 className="eng">Application and Infrastructure Monitoring</h2>
            <ul>
              <li>infrastructure monitoring.</li>
              <li>
                Continuous visibility into hosts, containers, and your
                serverless environments. Know the moment there is an issue and
                minimize mean time to resolution (MTTR).
              </li>
              <li>
                Rapidly drill down into minute details of a specific resource on
                a host or container from a high level on your infrastructure.
              </li>
              <li>Find your way down to poor-performing lines of code.</li>
              <li>
                Discover all the logs from the services associated with a
                transaction, reducing the time it takes to get to the root cause
                of application performance issues.
              </li>
              <li>
                Know the impact host and container resources and availability
                are having on your application performance with combined
                dashboards and proactive, advanced alerting.
              </li>
              <li>
                Maps and dashboards to keep you on top of the control efforts.
              </li>
            </ul>
            <h2 className="eng">Web Performance Management</h2>
            Make your websites faster and more reliable with easy-to-use web
            performance and digital experience monitoring.
            <br />
            <ul>
              <li>Real User Monitoring (RUM)</li>
              <li>Transaction monitoring</li>
              <li>Page speed monitoring</li>
              <li>Uptime monitoring</li>
              <li>Immediate alerts</li>
              <li>Root cause analysis</li>
              <li>Reports and Dashboards</li>
              <li>Outside-in, client-side monitoring</li>
            </ul>
          </Fade>
          <Fade bottom cascade>
            <h2 className="eng">Log Management</h2>
            Cloud-hosted log management for faster troubleshooting of
            infrastructure and application issues.
            <br />
            <ul>
              <li>Aggregate logs</li>
              <li>Tail and search logs</li>
              <li>Instant alerts</li>
              <li>Analyze logs</li>
              <li>Scale logs</li>
              <li>Encrypted logs</li>
              <li>Filter logs</li>
              <li>Clone existing alerts</li>
              <li>Colorize links</li>
              <li>Utilize command-line client</li>
            </ul>
            <h2 className="eng">Log Monitoring and Analytics</h2>
            See the data and metrics that matter from dozens of sources.
            <br />
            <ul>
              <li>
                View app performance, system behavior, and unusual activity
                across the stack. Monitor key resources and metrics and
                eliminate issues before they affect users.
              </li>
              <li>
                Work well as a team using data and analysis with DevOps tools.
                Integrates with Slack, GitHub, Jira, PagerDuty, Microsoft Teams,
                custom webhooks, and more.
              </li>
              <li>
                Analyze and visualize your data to answer key questions, track
                SLA compliance, and spot trends.
              </li>
            </ul>
          </Fade>
        </p>
      </div>
    </div>
  );
}
