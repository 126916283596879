import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Computing-–-Servers-01.svg";

export default function ComS() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="comheader">
        <p className="pp">
          <span className="transparent">Servers</span>
          <br />
          Unified Communication &
          <br />
          Collaboration (UCC)
        </p>
      </div>
      <div className="box 1">
        <Fade bottom> <p>
         
            <h1>UCC Servers</h1>
    
            <h3>
              Respond rapidly to business opportunities and maximize
              productivity by modernizing your IT infrastructure with computing
              that powers transformation. Utilize advanced automation to
              optimize and accelerate innovation with High-Performance
              Computing.
            </h3>
            The options available today for server computing are different from
            those of yesterday. At TSC, we cover all types of servers seen here:
         
        </p> </Fade>
      </div>
 
      <div className="box2">
        <Fade bottom cascade>
          <img src={graph} alt="" />
       
        <p>
          On top of that, virtualization can be deployed in any computing option
          of the above. It does not only involve having virtual servers that
          could cut costs and efforts, but also offers virtualization management
          for how machines are well-managed through a management solution for
          this specific task.
          <br />
          <br />
          TSC will review company requirements, check expansion probabilities,
          IT team capabilities and experience, in addition to numerous other
          considerations to come up with the perfect solution for the company.
        </p> </Fade>
      </div>
    </div>
  );
}
