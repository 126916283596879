import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
export default function Vulnerability() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">Vulnerability Assessment</span>
          <br />
          Find Vulnerabilities before <br />
          Others Find Them
        </p>
      </div>
      <div className="box 1">
          <Fade bottom cascade>
          <h1>Vulnerability Assessment</h1>

        <p>
       
            <h3>
              Vulnerability Assessment aims at identifying vulnerabilities in
              company networks and systems. The technique is used to estimate
              how susceptible the network is to different vulnerabilities.
              Vulnerability Assessment involves the use of automated network
              security scanning tools, whose results are listed in the report.
            </h3>
            <br />
            <br />
            <h4>Why and when you need Vulnerability Assessment?</h4>
            <br />
            Simply, the Vulnerability Assessment will determine and define the
            risks, vulnerabilities, and potential vulnerabilities that the
            company may face.
            <br />
            <br />
            The best practice for a normal company is to perform one
            Vulnerability Assessment a month to ensure covering all new updates
            in your environment.
            <br />
            <br />
            TSC highly recommends carefully selecting a Vulnerability Assessment
            tool to optimize the results and organize them, effectively
            achieving the following:
            <br />
            <br />
            <div className="rowabout">
              <div className="columnabout boxes eight">
                Active scanning, agents, passive monitoring, cloud connectors,
                and CMDB integrations provide unified visibility and a
                continuous view of all of your assets, both known and previously
                unknown.
              </div>
              <div className="columnabout boxes eight">
                Using the proper tools that has the industry’s most extensive
                CVE and security configuration support to help you understand
                all your exposures.
              </div>
              <div className="columnabout boxes eight">
                Combine vulnerability data, threat intelligence, and data
                science for easy-to-understand risk scores to quickly assess
                risk and know which vulnerabilities to fix first.
              </div>
            </div>
        
        </p>  </Fade>
      </div>

 
    </div>
  );
}
