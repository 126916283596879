import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import graph from "../../imgs/waf.png";

export default function WAF() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [counterOn, setCounterOn] = useState(false);

  if (window.innerWidth > 768) {
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">Web Application Firewall</span>
          <br />
          Focus on developing your web <br />
          apps and leave security to us
        </p>
      </div>
      <div className="box 1">
      <Fade bottom cascade>   <p>
        
            <h1>Web Application Firewall (WAF)</h1>
            <h3>
              A Web Application Firewall (WAF) helps protect web applications by
              filtering and monitoring HTTP traffic between a web application
              and the Internet. It typically protects web applications from
              attacks such as cross-site forgery, cross-site-scripting (XSS),
              file inclusion, SQL injection, and others. A WAF is a layer 7
              defense protocol (in the OSI model), and is not designed to defend
              against all types of attacks. This method of attack mitigation is
              usually part of a suite of tools to create a holistic defense
              against a range of attacks.
            </h3>
  
         
            By deploying a WAF in front of a web application, a shield is placed
            between the web application and the Internet. While a proxy server
            protects a client machine’s identity by using an intermediary, a WAF
            is a type of reverse-proxy, protecting the server from exposure by
            having clients pass through the WAF before reaching the server.
            <br /> <br />
            A WAF operates through a set of rules often called policies. These
            policies aim to protect against vulnerabilities in the application
            by filtering out malicious traffic. The value of a WAF comes in part
            from the speed and ease with which policy modifications can be
            implemented, allowing for faster response to various attack vectors;
            during a DDoS attack, rate limiting can be quickly implemented by
            modifying WAF policies.
            <br /> <br />
         
          <br />
          <br />
        </p> </Fade>
      </div>
      <div>
        <ScrollTrigger
          onEnter={() => setCounterOn(true)}
          onExit={() => setCounterOn(true)}
        >
          <div className="numbersrow">
            <div className="numbers">
              {counterOn && (
                <CountUp start={0} end={92} duration={1.5} delay={0} />
              )}
              %
              <p className="numbersp">
                92% of reported vulnerabilities
                <br /> are in applications, not networks{" "}
              </p>
            </div>
            <div className="numbers">
              {counterOn && (
                <CountUp start={0} end={62} duration={1.5} delay={0} />
              )}
              %
              <p className="numbersp">
                62% of companies are not confident
                <br /> about their application security{" "}
              </p>
            </div>
          </div>
        </ScrollTrigger>
      </div>
  
      <div className="box2">
        <h3>Types of WAF:</h3>
        <br />
        <br />
        <div className="rowabout">
          <div className="columnabout boxes five three"></div>
          <div className="columnabout boxes five three">
            <h2 className="eng">Hardware-based</h2>
          </div>
          <div className="columnabout boxes five three">
            <h2 className="eng">Software-based</h2>
          </div>
          <div className="columnabout boxes five three">
            <h2 className="eng">Cloud-based</h2>
          </div>
        </div>
        <div className="rowabout">
          <div className="columnabout boxes six">
            <h2 className="eng t">Types</h2>
          </div>
          <div className="columnabout boxes five">
            A hardware-based WAF is a hardware that is installed locally within
            the local area network (LAN) close to the web and application
            servers. An operating system runs within the appliance, supporting
            software configurations and updates.
          </div>
          <div className="columnabout boxes five">
            A software-based WAF is installed on a virtual machine (VM) instead
            of a physical hardware appliance. All WAF components are essentially
            the same as a hardware WAF. The only difference is that users would
            need to have their own hypervisor to run the virtual machine.
          </div>
          <div className="columnabout boxes five">
            A cloud-based WAF is a newer generation of WAF that is provided and
            managed directly by a service provider in the form of
            software-as-a-service (SaaS). Unlike software-based WAF, here the
            WAF components are entirely located on the cloud, so that the user
            does not have to install anything, neither locally nor on a virtual
            machine.
          </div>
        </div>

        <div className="rowabout">
          <div className="columnabout boxes six">
            <h2 className="eng t">What is it?</h2>
          </div>
          <div className="columnabout boxes five">
            The greatest advantage of a hardware-based WAF is fast speed and
            high performance. Due to its physical proximity to the server, it
            tracks and filters data packets to and from the website with very
            low latency. The major downside is that owning and maintaining
            hardware machines is not cheap. From acquisition and installation to
            storage and maintenance, hardware-based WAFs are associated with
            higher costs compared to other types of WAFs
          </div>
          <div className="columnabout boxes five">
            The main advantage of a software-based WAF is flexibility. Not only
            can it be used within an on-premises system, the virtual machine can
            also be deployed in the cloud, connecting to cloud-based web and
            application servers. A software WAF is also a cheaper option
            compared to hardware WAFs. However, the major downside is that since
            it runs on a virtual machine, higher latency is expected during
            monitoring and filtering processes, making it a little slower than
            hardware WAFs.
          </div>
          <div className="columnabout boxes five">
            The major advantage of cloud-based WAF is simplicity. The user does
            not need to install any software physically and only needs to enroll
            in a subscription plan. The service provider provides all the
            optimization and updates, so that the user does not need to manage
            the WAF by themselves. On the other hand, the disadvantage is that
            since the WAF is entirely managed by the service provider, there is
            not much room for customization.
          </div>
        </div>
        <div className="rowabout">
          <div className="columnabout boxes six">
            <h2 className="eng t">Suitable for who?</h2>
          </div>
          <div className="columnabout boxes five">
            A hardware-based WAF is commonly used by large companies who gain
            hundreds of thousands of visits on a daily basis. This is because
            hardware-based WAF is suitable to handle a massive amount of clients
            efficiently and at speed when performance is the highest priority.
            Besides, most large businesses can easily afford the managing and
            operating costs of running the hardware.
          </div>
          <div className="columnabout boxes five">
            Clearly, software WAFs are commonly used for companies with web and
            application servers based in the cloud, such as data centers and
            hosting providers. They are also popular among small and
            medium-sized enterprises who need to protect their web applications
            at lower costs.
          </div>
          <div className="columnabout boxes five">
            Cloud-based WAFs are suitable for most small and medium-sized
            companies, since they do not require physical storage place and
            manual maintenance. These are great for companies without extra
            resources to manage a WAF.
          </div>
        </div>
      </div>
    </div>
  );
} else {
  return(
    <div>
    <div className="cyberheader">
      <p className="pp">
        <span className="transparent">Web Application Firewall</span>
        <br />
        Focus on developing your web <br />
        apps and leave security to us
      </p>
    </div>
    <div className="box 1">
    <Fade bottom cascade>   <p>
      
          <h1>Web Application Firewall (WAF)</h1>
          <h3>
            A Web Application Firewall (WAF) helps protect web applications by
            filtering and monitoring HTTP traffic between a web application
            and the Internet. It typically protects web applications from
            attacks such as cross-site forgery, cross-site-scripting (XSS),
            file inclusion, SQL injection, and others. A WAF is a layer 7
            defense protocol (in the OSI model), and is not designed to defend
            against all types of attacks. This method of attack mitigation is
            usually part of a suite of tools to create a holistic defense
            against a range of attacks.
          </h3>

       
          By deploying a WAF in front of a web application, a shield is placed
          between the web application and the Internet. While a proxy server
          protects a client machine’s identity by using an intermediary, a WAF
          is a type of reverse-proxy, protecting the server from exposure by
          having clients pass through the WAF before reaching the server.
          <br /> <br />
          A WAF operates through a set of rules often called policies. These
          policies aim to protect against vulnerabilities in the application
          by filtering out malicious traffic. The value of a WAF comes in part
          from the speed and ease with which policy modifications can be
          implemented, allowing for faster response to various attack vectors;
          during a DDoS attack, rate limiting can be quickly implemented by
          modifying WAF policies.
          <br /> <br />
       
        <br />
        <br />
      </p> </Fade>
    </div>
    <div>
      <ScrollTrigger
        onEnter={() => setCounterOn(true)}
        onExit={() => setCounterOn(true)}
      >
        <div className="numbersrow">
          <div className="numbers">
            {counterOn && (
              <CountUp start={0} end={92} duration={1.5} delay={0} />
            )}
            %
            <p className="numbersp">
              92% of reported vulnerabilities
              <br /> are in applications, not networks{" "}
            </p>
          </div>
          <div className="numbers">
            {counterOn && (
              <CountUp start={0} end={62} duration={1.5} delay={0} />
            )}
            %
            <p className="numbersp">
              62% of companies are not confident
              <br /> about their application security{" "}
            </p>
          </div>
        </div>
      </ScrollTrigger>
    </div>

    <div className="box2">
      <h3>Types of WAF:</h3>
      <br />
      <br />
      <img src={graph} alt="" />
    </div>
  </div>
  )
}
}
