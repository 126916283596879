import React, { useEffect } from "react";
import Fade from "react-reveal/Fade";
import logo1 from "../imgs/vender-logo1-10.svg";
import logo2 from "../imgs/vender-logo1-11.svg";
import logo3 from "../imgs/vender-logo1-12.svg";
import logo4 from "../imgs/vender-logo1-13.svg";
import logo5 from "../imgs/vender-logo1-14.svg";
import logo6 from "../imgs/vender-logo1-15.svg";
import logo7 from "../imgs/vender-logo1-16.svg";
import logo8 from "../imgs/vender-logo1-17.svg";

export default function Partners() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="partnersheader">
        <p className="pp">
          <span className="transparent">
            Our success is first from
            <br />
            Allah and then our trusted partners
          </span>
          <br />
          Thank You!
        </p>
      </div>
      <div className="box 1">
        <Fade bottom cascade>
          {" "}
          <p>
            <h1>Partners </h1>
            <div className="partners">
              <img src={logo1} alt="" />
              <h3> TITANIUM PARTNER</h3>
              RSA Security LLC, formerly RSA Security, Inc. and doing business
              as RSA, is an American computer and network security company with
              a focus on encryption and encryption standards. NetWitness is an
              Evolved SIEM and Open XDR platform that accelerates threat
              detection and response. It can collect and analyze data across all
              capture points (logs, packets, netflow, endpoint and IoT) and
              computing platforms (physical, virtual and cloud), enriching data
              with threat intelligence and business context.
              <br />
              <br />
              <br />
              <a
                href="https://www.rsa.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.rsa.com
              </a>
              <br />
              <br />
            </div>{" "}
            <hr />
            <div className="partners">
              <img src={logo2} alt="" />
              <h3> GOLDEN PARTNER</h3>
              SolarWinds Inc. is an American company that develops software for
              businesses to help manage their networks, systems, and information
              technology infrastructure. It is headquartered in Austin, Texas,
              with sales and product development offices in a number of
              locations in the United States and several other countries.
              SolarWinds delivers powerful and affordable IT management and
              monitoring software to over 100,000 customers worldwide – from
              Global 1000 enterprises to small businesses. Named by Forbes as
              one of the top 10 fastest growing technology companies, SolarWinds
              is improving the way IT management software is developed, priced,
              purchased, delivered, and used
              <br />
              <br />
              <br />
              <a
                href="https://www.solarwinds.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.solarwinds.com
              </a>
              <br />
              <br />
            </div>
            <hr />
            <div className="partners">
              <img src={logo3} alt="" />
              <h3> SILVER PARTNER</h3>
              Ivanti is an IT software company headquartered in South Jordan,
              Utah. It produces software for IT Security, IT Service Management,
              IT Asset Management, Unified Endpoint Management, Identity
              Management and Supply Chain Management.
              <br />
              <br />
              <br />
              <a
                href="https://www.ivanti.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.ivanti.com
              </a>
              <br />
              <br />
            </div>
            <hr />
            <div className="partners">
              <img src={logo4} alt="" />
              <h3> INNOVATOR PARTNER</h3>
              Palo Alto Networks, Inc. is an American multinational
              cybersecurity company with headquarters in Santa Clara,
              California. Its core products are a platform that includes
              advanced firewalls and cloud-based offerings that extend those
              firewalls to cover other aspects of security
              <br />
              <br />
              <br />
              <a
                href="https://www.paloaltonetworks.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.paloaltonetworks.com
              </a>
              <br />
              <br />
            </div>
            <hr />
            <div className="partners">
              <img src={logo5} alt="" />
              <h3> SILVER PARTNER</h3>
              Tenable, Inc. is a cybersecurity company based in Columbia,
              Maryland. It is known as the creator of the vulnerability scanning
              software Nessus
              <br />
              <br />
              <br />
              <a
                href="https://www.tenable.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.tenable.com
              </a>
              <br />
              <br />
            </div>
            <hr />
            <div className="partners">
              <img src={logo6} alt="" />
              <h3> BRONZE PARTNER</h3>
              Trend Micro Inc. is an American-Japanese multinational cyber
              security software company with global headquarters in Tokyo, Japan
              and Irving, Texas, United States, with regional headquarters and
              R&D centers in Asia, Europe, and North America. Trend Micro, a
              global cybersecurity leader, helps make the world safe for
              exchanging digital information. Fueled by decades of security
              expertise, global threat research, and continuous innovation, our
              cybersecurity platform protects 500,000+ organizations and 250+
              million individuals across clouds, networks, devices, and
              endpoints.
              <br />
              <br />
              <br />
              <a
                href="https://www.trendmicro.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.trendmicro.com
              </a>
              <br />
              <br />
            </div>
            <hr />
            <div className="partners">
              <img src={logo7} alt="" />
              <h3> PREMIER PARTNER</h3>
              Cisco Systems, Inc. is an American multinational technology
              conglomerate corporation headquartered in San Jose, California.
              Integral to the growth of Silicon Valley, Cisco develops,
              manufactures, and sells networking hardware, software,
              telecommunications equipment and other high-technology services
              and products.
              <br />
              <br />
              <br />
              <a
                href="https://www.cisco.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.cisco.com
              </a>
              <br />
              <br />
            </div>
            <hr />
            <div className="partners">
              <img src={logo8} alt="" />
              <h3> AUTHORIZED PARTNER</h3>
              Infoblox, formerly, is a privately held IT automation and security
              company based in California’s Silicon Valley. The company focuses
              on managing and identifying devices connected to
              networks—specifically for the Domain Name System, Dynamic Host
              Configuration Protocol, and IP address management
              <br />
              <br />
              <br />
              <a
                href="https://www.infoblox.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.infoblox.com
              </a>
            </div>
          </p>
        </Fade>
      </div>

  
    </div>
  );
}
