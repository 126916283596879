  import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Network-Security-01.svg"

export default function NetworkSec() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">
          Your network is your organization’s<br/>heart, so secure it
          </span>
          <br />
          The first practical actions <br />
          should be placed well
        </p>
      </div>
      <div className="box 1">
       <Fade bottom cascade> <p>
     
            <h1>Networking Security</h1>
      
          
          Digitization has entirely transformed our world; the way we live, learn, and play has been completely revolutionized as everything nowadays depends on networks, which have become the first line of providing and receiving services. Both customers and employees will reach company services through a network. Network security has become one of the major requirements for running any business today.
       

            <br />
            <br />
          
        </p>   </Fade>
        <img src={graph} alt="" />
      </div>

 
    </div>
  );
}
