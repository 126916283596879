import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Sandbox-01.svg";
export default function Sandbox() {
    
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="itmheader">
        <p className="pp">
          <span className="transparent">
            Sandbox Emulation for secured Production
          </span>
          <br />
          Bury attacks in the sand
        </p>
      </div>
      <div className="box 1">
      <Fade bottom cascade> <p>
         
            <h1>Sandbox</h1>
          
            <h3>
              In cybersecurity, a sandbox is an isolated environment on a
              network that mimics end-user operating environments. Sandboxes are
              used to safely execute suspicious code without risking harm to the
              host device or network.
            </h3>
            Using a sandbox for advanced malware detection provides another
            layer of protection against new security threats, especially
            previously unseen malware and stealthy attacks. The benefit here is
            that what happens in the sandbox, stays in the sandbox, effectively
            avoiding system failures and keeping software vulnerabilities from
            spreading.
            <br />
            <br />
            <h3>How Does Sandbox Technology Work?</h3>
            <br />
            <br />
            Sandbox testing proactively detects malware by executing, or
            detonating, code in a safe and isolated environment to observe that
            code’s behavior and output activity. Traditional security measures
            are reactive and based on signature detection, which works by
            looking for patterns identified in known instances of malware.
            Because that detects only previously identified threats, sandboxes
            add another important layer of security. Moreover, even if an
            initial security defense utilizes artificial intelligence or machine
            learning (signature-less detection), these defenses are only as good
            as the models powering these solution; there is still a need to
            complement these solutions with advanced malware detection.
            <br />
            <br />
         
        </p> </Fade>
      </div>
      <div className="box2">
        <Fade bottom cascade>
          <h3>
            TSC provides three implementation options based on the different
            needs for our valuable clients.
          </h3>
          <img src={graph} alt="" />
        </Fade>
      </div>
    </div>
  );
}
