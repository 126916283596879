import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";

export default function CCTV() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">Closed-circuit Television (CCTV)</span>
          <br />
          Your Eyes and Ears
        </p>
      </div>
      <div className="box 1">
         <Fade bottom cascade><p>
     
            <h1>Closed-circuit television (CCTV)</h1>
            <h3>
              Closed-circuit television (CCTV) is your eyes and ears in your
              company. It does not sleep or become distracted, and is always on
              the look for suspicions acts. Companies today face challenges in
              controlling authorized traffic and monitoring and archiving
              movements, and the perfect solution for them is CCTV.
            </h3>
        
         
            CCTV for enterprises is completely different from that used in
            homes, differing in containers, capabilities, requirements,
            deliverables, etc. At TSC, we have a good experience in enterprise
            CCTV that can cut time and cost for any company with our ability to
            determine the exact requirement for the company, no less and no
            more. We define the project scope and timing, considering all
            company circumstances and business nature, to perform successful
            implementation.
            <br />
            <br />
            <h3>Enterprise CCTV contains three major parts:</h3>
            <ul>
              <li>
                <h2 className="eng">Endpoint Cameras and Sensors</h2>
              </li>
              <li>
                <h2 className="eng">CCTV Network</h2>
              </li>
              <li>
                <h2 className="eng">CCTV Command Center</h2>
              </li>
            </ul>
        
      
        </p>  </Fade>
      </div>

      <div className="box2">
        <Fade bottom cascade>
          <p>
            <h4>Endpoint Cameras and Sensors:</h4>
            <br />
            <br />
            Endpoint cameras come in a very wide variety of options and depends
            on lot of considerations. Selecting the suitable option for the
            company requires an assessment to define the exact specifications
            and requirements for the company, such as:
            <br />
            <br />
            <ul>
              <li>
                <span style={{ fontWeight: "bold" }}>
                  Visual and intelligence specs,
                </span>{" "}
                hich are related to resolution, night mode, face and badge
                recognition, definition of allowed and sensitive zones, etc.
                Also the angle of recording is considered, i.e., whether
                directional or 360.
              </li>
              <span style={{ fontWeight: "bold" }}> Accessories,</span>{" "}
              <li>
                which cover camera holders, boxes, arms, and speakers and
                microphones if needed.
              </li>
              <li>
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  Environmental specs,
                </span>{" "}
                which include camera specs in the face of temperatures,
                humidity, dust, etc.
              </li>
            </ul>
            <br />
            <br />
            Through all these consideration and more, we select the first part
            of the CCTV project: endpoint cameras.
            <br />
            <br />
            <br />
            <br />
            <h4>CCTV Network:</h4>
            <br />
            <br />
            The CCTV network is the way for connecting the endpoint cameras to
            the command center. TSC offers network and infrastructure as part of
            its major services. This part of the CCTV project is the easiest
            part as the considerations for selecting the type of the network are
            not too many. This simply depends on the CCTV endpoint’s type,
            location (in-door, out-door, distance) and the command center’s
            features.
            <br />
            There are two options for connecting CCTV endpoint cameras to the
            command center, either wired (regardless of cable type) or wireless.
            <br />
            Mature, stable, suitable, and efficient is our mission in this area.
            <br />
            <br />
            <br />
            <br />
            <h4>CCTV Command Center:</h4>
            <br />
            <br />
            The CCTV command center is the brain of the CCTV project. It simply
            centralizes the CCTV management, monitoring, and control for the
            whole company from single spot. The CCTV command center is divided
            into two parts:
            <br />
            <br />
            <ul>
              <li>
                <span style={{ fontWeight: "bold" }}>
                  The command facility,
                </span>{" "}
                which includes the building or room, video walls, desks, air
                conditioning, etc.
              </li>
              <span style={{ fontWeight: "bold" }}>
                {" "}
                The CCTV management system,
              </span>{" "}
              <li>
                which include the monitoring system, controlling,
                configurations, NDR (recording), and everything related to the
                endpoint cameras.
              </li>
            </ul>
          </p>
        </Fade>
      </div>
    </div>
  );
}
