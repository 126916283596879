 import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/ITM-01.svg";
import graph2 from "../../imgs/ITM-02.svg";
export default function Itm() {
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="itmheader">
        <p className="pp">
          <span className="transparent">
          IT Management
          </span>
          <br />
          MANAGE YOUR IT <br />AND HAVE FULL CONTROL
        </p>
      </div>
      <div className="box 1">
      <Fade bottom cascade>   <p>
         
            <h1>IT Management</h1>
     
          
            <h3>
            IT Management (ITM) refers to the monitoring and administration of a company’s information technology systems, i.e., hardware, software, and networks. IT management focuses on making information systems function efficiently.

            </h3>
        
            ITM solutions are no longer luxury solutions and have become necessary to efficiently manage an IT environment. It impacts how efficient managing IT becomes, simplifies troubleshooting for any issue, assists decision-makers in related topics, and streamlines expansion to a simple “copy-paste” endeavor.
            
         
        </p> </Fade>
      </div>
  
      <div className="box2">
      <Fade bottom cascade>
        <h3>Why Go for ITM?</h3>
        <img src={graph} alt="" />
        <p>
        IT environments these days are complex, which is reflected in monitoring, controlling, and managing them. Our major goal is to simplify IT management and covering all aspects.

        </p>
        <img src={graph2} alt="" />
        </Fade>
      </div>
    </div>
  );
}
