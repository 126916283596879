 import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";

export default function Strategy() {
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">
            Build up a robust Strategy for your Cybersecurity with ease
          </span>
          <br />
          The First step in securing your <br />
          cyber space
        </p>
      </div>
      <div className="box 1">
         <Fade bottom cascade>   <p>
          
            <h1>Cybersecurity Strategy</h1>
          
      
         
            We do not only develop the Cybersecurity Strategy, but we also
            embark on the journey of securing your cyber space with you. We
            start by developing a strategy that covers all possible aspects
            according to the best practices, and then we follow by helping you
            execute the strategy onto your environment, implementing all the
            points outlined in the strategy booklet. Next, we evaluate the
            strategy, focusing on two parts; the first is checking whether the
            executed strategy matches the strategy booklet, and the second is
            checking how much of the strategy the company adopted and benefited
            from it. We finally monitor whether the company is still functioning
            in line with the strategy and whether the strategy still serves the
            company as intended.
            <br />
            Even at this point, our journey is not finished yet; the goal of
            monitoring the company after executing and evaluating is to continue
            improving the strategy, updating the strategy booklet, enhancing the
            action plan for executing the strategy, and keep evaluation ongoing.
            All this is to ensure:
            <br />
            <br />
            <ul>
              <li>The highest quality for the strategy</li>
              <li>The strictest adoption of the strategy</li>
              <li>The largest benefit from the strategy</li>
            </ul>
        
        </p>  
   
       
          <p>
             <h3>
              Through our Cybersecurity Strategy service, we will cover three
              major pillars:
            </h3>
            <div className="rowabout">
              <div className="columnabout">
                <h2 className="eng">People</h2>
                Educated and empowered employees trained on how to identify and
                avoid Cyber threats and detect and report phishing emails and
                other social engineering tactics.
              </div>
              <div className="columnabout">
                <h2 className="eng">Procedures</h2>
                Business and IT processes that can make your company more
                cyber-resilient, transparent, and compliant. Also, strategies in
                place will proactively prevent and respond quickly and
                effectively to cyber-security incidents.
              </div>
              <div className="columnabout">
                <h2 className="eng">Technology</h2>
                Technology, tools, software, hardware, etc., that can be
                deployed, integrated, and automated to facilitate the rapid
                detection and mitigation of threats.
              </div>
            </div>
            <br />
            <br />
            <br />
            
            <h4>Is it suitable for us?</h4>
            <br />
            <br />
            It sure is because we at TSC design the strategy based on what the
            company needs; this means we can increase or decrease the scope
            based on needs. In other words, whatever the size of your company
            is, we will formulate a strategy that perfectly fits your company to
            achieve the highest benefit.
            <br />
            <br />
            <br />
            <br />
            <h4>How to start?</h4>
            <br />
            <br />
            Each company is different and that is why we are strong disbelievers
            in the effectiveness of one-size-fits-all solutions. We start by
            carefully defining the required scope based on the company’s
            businesses type, its stockholders, plans, and other aspects. Once we
            define these important factors, we start planning for the strategy
            project.
          </p>
        </Fade>
      </div>
    </div>
  );
}
