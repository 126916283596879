import img1 from "../imgs/logos/TSC Logos-01.png";
import img2 from "../imgs/logos/TSC Logos-02.png";
import img3 from "../imgs/logos/TSC Logos-03.png";
import img4 from "../imgs/logos/TSC Logos-04.png";
import img5 from "../imgs/logos/TSC Logos-05.png";
import img6 from "../imgs/logos/TSC Logos-06.png";
import img7 from "../imgs/logos/TSC Logos-07.png";
import img8 from "../imgs/logos/TSC Logos-08.png";
import img9 from "../imgs/logos/TSC Logos-09.png";
import img10 from "../imgs/logos/TSC Logos-10.png";
import img11 from "../imgs/logos/TSC Logos-11.png";
import img12 from "../imgs/logos/TSC Logos-12.png";
import img13 from "../imgs/logos/TSC Logos-13.png";
import img14 from "../imgs/logos/TSC Logos-14.png";
import img15 from "../imgs/logos/TSC Logos-15.png";
import img16 from "../imgs/logos/TSC Logos-16.png";
import img17 from "../imgs/logos/TSC Logos-17.png";
import img18 from "../imgs/logos/TSC Logos-18.png";
import img19 from "../imgs/logos/TSC Logos-19.png";
import img20 from "../imgs/logos/TSC Logos-20.png";
import img21 from "../imgs/logos/TSC Logos-21.png";
import img22 from "../imgs/logos/TSC Logos-22.png";
import img23 from "../imgs/logos/TSC Logos-23.png";
import img24 from "../imgs/logos/TSC Logos-24.png";
import img25 from "../imgs/logos/TSC Logos-25.png";
import img26 from "../imgs/logos/TSC Logos-26.png";
import img27 from "../imgs/logos/TSC Logos-27.png";
import img28 from "../imgs/logos/TSC Logos-28.svg";

const arr = [img12,img13,img14,img15,img16,img18,img19,img11,img27,img10,img9,img8,img7,
    img6,img5,img4,img22,img23,img25,img24,img26,img1,img2,img3,img17,img21,img20,img28];

export default arr;
