import React, { useEffect } from "react";
import Fade from "react-reveal/Fade";


export default function Cybersecurity() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">
            Focus on growing your business while we focus on securing it
          </span>
          <br />
          SECURE WHAT’S BEYOND THE CYBER​
        </p>
      </div>
      <div className="box 1">
        <Fade bottom cascade>
          {" "}
          <p>
            <h1>Cybersecurity</h1>
            <h3>
              Cybersecurity is the practice of defending your computers,
              servers, mobile devices, electronic systems, networks, programs,
              and data from malicious attacks.
            </h3>
            In recent years, Saudi Arabia has been exposed to terrorist cyber
            warfare that aims to destabilize its national security. Using
            various types of viruses, malware, and modern scamming techniques,
            attacks were meant to disrupt servers for all vital services in the
            country, thus affecting its sensitive and vital facilities. Most
            threats targeted government entities (48%), the energy sector (15%),
            and the telecommunications sector (11%), which are targets that
            reflect the threat actors’ intent on affecting the national economy.
            <br />
            With years of experience and accumulated knowledge in cybersecurity,
            TSC is devoted to improving the cybersecurity standing of the nation
            and its critical infrastructure by complying with the regulations of
            the NCSC (National Cybersecurity Centre) in Saudi Arabia.
            <br />
            We deliver cybersecurity services under three major divisions:
            <br />
            <br />
            <ul>
              <li className="prepare">
                <h3>Prepare</h3>
              </li>
              Preparing your organization for cybersecurity actions
              (Governance).
              <br/>           <br/>
              <li className="prepare"><h3>Protect</h3></li>
              Perform the required actions to protect your organization againest
              potential attacks.<br/>           <br/>
              <li className="prepare"><h3>Proof</h3></li>
              Ensure that all executed setups are effectively protecting your
              organization.
            </ul>
            <br />
          </p>{" "}
        </Fade>
      </div>

   
      
    </div>
  );
}
