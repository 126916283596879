 import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Assessment-01.svg";
import graph2 from "../../imgs/domain-chart-01.svg";
import logo1 from "../../imgs/1.png";
import logo2 from "../../imgs/2.png";
import logo3 from "../../imgs/3.png";
import logo4 from "../../imgs/4.png";
import logo5 from "../../imgs/5.png";
import logo6 from "../../imgs/6.png";
import logo7 from "../../imgs/7.jpg";

export default function CAssessment() {
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">
            Deep assessment leads to accurate results
          </span>
          <br />
          Dive deep into your cyber space <br />
          to learn about your security
        </p>
      </div>
      <div className="box 1">
          <Fade bottom cascade>  <p>
          <Fade bottom>
            <h1>Cybersecurity Assessment</h1>
          </Fade>{" "}
      
            <h3>
              No one can ever truly know the status of their cybersecurity
              unless they perform a structured assessment exercise that dives
              deep into their company.
            </h3>
            Cybersecurity Assessment is the key to the governance of a company
            and to the protection of its information assets. If a company does
            not realize the risks it faces, it will never be able to implement
            proper and effective protection.
            <br />
            TSC Cybersecurity Assessment services go through three major steps:
            <br />
            <br />
          
        </p></Fade>
        <img src={graph} alt="" />
      </div>
  
      <div className="box2">
        <Fade bottom cascade></Fade>
        <Fade bottom cascade>
          <p>
            TSC follows a comprehensive assessment process performed across all
            of the company’s assets, including information-processing systems.
            <br />
            <br />
            <h4>Discovery:</h4>
            An analysis of the results exported from the discovery. Here, the
            risks are categorized and prioritized, and then we define the best
            measures to mitigate and shut down these risks through clear
            actions.
            <br />
            <br />
            <h4>Analysis:</h4>
            The assessment will discover all cybersecurity risks and reveal
            apparent and hidden threats.
            <br />
            <br />
            <h4>Management:</h4>
            Once analysis is complete, the next step to follow is management.
            Management includes the security measures implemented to reduce the
            risks and ensure confidentiality, availability, and integrity of
            sensitive information.
            <br />
            <br />
            <br />
            Assessment, in its three steps, is not a one-time activity; the
            process will be continuously evaluated, monitored, and updated in
            response to changes in the environment.
            <br />
            Management is one of the most important services any company should
            adopt, as per the National Cybersecurity Authority (NCA)
            recommendations.
            <br />
            Cybersecurity assessment covers multiple domains, and we at TSC
            cover all these domains according to the company’s needs as the
            following:
            <br />
          </p>
          <img src={graph2} alt="" />
        </Fade>
      </div>

      <div className="box">        <Fade bottom cascade>
        <p>
          <h3>
            When we commence the assessment process, we follow specific
            standards or frameworks that should be complied with following the
            assessment:
          </h3>{" "}
          <br />
          <br />
          <div className="cyberlogos">
            <img src={logo1} alt="" />
            National Cybersecurity Authority – Essential Cybersecurity Controls
            (ECC – 1: 2018).
          </div>
          <div className="cyberlogos">
            <img src={logo2} alt="" />
            The National Institute of Standards and Technology{" "}
          </div>
          <div className="cyberlogos">
            <img src={logo3} alt="" />
            The Escal Institute of Advanced Technologies
            <br />
            <span style={{marginLeft:"328px"}}>SANS or SysAdmin, Audit, Network and Security</span>{" "}
          </div>
          <div className="cyberlogos">
            <img src={logo4} alt="" />
            Health Insurance Portability and Accountability Act{" "}
          </div>
          <div className="cyberlogos">
            <img src={logo5} alt="" />
            General Data Protection Regulation{" "}
          </div>
          <div className="cyberlogos">
            <img src={logo6} alt="" />
            Payment Card Industry Data Security Standard{" "}
          </div>
          <div className="cyberlogos">
            <img src={logo7} alt="" />
            Federal Information Security Management Act{" "}
          </div>
        </p>      </Fade>
      </div>
    
    </div>
  );
}
