 import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Network-Access-Control-NAC-01.svg";

export default function NAC() {
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">Network Access Control</span>
          <br />
          Strengthen your checkpoints,Endpoint access should be <br />
          controlled and secured
        </p>
      </div>
      <div className="box 1">
       <Fade bottom cascade>   <p>
       
            <h1>Network Access Control (NAC)</h1>
            <h3>
              Network Access Control (NAC) helps enterprises implement policies
              for controlling devices and user access to their networks. NAC can
              set policies for resource, role, device, and location-based access
              and enforce security compliance in management policies, among
              other controls.
            </h3>
       
        
            NAC is an effort to create order out of the chaos of connections
            from inside and outside the company. Personnel, customers,
            consultants, contractors, and guests all need some level of access.
            In some cases, access is on campus and at others, it is remote.
            <br />
            <br />
            NAC is an effort to create order out of the chaos of connections
            from inside and outside the company. Personnel, customers,
            consultants, contractors, and guests all need some level of access.
            In some cases, access is on campus and at others, it is remote.
       
          <br />
          <br />
        </p>   </Fade>
      </div>

     

      <div className="box2">
        <Fade bottom cascade>
          <img src={graph} alt="" />
          <p>
            <br />
            Implementing NAC with all the required controllers and policies is
            not difficult, but applying NAC into the network and checking if the
            network and endpoints follow the new restrictions is where the issue
            lies. The impact of applying the new restrictions may be too harsh
            for some endpoints, so a quick response to recover access for these
            endpoints is important and the time of recovery should be rapid.
            What we offer in TSC is provide a carefully constructed plan for all
            these actions, and dividing the application of the NAC to make it
            easier and well-controlled.
          </p>
        </Fade>
      </div>
    </div>
  );
}
