import React from "react";
import youtube from "../imgs/youtube.png";
import twitter from "../imgs/twitter.png";
import linkedin from "../imgs/linkedin.png";
import { Link } from "react-router-dom";

export default function Footer() {
  if (window.innerWidth > 768) {
    return (
      <div className="main_footer">
        <div className="container-footer">
          <div className="rowfooter" style={{ gap: "0" }}>
            <div className="column p">
              <p className="footerp">
                <span>About TSC</span>
                <br />
                <Link to="/who-tsc">Who is TSC?</Link>   <br />
                <Link to="/why-tsc">Why TSC?</Link>
                <br />
                <br />
                <br />
                <br />
              </p>
            </div>
            <div className="column p">
              <p className="footerp">
                <span>TSC Services</span>
                <br />
                <Link to="/cybersecurity">Cybersecurity</Link>
                <br />
                <Link to="/networking"> Networking</Link>
                <br />
                <Link to="/it-management">IT Managemant</Link>
                <br />
                <Link to="/computing-and-ucc">Computing & UCC</Link>
                <br />
                <Link to="/consultancy">Consultancy</Link>
              </p>
            </div>

            <div className="column p">
              <p className="footerp">
                <span>TSC Relations</span>
                <br />
                <Link to="/">Clients</Link>
                <br />
                <Link to="/">Partners</Link>
                <br />
                <Link to="/">Team</Link>
                <br />
                <br />
                <br />
              </p>
            </div>
            <div className="column p">
              <p className="footerp">
                <span>Support</span>
                <br />
                <Link to="/contact-us">Contact TSC</Link> <br />
                <br />
                <br />
                <br />
                <br />
              </p>
            </div>
            <div className="column p">
              <p className="footerp">
                <span>Follow Us </span>
                <br />
                <img src={twitter} alt="" />
                <a
                  href="https://twitter.com/TechStations"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Twitter
                </a>
                <br />
                <img src={linkedin} alt="" />
                <a
                  href="https://www.linkedin.com/company/tech-stations-est-/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Linkedin
                </a>
                <br />
                <img src={youtube} alt="" />
                <a
                  href="https://www.youtube.com/channel/UCUb1LAdS3yLvVkGEvz_y_zA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Youtube
                </a>
                <br />
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
        <hr />
        <div className="copyrights">
          &copy;{new Date().getFullYear()} - All rights reserved to Teach
          Stations Company.
        </div>
      </div>
    );
  } else {
    return (
      <div className="main_footer">
        <div className="container-footer">
          <div className="rowabout p" style={{ gap: "3rem" }}>
            <div className="column p">
              <p className="footerp">
                <span>About TSC</span>
                <br />
                <Link to="/who-tsc">Who is TSC?</Link> <br />
                <Link to="/why-tsc">Why TSC?</Link> <br />
                <br />
                <br />
                <br />
              </p>
            </div>
            <div className="column p">
              <p className="footerp">
                <span>TSC Services</span>
                <br />
                <Link to="/cybersecurity">Cybersecurity</Link>
                <br />
                <Link to="/networking"> Networking</Link>
                <br />
                <Link to="/it-management">IT Managemant</Link>
                <br />
                <Link to="/computing-and-ucc">Computing & UCC</Link>
                <br />
                <Link to="/consultancy">Consultancy</Link>
              </p>
            </div>

            <div className="column p">
              <p className="footerp">
                <span>TSC Relations</span>
                <br />
                <Link to="/">Clients</Link>
                <br />
                <Link to="/">Partners</Link>
                <br />
                <Link to="/">Team</Link>
                <br />
                <br />
                <br />
              </p>
            </div>
          </div>
          <div className="rowabout p" style={{ gap: "10rem" }}>
            <div className="column p">
              <p className="footerp">
                <span>Support</span>
                <br />
                <Link to="/contact-us">Contact TSC</Link> <br />
                <br />
                <br />
                <br />
                <br />
              </p>
            </div>
            <div className="column p">
              <p className="footerp">
                <span>Follow Us </span>
                <br />
                <img src={twitter} alt="" />
                <a
                  href="https://twitter.com/TechStations"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Twitter
                </a>
                <br />
                <img src={linkedin} alt="" />
                <a
                  href="https://www.linkedin.com/company/tech-stations-est-/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Linkedin
                </a>
                <br />
                <img src={youtube} alt="" />
                <a
                  href="https://www.youtube.com/channel/UCUb1LAdS3yLvVkGEvz_y_zA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Youtube
                </a>
                <br />
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
        <hr />
        <div className="copyrights">
          &copy;{new Date().getFullYear()} - All Rights Reserved. Designed and
          Developed by Tech Stations Company
        </div>
      </div>
    );
  }
}
