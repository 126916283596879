import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Physical-Access-Control-01.svg";

export default function PAC() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">Physical Access Control</span>
          <br />
          Control your doors and select <br />
          your guests
        </p>
      </div>
      <div className="box 1">
         <Fade bottom cascade>  <p>
       
            <h1>Physical Access Control (PAC)</h1>
            <h3>
              The first step in implementing physical security is Physical
              Access Control (PAC), which refers to restricting or allowing
              access to people in specific premises, campuses, buildings, areas,
              rooms, etc. The goal of PACS is to protect these locations from
              unauthorized physical access, protecting data and equipment.
            </h3>
            <br />
            <br />
     
       
            These are a variety of options for PAC with different techniques and
            security levels. These depend on multiple considerations and factors
            to select the proper and required PACS, such as the company’s
            business nature, sensitivity of data, budget, location, etc. Our
            mission here is to assist you in selecting the proper physical
            access control system that matches your needs and complies with
            regulations.
         
          <br />
          <br />
        </p>
        <img src={graph} alt="" />
        <p>
          <br />
          <br />
          Accurate assessment is required to select the most suitable solution
          of physical access control. Choosing the latest technologies that are
          more secured, cost effective, simple, and easy to manage is our
          mission.
        </p> </Fade>
      </div>

    </div>
  );
}
