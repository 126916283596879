import React, { useEffect } from "react";
import Fade from "react-reveal/Fade";

export default function SOAR() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">
            Security Orchestration, Automation, and Response
          </span>
          <br />
          Consolidate Your Security Tools
        </p>
      </div>
      <div className="box 1">
         <Fade bottom cascade> <p>
        
            <h1>SOAR</h1>
    
        
            <h3>
              SOAR stands for Security Orchestration, Automation, and Response.
              SOAR platforms are a collection of security software solutions and
              tools for browsing and collecting data from a variety of sources.
              SOAR solutions then use a combination of human and machine
              learning to analyze this diverse data in order to comprehend and
              prioritize incident response actions. SOAR is used to mainly
              perform three capabilities, threat and vulnerability management,
              security incident response, and security operations automation.
            </h3>
            <br />
            <br />
            SOAR allows companies to take control of any incident from detection
            to response, integrates disparate security tools, and augments them
            with advanced SIEM, Orchestration, and threat intelligence
            capabilities to capture the untapped potential of security
            investments.
            <br />
            <br />
            <br />
            <h2 className="eng">Holistic Incident Management</h2>
            <br />
            Effectively collects, standardizes and prioritizes alerts to
            streamline your SOC team’s response efforts. Leveraging expansive
            data sources, SOAR enables the collection, querying and enrichment
            of artifacts and indicators, such as users, systems, IPs and more.
            It captures the incident management lifecycle in a well-structured,
            consistent and auto-documented process.
            <br />
            <br />
            <br />
            <h2 className="eng">Threat-intelligence-powered Investigation</h2>
            <br />
            SOAR provides collaborative, threat-intelligence-powered security
            orchestration, automation, and remediation capabilities that allow
            companies to make intelligence-driven decisions. SOC teams gain
            relevant insights from intelligence sources and can take action by
            providing those insights to the necessary people and technologies.
            <br />
            <br />
            <br />
            <h2 className="eng">Automation Where You Need It</h2>
            <br />
            SOAR enhances response procedures, orchestration, and automation
            with threat intelligence to strengthen your company’s security
            posture. It connects disparate security tools and technology while
            maintaining the right balance between automated processes and human
            intervention to effectively carry out analysis and response
            activities.
            <br />
     
        </p>     </Fade>
      </div>

   
      <div className="box2">
        <Fade bottom cascade>
          <p>
            <h2>How SOAR works?</h2>
            <br />
            <br />
            SOAR helps companies consolidate their disparate security tools and
            to automate lfh]z , investigation, and incident response. It
            provides single pane control of security across an environment,
            including case and workflow management, investigation, and threat
            detection. Intuitive visualization and hundreds of built-in plugins
            eliminate the need for analysts to be experts in every tool,
            allowing them to focus on the most critical tasks.
            <br />
            <br />
          </p>
          <div className="rowabout">
            <div className="columnabout boxes seven">
              <h2 className="eng">PROCESS AUTOMATION</h2>Implement custom
              incident response workflow automation between your security
              appliances.
            </div>
            <div className="columnabout boxes seven">
              <h2 className="eng">INCIDENT RESPONSE PLAYBOOKS</h2>Upskill your
              analysts and accelerate investigations with pre-built courses of
              action developed by our Mandiant incident responders.
            </div>
            <div className="columnabout boxes seven">
              <h2 className="eng">OPEN PLUGIN FRAMEWORK</h2>
              ntegrate more than 150 third-party tools and data sources for
              seamless, single-pane management of your security stack.
            </div>
          </div>
          <div className="rowabout">
            <div className="columnabout boxes seven">
              <h2 className="eng">CASE MANAGEMENT</h2>Collaborate between
              analyst and incident response teams by storing correlated alerts
              and artifacts in an intuitive case management system. Create
              role-based groups and assign granular permissions for enhanced
              workflow management.
            </div>
            <div className="columnabout boxes seven">
              <h2 className="eng">INTUITIVE USER INTERFACE</h2>
              Enable security teams to easily connect to security tools with a
              simplified abstraction layer to retrieve and push information.
              Control changes at the network, host, and application levels, and
              even physical access control systems with the click of a button.
            </div>
          </div>
          <br />
        </Fade>
      </div>
    </div>
  );
}
