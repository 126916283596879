import React, { useEffect } from "react";
import Fade from "react-reveal/Fade";
import logo1 from "../../imgs/1.png";
import logo2 from "../../imgs/2.png";
import logo3 from "../../imgs/3.png";
import logo4 from "../../imgs/4.png";
import logo5 from "../../imgs/5.png";
import logo6 from "../../imgs/6.png";
import logo7 from "../../imgs/7.jpg";

export default function Audit() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">
            Enjoy leading your organization while we ensure its compliance
          </span>
          <br />
          Complying by book is the way <br />
          to becoming protected
        </p>
      </div>
      <div className="box 1">
        <Fade bottom cascade>
          {" "}
          <p>
            <h1>Cybersecurity Audit</h1>
            <h3>
              Do you think your environment is protected? Do you believe your
              company complies with all security controls?
              <br />
              <br />
              Let us help you answer these questions with a resounding YES. We
              will help you protect your environment via practical auditing,
              following cybersecurity standards or frameworks to ensure fully
              covering all controls.
            </h3>
            Our audit usually begins once the assessment and the execution of
            the solutions are complete, and all gaps are handled. We may
            sometimes go directly to auditing if the exercise is done before.
            <br />
            Do not waste any more time; if you do not know the current situation
            of your company’s cybersecurity, we will let you know. We will help
            you close any gap that may threaten your company by following the
            regularity booklet to ensure covering all aspects.
            <br />
            <br />
          </p>
        </Fade>
      </div>
     
      <div className="box2">
        <Fade bottom cascade>
          <p>
            <h4>
              Auditing will be the last step as it is used to verify 100%
              compliance with the certified standard or framework below:
            </h4>
            <br />
            <br />
            <div className="rowabout">
              <div className="columnabout boxes">
                <h2 className="eng">Select the reference</h2>
                What is the reference (standard or framework) that we will
                depends on
              </div>
              <div className="columnabout boxes">
                <h2 className="eng">Build up the matrix</h2>
                Build up the evaluation criteria which represented as comply
                matrix
              </div>
              <div className="columnabout boxes">
                <h2 className="eng">Planning & Timing</h2>
                Define the required time to implement the Audit
              </div>
            </div>
            <div className="rowabout">
              <div className="columnabout boxes">
                <h2 className="eng">Scanning & Audit</h2>
                Implement the Audit and do the required scanning
              </div>
              <div className="columnabout boxes">
                <h2 className="eng">Analyzing</h2>
                Analyse the results of this Audit
              </div>
              <div className="columnabout boxes">
                <h2 className="eng">Reporting</h2>
                Come up with the detailed findings of the Audit
              </div>
            </div>
          </p>
        </Fade>
      </div>

      <div className="box">
        {" "}
        <Fade bottom cascade>
          <p>
            <br />
            <br />
            <div className="cyberlogos">
              <img src={logo1} alt="" />
              National Cybersecurity Authority – Essential Cybersecurity
              Controls (ECC – 1: 2018).
            </div>
            <div className="cyberlogos">
              <img src={logo2} alt="" />
              The National Institute of Standards and Technology{" "}
            </div>
            <div className="cyberlogos">
              <img src={logo3} alt="" />
              The Escal Institute of Advanced Technologies
              <br />
              <span style={{ marginLeft: "328px" }}>
                SANS or SysAdmin, Audit, Network and Security
              </span>{" "}
            </div>
            <div className="cyberlogos">
              <img src={logo4} alt="" />
              Health Insurance Portability and Accountability Act{" "}
            </div>
            <div className="cyberlogos">
              <img src={logo5} alt="" />
              General Data Protection Regulation{" "}
            </div>
            <div className="cyberlogos">
              <img src={logo6} alt="" />
              Payment Card Industry Data Security Standard{" "}
            </div>
            <div className="cyberlogos">
              <img src={logo7} alt="" />
              Federal Information Security Management Act{" "}
            </div>
          </p>
        </Fade>
      </div>

    </div>
  );
}
