import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/ITSM-–-Service-Desk-01.svg";
export default function ItsmS() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="itmheader">
        <p className="pp">
          <span className="transparent">Service desk</span>
          <br />
          A Service Desk that Really <br />
          Serves Your Beneficiaries
        </p>
      </div>
      <div className="box 1">
           <Fade bottom cascade>    <p>
    
            <h1>Service Desk</h1>
     
   
            <h3>
              Service Desk is the single point of contact (SPOC) and engagement
              between users and the Service Provider for day-to-day activities.
              It undertakes managing incidents and service requests and handles
              communication with the users. Simply, a service desk is a
              communications center where customers can find help from their IT
              service providers.
            </h3>
        
        </p>  </Fade>
      </div>
    
      <div className="box2">
        <Fade bottom cascade>
          <h3>The service desk covers the following ITSM processes:</h3>
          <img src={graph} alt="" />
      
        <p>
         
            More than 150 service desk products are available so selecting the
            wrong ones can cost the company a lot. At TSC, we help you in
            selecting a suitable service desk solution for your company. A long
            list of questions and considerations will be carried out before the
            selection. A part of these questions and considerations include:
            <br />
            <ul>
              <li>What type of support do your customers expect?</li>
              <li>
                Select your “must-have” features and “good to have” features
              </li>
              <li>Review reporting options</li>
              <li>Check on the adoption of processes based on ITIL</li>
              <li>What experience do you want to offer your customers?</li>
              <li>
                Define the suitable deployment option (On-Premise, On-Cloud)
              </li>
              <li>Prioritize reliability and support</li>
              <li>Check integration options and scenarios</li>
              <li>What experience do you want to offer your support team?</li>
              <li>Consider scalability and automation</li>
              <li>Identify key processes and their dependencies</li>
              <li>Define the required budget</li>
            </ul>
            Moreover, the experience we have at TSC in delivering end-to-end
            service is one of our strengths, priding ourselves on our very solid
            knowledge. TSC is proud of having localized some leading service
            desk products and of adjusting interfaces to be in Arabic and with
            right-to-left alignment. This requires extensive experience in
            service desks and a good relationship with our clients.
          
        </p></Fade>
      </div>
    </div>
  );
}
