  import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/NDR-01-2.svg";
import graph2 from "../../imgs/NDR-02.svg";

export default function EE() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">Endpoint encryption</span>
          <br />
          Protect your final layer
          <br />
          of security. Encrypt your endpoint
        </p>
      </div>
      <div className="box 1">
       <Fade bottom cascade> <p>
         
            <h1>Endpoint Encryption</h1>
            <h3>
              Endpoint encryption essentially protects the operating system from
              installation of “Evil Maid” attacks that can install a keylogger,
              corrupt boot files, or lock files stored on laptops, servers,
              tablets, and other endpoints to prevent unauthorized users from
              accessing the data.
            </h3>
  
          
            Encryption is an essential component of a layered data security
            strategy. Companies typically incorporate multiple layers of
            protection, including firewalls, intrusion prevention, anti-malware,
            and data loss prevention, and encryption acts as the last layer to
            protect data in case it falls into the wrong hands
            
            <br />
            <br />
         
          <br />
          <br />
        </p> </Fade>
        <img src={graph} alt="" />
      </div>



      <div className="box2">
        <Fade bottom cascade>
          <p>
          A comprehensive endpoint encryption solution can enable IT departments to centrally manage all encrypted endpoints, including encryption that different vendors provide. The solution should monitor and audit encryption endpoints, as well as manage encryption policies and keys from a single console. Moreover, it should cover native encryption on devices, such as the encryption on Apple FileVault on OS X and Microsoft BitLocker on Windows.
          <br />
            <br />
            Therefore, when selecting an endpoint encryption solution, it should cover a variety of management capabilities, such as:

          </p>
          <img src={graph2} alt="" />
   
        </Fade>
      </div>
    </div>
  );
}
