 import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/EDR-01.svg";

export default function EDR() {
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">Endpoint Detection and Response</span>
          <br />
          Detect All Endpoint Threats,
          <br />
          Immediately
        </p>
      </div>
      <div className="box 1">
         <Fade bottom><p>
         
            <h1>Endpoint Detection and Response (EDR)</h1>
            <h3>
              Endpoint Detection and Response (EDR) monitors activity across all
              your endpoints, on and off the network, providing deep visibility
              into their security state. It also prioritizes alerts when there
              is an issue. EDR drastically reduces dwell time by rapidly
              detecting new and non-malware attacks, and it cuts the cost, time,
              and scope of incident response.
            </h3>
            <br />
            <br />{" "}
          
        </p></Fade>{" "}
        <img src={graph} alt="" />
      <Fade bottom cascade>   <p>
          <br />
          <br />
         
            EDR is also one of the SIEM family of solutions, so selecting EDR,
            NDR, and SIEM should be studied well for smooth and harmonious
            functioning.
            <br />
            <br />
            <br />
            <br />
            <h3>What Is the Difference Between EDR and NDR?</h3>
           
            
            Endpoint Detection and Response (EDR) provides a detailed
            ground-level view of the processes running on a host and
            interactions between them. Network Detection and Response (NDR)
            provides an aerial view of the interactions between all devices on
            the network.
         
          <br />
          <br />
        </p> </Fade>
      </div>


    </div>
  );
}
