import React, { useEffect } from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/ITSM-–-IT-Asset-Management-01.svg";
import graph2 from "../../imgs/ITSM-–-IT-Asset-Management-02-1536x1481.png";
export default function ItsmA() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="itmheader">
        <p className="pp">
          <span className="transparent">IT Asset Management</span>
          <br />
          Manage your IT Assets <br />
          Efficiently and Effectively
        </p>
      </div>
      <div className="box 1">
        <Fade bottom cascade>
          {" "}
          <p>
            <h1>IT Asset Management</h1>

            <h3>
              Running a tight ship on assets can become a nightmare for all IT
              managers as it can be complex and costly. This is where ITIL comes
              in to introduce the best practices in managing and controlling IT
              processes, including assets and inventory. Knowing your assets
              helps you know how to manage, maintain and control them. This is
              why TSC recommends IT Asset Management (ITAM) to know your
              environment and ensure stability.
            </h3>
          </p>{" "}
        </Fade>
      </div>

      <div className="box2">
        <Fade bottom cascade>
          <img src={graph} alt="" />
        </Fade>
        <Fade bottom cascade>
          {" "}
          <p>
            IT Asset Management is a process to ensure all assets are accounted
            for, deployed, maintained, upgraded, and even disposed of when the
            time comes. It is important because it will support other processes
            in ITSM in addition to having a centralized and accurate place for
            all asset information.
            <br />
            <br />
            ITAM offers a wide range of features: <br />
          </p>
          <img src={graph2} alt="" />
        </Fade>
      </div>
    </div>
  );
}
