  import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/ITOM-–-Network-Management-01.svg";

export default function ItomN() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="itmheader">
        <p className="pp">
          <span className="transparent">Network Management</span>
          <br />
          The ‘Power’ to Monitor and <br />
          Manage Your Network
        </p>
      </div>
      <div className="box 1">
        <Fade bottom cascade> <p>
        
            <h1>Networking Management</h1>
         
         
            <h3>
              Networks are wide and their components are numerous. To achieve
              full visibility and control, you need to have network management
              solutions as nothing could give you what network management can,
              even with a battalion of staff.
            </h3>

            Through network management solutions, everything will easily be
            under control. All the required processes will be streamlined,
            efficient, and rapid.
            <br />
            <ul>
              <li>
                Focus on network monitoring and manage each element from
                performance metrics to threshold management and alerting.
              </li>
              <li>
                Get end-to-end visibility into the health and performance of
                on-premises, hosted, and cloud infrastructure.
              </li>
              <li>
                See maps of network connections, dependency relationships, and
                topology information.
              </li>
              <li>
                Gain a better understanding of whether complex network devices
                are performing as expected.
              </li>
              <li>
                Have full visibility into your network, which requires a
                watchful eye over your network configuration to tracks any
                change, evaluate these changes, restore old configuration, etc.
              </li>
              <li>
                Be prepared to recover quickly from hardware faults and human
                errors with automatic backups.
              </li>
              <li>
                Help ensure devices are configured and operating in compliance
                with regulatory standards, such as PCI, SOX, HIPAA, and more
              </li>
              <li>
                Manage IPs in an efficient and automated manner because manual
                tracking and working on sheets will lead to mistakes.
              </li>
              <li>
                Measure the quality of your WAN connection, which is a crucial
                step.
              </li>
              <li>
                Finally, establish a log center to track events and logs.
                Monitoring and correlating logs and events for potential threats
                can help significantly with reducing the time to detect
                incidents.
              </li>
            </ul>
         
        </p> </Fade>
      </div>
 
      <div className="box2">
        <Fade bottom cascade>
          <img src={graph} alt="" />
        </Fade>
        <p>
          <Fade bottom cascade>
            <h2 className="eng">Network Automation</h2>
            Integrate network automation for large and complex environments.
            <br />
            <ul>
              <li>Network performance monitoring</li>
              <li>Traffic and bandwidth analysis</li>
              <li>Configuration and change management</li>
              <li>Switch port and end-user monitoring and tracking</li>
              <li>WAN performance monitoring</li>
              <li>IP address management</li>
            </ul>
            <h2 className="eng">Network Performance Monitoring</h2>
            Network monitoring that scales and expands with the needs of your
            network.
            <br />
            <ul>
              <li>Network Insights for deeper visibility</li>
              <li>Intelligent maps</li>
              <li>NetPath and PerfStack for easy troubleshooting</li>
              <li>Smarter scalability for large environments</li>
              <li>Advanced alerting</li>
            </ul>
          </Fade>
          <Fade bottom cascade>
            <h2 className="eng">Network Bandwidth Analysis</h2>
            Comprehensive network bandwidth analysis and performance monitoring.
            <br />
            <ul>
              <li>Detect, diagnose, and resolve network performance issues</li>
              <li>
                Track response time, availability, and uptime of routers,
                switches, and other SNMP-enabled devices
              </li>
              <li>
                Monitor and analyze network bandwidth performance and traffic
                patterns
              </li>
              <li>
                Find bandwidth hogs on a network and see which applications are
                using the most bandwidth
              </li>
              <li>
                View visual hop-by-hop analysis for devices along with the
                delivery path using NetPath
              </li>
              <li>Enterprise Command Center</li>
            </ul>
            <h2 className="eng">Network Configuration Management</h2>
            Reduce cost, save work hours, and remain compliant with automated
            network configuration management and backup.
            <br />
            <ul>
              <li>Network compliance</li>
              <li>Network automation</li>
              <li>Configuration backup</li>
              <li>Vulnerability Assessment</li>
              <li>Network Insight for Cisco and Palo Alto</li>
              <li>Integration with Network Performance Monitor</li>
            </ul>
          </Fade>
          <Fade bottom cascade>
            <h2 className="eng">IP Address Management</h2>
            Save time and avert costly errors.
            <br />
            <ul>
              <li>Automated IP address tracking</li>
              <li>Integrated DHCP, DNS, and IP address management</li>
              <li>IP address alerting, troubleshooting, and reporting</li>
              <li>Multi-vendor on-premises and cloud DHCP and DNS support</li>
              <li>
                IP Request Form for streamlining and automating IP address
                requests
              </li>
              <li>
                API support with CRUD operations for two-way integration with
                third-party software
              </li>
            </ul>
            <h2 className="eng">User and Device tracker</h2>
            Network device tracking software are designed to locate users and
            devices on your network.
            <br />
            <ul>
              <li>Locate users and devices on your network</li>
              <li>Identify users with Active Directory integration</li>
              <li>Whitelist hosts to detect rogue devices</li>
              <li>Track sensitive or suspicious devices with a watch list</li>
              <li>Correlate network topology to endpoints and users</li>
              <li>Remotely turn switch ports on and off</li>
            </ul>
            <h2 className="eng">VOIP Monitoring</h2>
            Figure out network issues in no time.
            <br />
            <ul>
              <li>Automated network discovery</li>
              <li>Real-time monitoring and alerting</li>
              <li>Powerful diagnostic capabilities</li>
              <li>Enhanced network security</li>
              <li>Integration with ITOM solution</li>
            </ul>
          </Fade>
        </p>
      </div>
    </div>
  );
}
