  import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Email-security-01.svg";

export default function Email() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">
            Secured Email Gateway Services and more
          </span>
          <br />
          Fend Off Malicious Emails, <br />
          The Number One Source of Attacks
        </p>
      </div>
      <div className="box 1">
            <Fade bottom cascade>  <p>
        
            <h1>Secure Email Gateway (SEG)</h1>
    
    
            <h3>
              A Secure Email Gateway (SEG) is a device or software used for
              monitoring emails being sent and received. A SEG is designed to
              filter away unwanted emails and delivers trusted ones. Unwanted
              messages include spam, phishing attacks, malware, or fraudulent
              content. Outgoing messages can also be analyzed to prevent
              sensitive data from leaving the company or to automatically
              encrypt emails that contain sensitive information. SEG
              functionality can be deployed as a cloud service, or as an
              on-premises appliance, depending on requirements.
            </h3>
            Email Gateways will also offer protection from social engineering
            attacks, such as phishing or business email compromise. The gateway
            can check the domain of incoming emails, as well as scan for
            suspicious content within the email, to stop harmful content from
            coming into the network. Emails that have been marked as malicious
            are quarantined or rejected. Some systems allow the quarantined
            emails to be accessed by admins if necessary.
            <br />
            Alongside blocking incoming threats, Email Gateways will also scan
            outgoing content. This can help protect businesses from data loss.
            Many Email Gateways will provide Email Archiving functionality,
            which stores emails for legal compliance and data management. They
            will also provide business with Continuity features, which means
            that even if their email network goes down, they will still be able
            to access their emails. Email Gateways will also provide businesses
            with a management dashboard and a range of reports and analytics.
            These give businesses visibility into all email traffic in a
            network. This is important for businesses to gain insights into
            their network security.
            <br />
            These simple, honest mistakes can be very costly for companies
            nowadays, and from this rises the importance of boosting employee
            awareness regarding Cybersecurity.
            <br />
            The list of expected regular employee mistakes that could affect the
            company’s cyberspace is too long to list, but if employees are
            educated and made aware about taking proper measures that do not put
            the company at risk, then 90% of such mistakes will never occur. The
            following are some examples of employee mistakes:
            <br />
        
        </p>  </Fade>
      </div>


      <div className="box2">
        <Fade bottom cascade>
          <h3>Key features of SEG:</h3>
          <img src={graph} alt="" />
        </Fade>
        <Fade bottom cascade>
          <p>
            <br />
            <br />
            At TSC, we deliver the SEG service for all types of email servers,
            to most email solutions, and under any category of implementation,
            whether cloud-based and on-premises setups and more through an
            expert team who have delivered numerous SEG services successfully.
          </p>
        </Fade>
      </div>


      <div className="box">
         <Fade bottom cascade>  <p>
       
            <br />
            <br />
            When we deliver the SEG services, we ensure selecting the best SEG
            solution to fit your company based on many considerations. This will
            increase SEG optimization and boost return of investment on SEG as
            well. Benefits of SEG include:
            <br />
            <br />
            <div className="rowabout">
              <div className="columnabout boxes four">
                <h2 className="eng">DEPLOYMENT OPTIONS</h2>
                Whether an organization wants to deploy email security as a
                cloud service or on-premises can be a major factor in choosing
                the right solution.
              </div>
              <div className="columnabout boxes four">
                <h2 className="eng">SPAM EFFECTIVENESS</h2>
                As one of the main features of a secure email gateway,
                organizations should review email archiving solution comparisons
                of vendors using real-world email traffic.
              </div>
              <div className="columnabout boxes four">
                <h2 className="eng">MALWARE EFFECTIVENESS</h2>
                Another core ability of an email security solution is the
                ability to quickly and accurately detect and block malware using
                active analysis or URLs and attachments.
              </div>
            </div>
            <div className="rowabout">
              <div className="columnabout boxes four">
                <h2 className="eng">THREAT INTELLIGENCE</h2>
                Tied to the ability to detect and block malware, threat
                intelligence can help administrators understand how they are
                being attacked, what tactics are being used, and even which
                group is targeting them.
              </div>
              <div className="columnabout boxes four">
                <h2 className="eng">OUTBOUND CONTENT <br/>CONTROL</h2>
                Analyzing email leaving an organization to prevent sensitive
                data from leaving or automatically using encryption to enforce
                security policies.
              </div>
              <div className="columnabout boxes four">
                <h2 className="eng">RESPONSE CAPABILITIES</h2>
                If an email makes it to a user inbox and is subsequently found
                to be malicious, the ability to automatically locate and remove
                those emails will prevent subsequent attacks.
              </div>
            </div>
            <br />
            <br />
         
        </p> </Fade>
      </div>

    </div>
  );
}
