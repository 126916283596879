import React, { useEffect } from "react";
import Fade from "react-reveal/Fade";

export default function Proof() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">
            Well-rounded cybersecurity testing
          </span>
          <br />
          Verify, Ensure, and Double-Check Your Security
        </p>
      </div>
      <div className="box 1">
        <Fade bottom cascade>
          {" "}
          <p>
            <h1>Proof</h1>
          
            Once you install all cybersecurity measures and comply with all
            controllers, it is the time to ensure that everything is functioning
            as it should and check if your company is fully secured or not. This
            is simply what we do here. The “Proof” service consists of several
            checkpoints and tests that cover all aspects of cybersecurity.
            <br />
            <br />
            <ul>

              <li>Configuration Review</li>
              <li>Application security testing</li>
              <li>Vulnerability assessment</li>
              <li>Penetration Testing</li>

            </ul>
          </p>{" "}
        </Fade>
      </div>
    </div>
  );
}
