 import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Computing-–-Clients-01.svg";

export default function ComC() {
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="comheader">
        <p className="pp">
          <span className="transparent">Clients</span>
          <br />
            Accessing Your Client Data
          <br />
          will Become a Breeze
        </p>
      </div>
      <div className="box 1"> 
       <Fade bottom cascade>
        <p>
      
            <h1>Clients</h1>
        
        
            <h3>
              Clients are not just customers here. A client is a piece of
              hardware and software that accesses a service made available by a
              server. The client, in other words, is the endpoint that users use
              to access network resources.
            </h3>
            Business nature can differ and every business has its special
            requirements, which in turn depends on the requirements of human
            resources, just as having designers is so much different from having
            accountants. Today the market offers a variety of options simplified
            in the following:
          
        </p></Fade>  
         <Fade bottom cascade>
          <img src={graph} alt="" />
        </Fade>
       
      </div>
 
     
    
    </div>
  );
}
