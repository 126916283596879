import React from "react";
import location from "../imgs/HEADERS-13.png";
import email from "../imgs/HEADERS-11.png";
import phone from "../imgs/HEADERS-12.png";

import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
export class Contact extends React.Component {
  render() {
    const containerStyle = {
      position: "relative",
      width: "70%",
      height: "50%",
      paddingBottom: "10vh",
    };
    return (
      <div>
        <div className="contacheader">
        <p className="pp">
            <span className="transparent">
             
Contact TSC
            </span>
            <br />
            We Are Always Glad to Hear from You
          </p>
        </div>
        <div className="box">
          <p>
            <h3 style={{ paddingTop: "3rem" }}>
              Contact us at any time using the following methods:
            </h3>
            <div className="row cont" style={{ gap: "20px" }}>
              <div className="columnabout boxes c">
                <img src={location} alt="" />
                <br/>
                <span className="contacttitle">office location</span>
                <br/><br/>
                P.O.Box 3995, Riyadh 12445, Al Wahah Square, Unit No. 205, 8968
                Othman Bin Affan Rd Kingdom of Saudi Arabia<br/><br/>
        <a className="contactlink"
                
                  href="https://goo.gl/maps/fvYxgyRiDmh6o4AU8"
                  target="_blank"
                  rel="noopener noreferrer"
                >View on Google Maps &gt;</a> 
              </div>
              <div className="columnabout boxes c">
                <img src={email} alt="" />
                <br/>
                <span className="contacttitle">Email</span>
                <br/><br/>
                <a href="mailto: info@techs.com.sa" className="contactlink">   info@techs.com.sa</a>
              </div>
              <div className="columnabout boxes c" >
                <img src={phone} alt="" />
                <br/>
                <span className="contacttitle">Phone and Fax</span>
                <br/><br/>  Phone:    <a className="contactlink" href="tel:+966112226999" target="_blank" rel="noopener noreferrer"
                >+966112223999</a><br/> Fax: +966112221777
              </div>
            </div>
          </p>
        </div>
        <div className="map">
          <Map
            google={this.props.google}
            style={containerStyle}
            zoom={15}
            initialCenter={{
              lat: 24.747292389497105,
              lng: 46.72311120628862,
            }}
          >
            <Marker 
              position={{ lat: 24.747292389497105, lng: 46.72311120628862 }}
            />
          </Map>
        </div>
      </div>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyA7CPuBp16jSp4uUrU2yNR4yNka_hBxUnc",
})(Contact);
