import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import logoc1 from "../imgs/saudi.svg";
import logoc2 from "../imgs/10.svg";
import logoc3 from "../imgs/nahl.svg";
import logoc4 from "../imgs/moh.svg";
import logoc5 from "../imgs/iso.svg";
import iconN from "../imgs/Untitled-4-04.png";
import iconC from "../imgs/Untitled-4-03.png";
import iconCP from "../imgs/Untitled-4-06.png";
import iconM from "../imgs/Untitled-4-05.png";
import iconCS from "../imgs/Untitled-4-07.png";
import Fade from "react-reveal/Fade";
import Card from "./Card";
import images from "./imgclient";
import Carousel from "react-elastic-carousel";
import Item from "./Item";
import { Link } from "react-router-dom";

export default function Home() {
  const breakPoints = [
    { width: 1, itemsToShow: 2 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [counterOn, setCounterOn] = useState(false);
  if (window.innerWidth > 768) {
    return (
      <div>
        <div className="homeheader">
          {/* <p className="pp">
          <h1 className="titleWord">Tech Stations Company</h1>
          <span className="transparent"> Your Trusted IT Partner</span> <br />
          With over 400 clients putting their trust in TSC
        </p> */}
          <div className="row n" style={{ paddingTop: "42rem" }}>
            <div className="column n">
              <img className="certified" src={logoc1} alt="​" />
              <p className="certifiedDesc">
                Saudi Company <br />
                Based In Riyadh
              </p>
            </div>
            <div className="column n">
              <img className="certified" src={logoc2} alt="​" />
              <p className="certifiedDesc">10+ Years Of Experience</p>
            </div>{" "}
            <div className="column n">
              <img className="certified" src={logoc3} alt="" />
              <p className="certifiedDesc">
                Part Of <br /> NAHL Holding Family
              </p>
            </div>{" "}
            <div className="column n">
              <img className="certified" src={logoc4} alt="" />
              <p className="certifiedDesc">Classified By MOMRA​</p>
            </div>{" "}
            <div className="column n">
              <img className="certified" src={logoc5} alt="" />
              <p className="certifiedDesc">Certified By ISO​</p>
            </div>
          </div>
        </div>
        <div className="count">
          <h1 className="tscnumbers">TSC IN NUMBERS</h1>

          <ScrollTrigger
            onEnter={() => setCounterOn(true)}
            onExit={() => setCounterOn(true)}
          >
            <div className="numbersrow">
              <div className="numbers">
                {counterOn && (
                  <CountUp start={0} end={400} duration={1.5} delay={0} />
                )}
                +<p className="numbersp">satisfied clients </p>
              </div>
              <div className="numbers">
                {counterOn && (
                  <CountUp start={0} end={500} duration={1.5} delay={0} />
                )}
                +<p className="numbersp">projects </p>
              </div>
              <div className="numbers">
                {counterOn && (
                  <CountUp start={0} end={350} duration={1.5} delay={0} />
                )}
                +<p className="numbersp">meter of fiber optic </p>
              </div>
              <div className="numbers">
                {counterOn && (
                  <CountUp start={0} end={110} duration={1.5} delay={0} />
                )}
                k<p className="numbersp">network nodes </p>
              </div>
              <div className="numbers">
                {counterOn && (
                  <CountUp start={0} end={1500} duration={1.5} delay={0} />
                )}
                <p className="numbersp">switchs </p>
              </div>
              <div className="numbers">
                {counterOn && (
                  <CountUp start={0} end={4500} duration={1.5} delay={0} />
                )}
                <p className="numbersp">IP telephony </p>
              </div>
            </div>

            <div className="numbersrow">
              <div className="numbers">
                {counterOn && (
                  <CountUp start={0} end={10} duration={1.5} delay={0} />
                )}
                +<p className="numbersp">SOC </p>
              </div>
              <div className="numbers">
                {counterOn && (
                  <CountUp start={0} end={120} duration={1.5} delay={0} />
                )}
                +<p className="numbersp">firewalls </p>
              </div>
              <div className="numbers">
                {counterOn && (
                  <CountUp start={0} end={850} duration={1.5} delay={0} />
                )}
                <br />
                <p className="numbersp">wifi ap </p>
              </div>
              <div className="numbers">
                {counterOn && (
                  <CountUp start={0} end={45} duration={1.5} delay={0} />
                )}
                +<p className="numbersp">noc </p>
              </div>
              <div className="numbers">
                {counterOn && (
                  <CountUp start={0} end={100} duration={1.5} delay={0} />
                )}
                <p className="numbersp">conference rooms </p>
              </div>
              <div className="numbers">
                {counterOn && (
                  <CountUp start={0} end={85} duration={1.5} delay={0} />
                )}
                %<p className="numbersp"> KSA ITM bussiness</p>
              </div>
            </div>
          </ScrollTrigger>

          <h1 className="servicestitle">TSC SERVICES </h1>
          <ScrollTrigger
            onEnter={() => setCounterOn(true)}
            onExit={() => setCounterOn(true)}
          >
            <div className="row">
              <div className="column network">
                <Fade left>
                  <Card
                    title="Networking"
                    imgurl={iconN}
                    body="arrive at 100% network reialability,
            covering passive and active network parts"
                    link={<Link to="/networking">Discover &gt;</Link>}
                  />
                </Fade>
              </div>
              <div className="column cyber">
                <Fade top>
                  <Card
                    title="Cybersecurity"
                    imgurl={iconCS}
                    body="    Securing the cyber space is our mission,
              offering 360 Cybersecurity services"
                    link={<Link to="/cybersecurity">Discover &gt;</Link>}
                  />
                </Fade>
              </div>
              <div className="column it">
                <Fade right>
                  <Card
                    title="IT Management"
                    imgurl={iconM}
                    body="integrated IT management
              suitable for all organization sizes"
                    link={<Link to="/it-management">Discover &gt;</Link>}
                  />
                </Fade>
              </div>
            </div>

            <div className="row">
              <div className="column comp">
                <Fade right>
                  <Card
                    title="Computing & UCC"
                    imgurl={iconCP}
                    body="The core part of IT in hand,
            computing and unified communication"
                    link={<Link to="/computing-and-ucc">Discover &gt;</Link>}
                  />
                </Fade>
              </div>
              <div className="column con">
                <Fade left>
                  <Card
                    title="Consultancy"
                    imgurl={iconC}
                    body="TSC's experts will analyze your goals and offer solutions for all your need. "
                    link={<Link to="/consultancy">Discover &gt;</Link>}
                  />
                </Fade>
              </div>
            </div>
          </ScrollTrigger>
        </div>
        <div className="box4">   <Fade bottom>
          <h1 className="servicestitle">TSC CLIENTS</h1>
          <div className="carousel-wrapper">
            <Carousel className="item" breakPoints={breakPoints}>
              {images.map((image) => {
                return <img src={image} alt="" />;
              })}
            </Carousel>
            <Item />
          </div></Fade>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="homeheader">
          {/* <p className="pp">
          <h1 className="titleWord">Tech Stations Company</h1>
          <span className="transparent"> Your Trusted IT Partner</span> <br />
          With over 400 clients putting their trust in TSC
        </p> */}
          <div className="row n" style={{ paddingTop: "42rem" }}>
            <div className="column n">
              <img className="certified" src={logoc1} alt="​" />
              <p className="certifiedDesc">
                Saudi Company <br />
                Based In Riyadh
              </p>
            </div>
            <div className="column n">
              <img className="certified" src={logoc2} alt="​" />
              <p className="certifiedDesc">10+ Years Of Experience</p>
            </div>{" "}
            <div className="column n">
              <img className="certified" src={logoc3} alt="" />
              <p className="certifiedDesc">
                Part Of <br /> NAHL Holding Family
              </p>
            </div>{" "}
            <div className="column n">
              <img className="certified" src={logoc4} alt="" />
              <p className="certifiedDesc">Classified By MOMRA​</p>
            </div>{" "}
            <div className="column n">
              <img className="certified" src={logoc5} alt="" />
              <p className="certifiedDesc">Certified By ISO​</p>
            </div>
          </div>
        </div>
        <div className="count22">
          <h1 className="tscnumbers">TSC IN NUMBERS</h1>

          <ScrollTrigger
            onEnter={() => setCounterOn(true)}
            onExit={() => setCounterOn(true)}
          >
            <div className="numbersrow22">
              <div className="column22">
                <div className="numbers">
                  {counterOn && (
                    <CountUp start={0} end={400} duration={1.5} delay={0} />
                  )}
                  +<p className="numbersp">satisfied clients </p>
                </div>
                <div className="numbers">
                  {counterOn && (
                    <CountUp start={0} end={500} duration={1.5} delay={0} />
                  )}
                  +<p className="numbersp">projects </p>
                </div>
                <div className="numbers">
                  {counterOn && (
                    <CountUp start={0} end={350} duration={1.5} delay={0} />
                  )}
                  +<p className="numbersp">meter of fiber optic </p>
                </div>
                <div className="numbers">
                  {counterOn && (
                    <CountUp start={0} end={1500} duration={1.5} delay={0} />
                  )}
                  <p className="numbersp">switchs </p>
                </div>
                <div className="numbers">
                  {counterOn && (
                    <CountUp start={0} end={4500} duration={1.5} delay={0} />
                  )}
                  <p className="numbersp">IP telephony </p>
                </div>
                <div className="numbers">
                  {counterOn && (
                    <CountUp start={0} end={110} duration={1.5} delay={0} />
                  )}
                  k<p className="numbersp">network nodes </p>
                </div>
              </div>

              <div className="column22">
                <div className="numbers">
                  {counterOn && (
                    <CountUp start={0} end={85} duration={1.5} delay={0} />
                  )}
                  %<p className="numbersp"> KSA ITM bussiness</p>
                </div>
                <div className="numbers">
                  {counterOn && (
                    <CountUp start={0} end={120} duration={1.5} delay={0} />
                  )}
                  +<p className="numbersp">firewalls </p>
                </div>
                <div className="numbers">
                  {counterOn && (
                    <CountUp start={0} end={100} duration={1.5} delay={0} />
                  )}
                  <p className="numbersp">conference rooms </p>
                </div>
                <div className="numbers">
                  {counterOn && (
                    <CountUp start={0} end={45} duration={1.5} delay={0} />
                  )}
                  +<p className="numbersp">noc </p>
                </div>
                <div className="numbers">
                  {counterOn && (
                    <CountUp start={0} end={850} duration={1.5} delay={0} />
                  )}
                  <br />
                  <p className="numbersp">WIFI AP </p>
                </div>

                <div className="numbers">
                  {counterOn && (
                    <CountUp start={0} end={10} duration={1.5} delay={0} />
                  )}
                  +<p className="numbersp">SOC </p>
                </div>
              </div>
            </div>
          </ScrollTrigger>

          <h1 className="servicestitle">TSC SERVICES </h1>
          <ScrollTrigger
            onEnter={() => setCounterOn(true)}
            onExit={() => setCounterOn(true)}
          >
            <div className="row">
              <div className="column network">
                <Fade left>
                  <Card
                    title="Networking"
                    imgurl={iconN}
                    body="arrive at 100% network reialability,
            covering passive and active network parts"
                    link={<Link to="/networking">Discover &gt;</Link>}
                  />
                </Fade>
              </div>
              <div className="column cyber">
                <Fade top>
                  <Card
                    title="Cybersecurity"
                    imgurl={iconCS}
                    body="    Securing the cyber space is our mission,
              offering 360 Cybersecurity services"
                    link={<Link to="/cybersecurity">Discover &gt;</Link>}
                  />
                </Fade>
              </div>
              <div className="column it">
                <Fade right>
                  <Card
                    title="IT Management"
                    imgurl={iconM}
                    body="integrated IT management
              suitable for all organization sizes"
                    link={<Link to="/it-management">Discover &gt;</Link>}
                  />
                </Fade>
              </div>
            </div>

            <div className="row">
              <div className="column comp">
                <Fade right>
                  <Card
                    title="Computing & UCC"
                    imgurl={iconCP}
                    body="The core part of IT in hand,
            computing and unified communication"
                    link={<Link to="/computing-and-ucc">Discover &gt;</Link>}
                  />
                </Fade>
              </div>
              <div className="column con">
                <Fade left>
                  <Card
                    title="Consultancy"
                    imgurl={iconC}
                    body="TSC's experts will analyze your goals and offer solutions for all your need. "
                    link={<Link to="/consultancy">Discover &gt;</Link>}
                  />
                </Fade>
              </div>
            </div>
          </ScrollTrigger>
        </div>
        <div className="box4">
        <Fade bottom>
          <h1 className="servicestitle">TSC CLIENTS</h1>
          <div className="carousel-wrapper">
            <Carousel className="item" breakPoints={breakPoints}>
              {images.map((image) => {
                return <img src={image} alt="" />;
              })}
            </Carousel>
            <Item />
          </div></Fade>
        </div>
      </div>
    );
  }
}
