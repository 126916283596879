import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Cybersecurity-Assessment-and-Audit-01.svg";

export default function AuditAndAss() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">
            Evaluate your environment status,
            <br />
            learn what you need, and take action!
          </span>
          <br />
          Cybersecurity Assessment and Audit
        </p>
      </div>
      <div className="box 1">
          <Fade bottom cascade> <p>

            <h1>Secure Email Gateway (SEG)</h1>
        
       
            <div className="rowabout">
              <div className="columnabout boxes eight"></div>
              <div className="columnabout boxes eight two">
                <h2 className="eng">Cybersecurity Assessment</h2>
              </div>
              <div className="columnabout boxes eight two">
                <h2 className="eng">Cybersecurity Audit</h2>
              </div>
            </div>
            <div className="rowabout">
              <div className="columnabout boxes six">
                <h2 className="eng t">What?</h2>
              </div>
              <div className="columnabout boxes eight">
                It is the delivery of a more in-depth evaluation of performance
                against controls. Assessments include some sort of impact
                measure or an interpretation of the effectiveness of the area
                under assessment.
              </div>
              <div className="columnabout boxes eight">
                It is the evaluation of performance against specifications,
                standards, controls, or guidelines. This is a checklist exercise
                with evaluation against a list of controls called the controls
                library.
              </div>
            </div>

            <div className="rowabout">
              <div className="columnabout boxes six">
                <h2 className="eng t">Why?</h2>
              </div>
              <div className="columnabout boxes eight">
                It forms a complete picture of the status of your cybersecurity
                and identifies security gaps to achieve the highest compliance
                rating.
              </div>
              <div className="columnabout boxes eight">
                It tests and verifies if all controllers comply with specific
                standards or frameworks.
              </div>
            </div>
            <div className="rowabout">
              <div className="columnabout boxes six">
                <h2 className="eng t">What?</h2>
              </div>
              <div className="columnabout boxes eight">
                It is preferable to be at the beginning of reviewing the
                environment to offer security gap analysis. This time also helps
                develop an accurate plan that covers all requirements.
              </div>
              <div className="columnabout boxes eight">
                It is preferable to be after filling all security gaps defined
                via the assessment exercise and after the execution of all the
                necessary actions to ensure they comply with standards or
                frameworks.
              </div>
            </div>
            <br />
       
          <br />
          <br />
          The TSC Cybersecurity Assessment and Audit is a continuous cycle, beginning at the assessment and ending at the audit. The cycle is then repeated on an ongoing basis to ensure the highest level of security. The main goal of repeating these operations is to cope with the dynamic changes any company undergoes in the environment, infrastructure, business needs, expansions, etc. All such changes can and will open the door for new challenges for the Cybersecurity team to secure their company.

        </p>   </Fade>
        <img src={graph} alt="" />
      </div>


      
    </div>
  );
}
