  import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Firewall-01.svg"

export default function Firewall() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">
          
All types of NGFW with<br/>the highest standards
          </span>
          <br />
          Ramp up your security with <br />
          TSC firewall services
        </p>
      </div>
      <div className="box 1">
           <Fade bottom cascade>  <p>
         
            <h1>Firewall</h1>
            <h3>
            A firewall is a network security system that monitors and controls the incoming and outgoing network traffic based on predetermined security rules. The major role of the firewall is filtering traffic. Types of firewalls are differentiated based on which layer the firewall covers.

            </h3>
     
     
          TSC provides full service for all types of firewalls, by selecting, sizing, installing, configuring, tuning, managing, and reviewing firewalls, and finally documenting all deliverables.
        

            <br />
            <br />
          
        </p>
        <img src={graph} alt="" />    </Fade>
      </div>


    </div>
  );
}
