 import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../imgs/Consultancy-01-1.svg";

export default function Ucc() {
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="consultheader">
        <p className="pp" >
          <span className="transparent">Consultancy</span>
          <br />
          Draw Your Road Map <br />
          Take the Journey
        </p>
      </div>
      <div className="box 1">
         <Fade bottom cascade>  <p>
        
            <h1>Consultancy</h1>
      
       
            <h3>
              Are you too busy in day-to-day operations that cannot focus on
              development? Do you desire to solve an issue or make a decision on
              which direction you should go but cannot find the time to look
              into it? Consultancy services can help when you do not have the
              time, but they are also crucial for most strategic decisions
              because they need an expert opinion.
            </h3>
            TSC is glad to do all that on your behalf after thoroughly studying
            your goals and digging deep via on-site surveys. All collected
            information will be studied and analyzed by our experts to offer you
            solutions for all your need. Once the solutions are defined and
            selected, budgeting for these solutions will commence, ensuring that
            we procure all the requirements at the best price. But before we
            start placing orders, we take into consideration site readiness and
            offer terms or resources as a list of priorities detailing the
            timeline for everything. Documenting all the processes is the last
            step in this service.
            <br />
            <br />
            <ol>
              <li>
                <h4>Survey</h4>
                Perform a deep survey of all related components
              </li>
              <li>
                <h4>Analyze and Study</h4>
                Analyze all survey results and study all the requirements
                <br />
              </li>
              <li>
                <h4>Define the Solutions</h4>
                Define the required solutions in detail and based on the study
                results
                <br />
              </li>
              <li>
                <h4>Budget</h4>
                Define the required budget for the list of requirements
                <br />
              </li>
              <li>
                <h4>Prioritization</h4>
                Prioritize these requirements per need and schedule them in a
                timeline
                <br />
              </li>
              <li>
                <h4>Documentation</h4>
                Documenting all the details of the road map strategy
              </li>
            </ol>
        
        </p>  </Fade>
      </div>
   
      <div className="box2">
        <Fade bottom cascade>
          <p>
            TSC does not provide consultancy services for everything. We keep
            our consultancy services focused to offer better quality:
            <br />
          </p>
          <img src={graph} alt="" />
     
          <p>
            Our experts are ready to assist at any time. Do not hesitate to
            contact us.
            <br />
            <br />
          </p>
        </Fade>
      </div>
    </div>
  );
}
