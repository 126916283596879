  import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Intrusion-Prevention-System-IPS-01.svg";
export default function IPS() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="itmheader">
        <p className="pp">
          <span className="transparent">Intrusion Prevention System</span>
          <br />
          Stop intrusions in their tracks
        </p>
      </div>
      <div className="box 1">
          <Fade bottom cascade> <p>
         
            <h1>Intrusion Prevention System (IPS)</h1>
      
       
            <h3>
              An Intrusion Prevention System (IPS) is a network security/threat
              prevention technology that examines network traffic flows to
              detect and prevent vulnerability exploits. Vulnerability exploits
              usually come in the form of malicious inputs to a target
              application or service that attackers use to interrupt and gain
              control of an application or machine. Following a successful
              exploit, the attacker can disable the target application
              (resulting in a denial-of-service state), or can potentially gain
              access to all rights and permissions available to the compromised
              application.
            </h3>
            Understanding the company environment and specifics is mandatory in
            order to define all untrusted activities and the required actions.
            This is precisely where TSC starts with the IPS service to ensure
            sustainable and reliable delivery.
            <br />
            <br />
            <h3>How do Intrusion Prevention Systems work?</h3>
            <br />
            <br />
            Intrusion prevention systems work by scanning all network traffic.
            There are a number of different threats that an IPS is designed to
            prevent, including:
            <br />
            <br />
            <ul>
              <li>Denial of Service (DoS) attack</li>
              <li>Distributed Denial of Service (DDoS) attack</li>
              <li>Various types of exploits</li>
              <li>Worms</li>
              <li>Viruses</li>{" "}
            </ul>
            <br />
            <br />
            IPS performs real-time packet inspection, thoroughly inspecting
            every packet that travels across the network. If any malicious or
            suspicious packets are detected, the IPS will carry out one of the
            following actions:
            <br />
            <br />
            <ul>
              <li>
                Terminate the Transmission Control Protocol (TCP) session that
                has been exploited and block the IP address or user account of
                the offender from further accessing any application, target
                hosts, or other network resources.
              </li>
              <li>
                Reprogram or reconfigure the firewall to prevent a similar
                attack from occurring in the future.
              </li>
            </ul>
            <br />
            <br />
            Remove or replace any malicious content that remains on the network
            following an attack. This is done by repackaging payloads, removing
            header information, and removing any infected attachments from file
            or email servers.
            <br />
            IPS performs real-time packet inspection, thoroughly inspecting
            every packet that travels across the network. If any malicious or
            suspicious packets are detected, the IPS will carry out one of the
            following actions:
            <br />
            <br />
            <ul>
              <li>
                Terminate the Transmission Control Protocol (TCP) session that
                has been exploited and block the IP address or user account of
                the offender from further accessing any application, target
                hosts, or other network resources.
              </li>
              <li>
                Reprogram or reconfigure the firewall to prevent a similar
                attack from occurring in the future.
              </li>
            </ul>
            <br />
            <br />
            Remove or replace any malicious content that remains on the network following an attack. This is done by repackaging payloads, removing header information, and removing any infected attachments from file or email servers.

       
        </p>   </Fade>
      </div>

      <div className="box2">
        <Fade bottom cascade>
          <h3>Types of IPS:</h3>
          <img src={graph} alt="" />
        </Fade>
    
      </div>
    </div>
  );
}
