import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/UCC-01.svg";
import graph2 from "../../imgs/UCC-02.svg";

export default function Ucc() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="comheader">
        <p className="pp">
          <span className="transparent">
Unified Communication & Collaboration (UCC)</span>
          <br />
          Communicate Easy, Productive, and Fun
        </p>
      </div>
      <div className="box 1">
          <Fade bottom>  <p>
      
            <h1>UCC</h1>
     
            <h3>
            Unified Communications and Collaboration (UCC) is not a single tool or product, but a collection of solutions, which companies implement to ensure that nearly all their technology works together smoothly and securely for real-time collaboration. UCC integrates diverse communication tools, such as Voice, IP Telephony Calling, Instant Messaging, Desktop Sharing, Presence, and Web Conferencing, Audio Conferencing, and Video Conferencing to interact together in a virtually seamless way.

            </h3>
           
        </p> </Fade>
      </div>
  
      <div className="box2">
        <Fade bottom cascade>
          <img src={graph} alt="" />
    
        <p>
        With the emergence of the Covid-19 pandemic, companies that have mature UCC setup were the less impacted because they kept working from home smoothly. Their employees communicate as they were in the office or maybe even better. UCC is no longer a luxury service, but rather a necessity that can benefit the company directly:

          <br />
          <br />
          </p>
          <img src={graph2} alt="" />
          <p>
          TSC will review company requirements, check expansion probabilities,
          IT team capabilities and experience, in addition to numerous other
          considerations to come up with the perfect solution for the company.
        <h2 className="eng">Quality of service</h2>
        UCC traffic needs a solid network to ensure there will be no latency in the signal. The network is one of TSC’s core services and we guarantee spotless integration.

        <h2 className="eng">Migration from traditional communication to UCC</h2>
        The migration from traditional communications to modern UCC is also a challenge, especially when considering how to migrate with ZERO downtime and how to keep the service running 24/7. This is our mission.

        <h2 className="eng">Implementation cost</h2>
        UCC components are numerous. Skipping proper design can drive UCC implementation costs through the roof.
        <h2 className="eng">Which technology do I need? Which vendor should I go with?</h2>
        The assessment we carry out before the designing phase will define the exact needs; we will not install something that would not be highly utilized.
        </p>    </Fade>
      </div>
    </div>
  );
}
