  import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/csdfghjk-768x755.png";

export default function PAM() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">
            Implement further protections using IAM
          </span>
          <br />
          Identity and Access <br />
          Management
        </p>
      </div>
      <div className="box">
          <Fade bottom cascade> <p>
       
            <h1>Privileged Access Management (PAM)</h1>
         
       
            <h3>
              Privileged Access Management (PAM), in simple terms, is a solution
              that helps secure, control, manage, and monitor privileged access
              to critical assets.
            </h3>
            Through layers of more granular visibility, control, and auditing
            over privileged identities and activities, PAM helps companies
            protect against accidental or deliberate misuse of privileged access
            by streamlining the authorization and monitoring of privileged
            users. Controlling and monitoring privileged user access to your
            most critical data and systems is the best way to prevent attacks.
            <br />
            <br />
            <h3>What is the difference between PAM and IAM?</h3>
            <br />
            <br />
            Both IAM and PAM are critical areas of cybersecurity that deliver
            protection to different audiences. We will begin with some basics:
            What is Identity Access Management? IAM focuses on managing general
            users and customers, controlling the access and privileges that
            these users are granted within an application. PAM, on the other
            hand, is intended for administrative and privileged users by
            defining and controlling the administrative role of admin users.
            <br />
          
        </p>
        <img src={graph} alt="" style={{ width: "50%" }} /></Fade>
      </div>
    
      <div className="box2">
        <Fade bottom cascade></Fade>
        <Fade bottom cascade>
          <p>
            <br />
            <h4>Do I need PAM if I have IAM?</h4>
            <br /><br />
            IAM and PAM users enter applications through different interfaces.
            Whilst the audience of IAM enter through the ‘shop door’, PAM users
            are ‘back office’ users. Consequently, there is a difference in
            attack surface. The ‘shop door’ (IAM) has a low attack surface and
            can reveal a lot about individual customers, but does not provide
            the opportunity to download the entire database. In contrast, the
            ‘back office’ interface (PAM) allows for the bulk download of
            databases, subtle changes in stock levels, takings, or log files,
            etc. This represents the greater data breach surface. In other
            words, an IAM user will have access to a low number of
            business-specific applications, whilst a PAM user will have access
            to a larger number of privileged accounts that have full access to
            both business and technical functions.
            <br />
            <br />
            Integration between both IAM and PAM should be performed, assuring
            all the privileges and accesses are well-controlled, and this is
            what TSC focuses on when providing its service.
            <br />
            <br />
          </p>
        </Fade>
      </div>
    </div>
  );
}
