  import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Physical-security-01-1.svg";

export default function Physical() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">
            10% of malicious breaches are caused
            <br /> by a physical compromises
          </span>
          <br />
          Averaging at a cost of 16.3 SAR million
        </p>
      </div>
      <div className="box 1">
         <Fade bottom cascade>   <p>
       
            <h1>Physical Security</h1>
            
            <h2 className="eng">What is Physical Security?</h2>
            <br />
            Physical security is the protection of personnel, hardware,
            software, networks and data from physical actions and events that
            could cause serious loss or damage to an enterprise, agency, or
            institution. This includes protection from fire, flood, natural
            disasters, burglary, theft, vandalism, and terrorism.
            <br />
            <br />
            <br />
            <h2 className="eng">Why Physical Security is Important?</h2>
            <br />
            Attacks could be carried out digitally through the network or
            physically at the site. The optimum goal of cybersecurity is
            protecting data and equipment from any damages or theft, and
            physical attacks is one of these threats.
            <br />
            <br />
            <br />
       
      
            The risk from physical threats could be unintentional, such as fire,
            and the role of physical security is to ensure a company is ready
            for such accidents by installing firefighting systems, for example.
            At other times, the risk is intentional, such as an attacker trying
            to access the data center, and the required action here is to have
            physical access control. So, the role of physical security is to
            ensure closing all security gaps.
     
          <br />
          <br />
        </p>
        <img src={graph} alt="" />

        <p>
          <br />
          <br />
          TSC follows NCA regulations and implements their controls to ensure
          the company fully complies with all the physical security
          requirements.
        </p>     </Fade>
      </div>

      
    </div>
  );
}
