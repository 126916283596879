  import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Identity-Governance-Administration-IGA-01.svg";

export default function IGA() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">
            Identity Governance Administration and Life cycle
          </span>
          <br />
          Aligning technology, people,
          <br />
          and processes for success
        </p>
      </div>
      <div className="box 1">
      <Fade bottom cascade>  <p>
      
            <h1>
              Identity Governance Administration
              <br /> and Life Cycle (IGA)
            </h1>
            <h3>
              Governance Administration and Life Cycle (IGA) systems provide
              additional functionality beyond standard Identity and Access
              Management (IAM) systems. In particular, they help companies meet
              compliance requirements and enable them to audit access for
              compliance reporting. They also automate workflows for tasks such
              as access approvals and provisioning/de-provisioning.
            </h3>
    
      
            IGA systems merge identity administration with identity governance;
            the former addresses administration accounts and credentials,
            provisioning, and managing entitlements, while the latter addresses
            the separation of duties, role management, logging, and analytics
            and reporting.
            <br />
            <br />
            So, in addition to the IAM functionality, the IGA adds more key
            features, such as:
         
        </p> </Fade>{" "}
        <img src={graph} alt="" />
      </div>

 
      <div className="box2">
        <Fade bottom cascade>
          <p>
            <h2>Who are you? What do you want? And why?</h2>
            <br />
            In an increasingly digital world, you need to make sure that people
            requesting access are who they say they are, but what about whether
            the right people have access to the right resources and for the
            right reasons? Only a comprehensive IGA program can tell you that.
            <br />
            <br />
            <br />
            <br />
            <h2>A phased approach</h2>
            <br />
            IGA covers a lot of ground, from access reviews to lifecycle
            management to role-based access control and more. IGA offers a
            phased approach to deployment, whether cloud-based on-premises, so
            you do not have to roll out everything at once.
            <br />
            <br />
            <br />
            <br />
            <h2>Automated processes</h2>
            <br />
            Spreadsheets and other manual tools for IGA do not cut it when you
            are dealing with a constantly changing workforce, a growing number
            of resources, and multiple access points. SecurID Governance and
            Lifecycle replaces slow, inefficient processes with fast, accurate
            automation.
            <br />
            <br />
            <br />
            <br />
            <h2>Access visibility</h2>
            <br />
            Limited visibility into access makes effective governance
            impossible. SecurID Governance and Lifecycle provides the full
            visibility you need to uncover access anomalies and policy
            violations, and combines it with advanced analytics to prioritize
            problem areas for action.
          </p>
        </Fade>
      </div>
    </div>
  );
}
