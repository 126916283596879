import React, { useEffect } from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/Network-Infrastructure-01.svg";

export default function Infrastructure() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="networkheader">
        <p className="pp">
          <span className="transparent">
            Trained and expert team that is
            <br />
            well-equipped to launch your network
          </span>
          <br />
          YOUR IT BACKBONE
        </p>
      </div>
      <div className="box 1">
        <Fade bottom cascade>
          {" "}
          <p>
            <h1>Networking Infrastructure</h1>
            <h3>
              A professionally designed and structured cabling installation is
              necessary to establish a comprehensive network infrastructure to
              support multiple hardware systems and achieve continuous flow of
              communications data across your business. Supporting critical
              network systems and being able to quickly and cost-efficiently
              adapt are critical functions of a well-built IT network
              infrastructure. The backbone of this infrastructure is a robust,
              adaptable, and structured cabling system.
            </h3>
            TSC provides cabling systems to support high-speed data and UCC
            networks, including both copper and fiber optic cabling. We
            specialize in providing voice, data, and fiber optic cabling based
            on reliable, high-quality, standard-based cabling and network
            infrastructure components.
            <br />
            <br />
            Within the domain of network infrastructure, TSC covers the
            following sub-domains:
          </p>{" "}
          <img src={graph} alt="" />
        </Fade>
      </div>
    </div>
  );
}
