 import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import logo1 from "../../imgs/1.png";
import logo2 from "../../imgs/2.png";
import logo3 from "../../imgs/3.png";
import logo4 from "../../imgs/4.png";
import logo5 from "../../imgs/5.png";
import logo6 from "../../imgs/6.png";
import logo7 from "../../imgs/7.jpg";
import graph from "../../imgs/Cybersecurity-Policies-and-Procedures-01.svg";

export default function Policies() {
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">
            Practical and applicable, not theoretical
          </span>
          <br />
          Policies and Procedures <br />
          of Cybersecurity
        </p>
      </div>
      <div className="box 1">
             <Fade bottom cascade>   <p>
   
            <h1>Cybersecurity Policies & Procedures</h1>
  
  
            <h3>
              Usually, those who are most familiar with the importance of
              cybersecurity are the IT department and top management, in
              addition to the cybersecurity team, of course. Based on our
              experience in the market, and according to other experiences the
              world over, company members are the weak link in cybersecurity.
              That is why policies and procedures are one of the most important
              parts of securing a company from its own employees and guarantying
              secured processes between all and for all.
            </h3>
            <div className="rowabout">
              <div className="columnabout boxes three">
                <h2 className="eng">Policies</h2>
                What is the reference (standard or framework) that we will
                depends on
              </div>
              <div className="columnabout boxes three">
                <h2 className="eng">Procedures</h2>
                Build up the evaluation criteria which represented as comply
                matrix
              </div>
            </div>
            Cybersecurity procedures explain the rules for how employees,
            consultants, partners, board members, and end-users access online
            applications and internet resources, send data over networks, and
            otherwise practice responsible security.
            <br />
            The policies may then include sections for various areas of
            cybersecurity, such as requirements for antivirus software or the
            use of cloud applications, remote access policy, wireless
            communication policy, password protection policy, email policy,
            digital signature policy, and many more.
            <br />
            Cybersecurity policies and procedures are extremely important to any
            company, especially for large companies because the impact of any
            cyber-attack or data breach will be very costly. However, even small
            companies should have their own policies and procedures; the
            policies and procedures for smaller companies will be on a much
            smaller scale compared to larger companies, but still very important
            nonetheless. We simply measure the investment in cybersecurity
            policies and procedures for the company based on its size. If a
            company is large, the investment in policies and procedures should
            be large as well.
            <br />
            To avoid endeavors akin to reinventing the wheel and to guarantee
            the best results, TSC follows on global and/or local standards for
            setting up the policies. These standards and frameworks will help
            formulate and easy-to-follow policies and procedures. These would
            surely require adjustment or selecting from multiple options.
            Standards and frameworks that TSC follow are:
            <br />
            <br />
     
      
            <div className="cyberlogos">
              <img src={logo1} alt="" />
              National Cybersecurity Authority – Essential Cybersecurity
              Controls (ECC – 1: 2018).
            </div>
            <div className="cyberlogos">
              <img src={logo2} alt="" />
              The National Institute of Standards and Technology{" "}
            </div>
            <div className="cyberlogos">
              <img src={logo3} alt="" />
              The Escal Institute of Advanced Technologies
              <br />
              <span style={{ marginLeft: "328px" }}>
                SANS or SysAdmin, Audit, Network and Security
              </span>{" "}
            </div>
            <div className="cyberlogos">
              <img src={logo4} alt="" />
              Health Insurance Portability and Accountability Act{" "}
            </div>
            <div className="cyberlogos">
              <img src={logo5} alt="" />
              General Data Protection Regulation{" "}
            </div>
            <div className="cyberlogos">
              <img src={logo6} alt="" />
              Payment Card Industry Data Security Standard{" "}
            </div>
            <div className="cyberlogos">
              <img src={logo7} alt="" />
              Federal Information Security Management Act{" "}
            </div>
       
        </p>   </Fade>
      </div>
      
      <div className="box2">
        <Fade bottom cascade></Fade>
        <Fade bottom cascade>
          <p>
            <h4 style={{fontSize:"2.5rem"}}>HOW DOES IT WORK?</h4>
            <br />
            <br />
            <img src={graph} alt=""  style={{width:"90%"}}/>
          </p>
        </Fade>
      </div>
    </div>
  );
}
