import React, {useEffect} from "react";
import Fade from "react-reveal/Fade";
import graph from "../../imgs/asdgrthyjuki-768x759.png";

export default function Backup() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="cyberheader">
        <p className="pp">
          <span className="transparent">Backup and Recovery</span>
          <br />
          Always plan for the worst. <br />
          Store data in a safe place
        </p>
      </div>
      <div className="box 1">
          <Fade bottom cascade> <p>
       
            <h1>Backup and Recovery</h1>
         
       
            <h3>
              Backup and recovery describes the process of creating and storing
              copies of data to protect companies against data loss. This is
              sometimes referred to as operational recovery. Recovery from a
              backup typically involves restoring the data to the original
              location, or to an alternate location where it can be used in
              place of the lost or damaged data.
            </h3>
            The purpose of the backup is to create a copy of data that can be
            recovered in the event of a primary data failure. Primary data
            failures can be the result of hardware or software failure, data
            corruption, or a human-caused event, such as a malicious attack
            (virus or malware) or accidental deletion of data. Backup copies
            allow data to be restored from an earlier point in time to help the
            business recover from an unplanned event.
            <br />
            <br />
         
        </p> 
        <img src={graph} alt="" style={{ width: "60%" }} /></Fade>
      </div>
   
      <div className="box2">
   
        <Fade bottom cascade>
          <p>
            <br />
            <h4>Full</h4>
            <br />
            As the name suggests, this refers to the process of copying
            everything that is considered important and that must not be lost.
            This type of backup is the first copy and generally the most
            reliable copy, as it can normally be made without any need for
            additional tools.
            <br />
            <br />
            <h4>Incremental</h4>
            <br />
            The assessment will discover all cybersecurity risks and reveal
            apparent and hidden threats.This process requires much more care and
            has to be done over different phases as it involves making copies of
            the files by taking into account the changes made to them since the
            previous backup. For example, imagine you have done a full backup.
            Once you have finished, you decide that going forward you will do
            incremental backups, and you then create two new files. The
            incremental backup will discover that all the files in the full
            backup remain the same, and will only make backup copies of the two
            newly created files. As such, incremental backup saves time and
            space, as there will always be fewer files to be backed up than if
            you were to do a full backup. We recommend that you do not try to
            employ this type of backup strategy using manual means.
            <br />
            <br />
            <h4>Differential</h4>
            <br />
            A differential backup has the same basic structure as an incremental
            backup. In other words, it involves making copies only of new files
            or of files that underwent some kind of change. However, with this
            backup model, all the files created since the original full backup
            will always be copied again. For the same reasons as with
            incremental backups, we recommend that differential backups are also
            not carried out manually.
            <br />
            <br />
            <br />
            <br />
            The general aim of Cybersecurity is to make data safe and secure to
            keep the company business up and running without any losses. A part
            of securing any company, is to save its data in a safe place to
            ensure business continuity.
          </p>
        </Fade>
      </div>
    </div>
  );
}
